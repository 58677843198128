<template>
  <a-col>
    <ChartAndSentiment
      :mode="mode"
      type="category"
      :category-by-level="categoryByLevel"
      :filterResult="filterCriteria"
      chart-type="category"
      :tabActive="tabActive"
    >
      <template v-slot:category="{ noResult }">
        <div
          v-if="!isMobileScreen"
          id="level-select"
          class="category-select-list"
        >
          <a-select
            ref="level-select"
            v-model:value="categoryLevel"
            :getPopupContainer="(trigger) => trigger.parentNode"
            style="width: 150px"
            @change="onLevelChange"
            :disabled="noResult"
            :placeholder="`No Category`"
          >
            <a-select-option
              v-for="cat in categoryData"
              :key="cat.level"
              :value="cat.level"
            >
              {{ cat.name }}
            </a-select-option>
          </a-select>
        </div>
        <div v-if="isMobileScreen">
          <a-dropdown
            placement="bottomRight"
            :getPopupContainer="(trigger) => trigger.parentNode"
          >
            <FeatherIcon class="more-menu" type="more-horizontal" size="16" />
            <template #overlay>
              <a-menu
                id="mode-menu-mobile"
                style="width: 150px"
                class="mode-menu-mobile-2"
                :get-popup-container="(trigger) => trigger.parentNode"
                @click="onLevelChange"
                :placeholder="`No Category`"
              >
                <a-menu-item-group title="SELECT CATEGORY:">
                  <a-menu-item
                    v-for="cat in categoryData"
                    :key="cat.level"
                    :value="cat.level"
                    :class="{ active: cat.level === categoryLevel }"
                  >
                    {{ cat.name }}
                  </a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </ChartAndSentiment>
  </a-col>
</template>

<script>
import { computed, ref, onMounted, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import ChartAndSentiment from './ChartAndSentiment.vue';

export default {
  name: 'ShareOfVoice',
  props: {
    mode: String,
  },
  components: {
    ChartAndSentiment,
  },
  setup() {
    const store = useStore();
    const { state, dispatch } = store;
    const categoryData = computed(() => state.config.categoryData);
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let loading = ref(false);
    const error = ref(false);
    const tabActive = ref('pie');
    let categoryLevel = ref(null);
    const finalCriteria = computed(() => {
      return {
        ...filterCriteria.value,
      };
    });
    let filterResult = reactive({
      level: 0,
      categoryList: filterCriteria.value.category,
      valid: true,
      filterData: finalCriteria.value,
    });

    const categoryByLevel = computed(() => {
      return categoryData.value[categoryLevel.value];
    });

    const onFilterCriteriaChange = () => {
      let filterData = {
        ...filterCriteria.value,
      };
      if (filterResult.level === 0) {
        filterResult.categoryList = [...filterData.category];
      } else {
        const l = filterResult.level - 1;
        // when unselect subcat
        if (filterData.subCategory && filterData.subCategory.length) {
          const k = filterData.subCategory[l].category;
          filterResult.categoryList = [...k];
        } else {
          filterResult.categoryList = [...filterData.category];
        }
      }

      filterResult.filterData = filterData;
    };

    const onLevelChange = (level) => {
      filterResult.level = level.item.value;
      categoryLevel.value = level.item.value;
      onFilterCriteriaChange();
    };

    const init = async () => {
      loading.value = true;
      await dispatch('config/getAccountKeyword');
      loading.value = false;
    };

    onMounted(() => {
      init();
      if (categoryData.value.length > 0) {
        categoryLevel.value = 0;
      }
    });

    watch(
      () => filterCriteria.value,
      () => {
        onFilterCriteriaChange();
      },
      { deep: true }
    );

    return {
      filterCriteria,
      loading,
      error,
      tabActive,
      categoryByLevel,
      categoryData,
      isMobileScreen,
      onLevelChange,
      filterResult,
      categoryLevel,
    };
  },
};
</script>

<style></style>
