<template>
  <span
    class="source-tag"
    :class="{
      [source]: true,
      'with-label': showLabel,
    }"
  >
    <span class="logo">
      <span v-if="source === 'facebook'" class="inner facebook">
        <font-awesome-icon :icon="faFacebookF" />
      </span>
      <span v-else-if="source === 'twitter'" class="inner twitter">
        <font-awesome-icon :icon="faXTwitter" />
      </span>
      <span v-else-if="source === 'instagram'" class="inner instagram">
        <font-awesome-icon :icon="faInstagram" />
      </span>
      <span v-else-if="source === 'youtube'" class="inner youtube">
        <font-awesome-icon :icon="faYoutube" />
      </span>
      <span v-else-if="source === 'tiktok'" class="inner tiktok">
        <font-awesome-icon :icon="faTiktok" />
      </span>
      <span v-else-if="source === 'blockdit'" class="blockdit">
        <img src="../assets/images/source-icon/transparent/blockdit.svg" />
      </span>
      <FeatherIcon
        v-else-if="source === 'pantip'"
        class="pantip-icon"
        type="message-square"
        size="9"
      />
      <span v-else class="inner website">
        <FeatherIcon type="globe" size="8" />
      </span>
    </span>
    <span class="label" v-if="showLabel">{{
      source === 'twitter' ? 'X (Twitter)' : source
    }}</span>
  </span>
</template>

<script>
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faInstagram,
  faXTwitter,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    source: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      faFacebookF,
      faYoutube,
      faTwitter,
      faInstagram,
      faXTwitter,
      faTiktok,
    };
  },
};
</script>

<style lang="scss" scoped>
.source-tag {
  height: 14px;
  line-height: 14px;
  outline: 1px #fff solid;
  &.with-label {
    outline: unset;
    padding: 2px 5px 2px 2px;
    height: 18px;
    line-height: 18px;
  }
  border-radius: 18px;
  font-size: 10px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  // display: inline-block;
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  .logo {
    & + .label {
      margin-left: 4px;
    }
    height: 14px;
    width: 14px;
    border-radius: 24px;
    color: #fff;
    line-height: 14px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #36d9be;
  }
  .inner {
    display: flex;
  }
  &.facebook {
    .logo {
      background-color: rgba(24, 119, 242, 1);
      svg {
        height: 10px;
      }
    }
  }
  &.twitter {
    .logo {
      background-color: #000000;
      svg {
        height: 10px;
      }
    }
  }
  &.pantip {
    .logo {
      background-color: rgba(118, 116, 188, 1);
      svg {
        height: 12px;
      }
    }
  }
  &.youtube {
    .logo {
      background-color: #ff0000;
      svg {
        height: 10px;
      }
    }
  }
  &.instagram {
    .logo {
      background-color: rgba(228, 64, 95, 1);
      svg {
        height: 10px;
      }
    }
  }
  &.blockdit {
    .logo {
      background-color: #4a69ff;
      .blockdit {
        display: flex;
        align-items: center;
      }
    }
  }
  &.tiktok {
    .logo {
      background-color: #252525;
      .svg {
        display: flex;
        align-items: center;
      }
    }
  }
  &.website {
    .logo {
      background-color: #36d9be;
      .svg {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
