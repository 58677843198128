<template>
  <ChannelOverallVue :mode="mode" :filterResult="filterResult"></ChannelOverallVue>
  <ChannelTimeline :mode="mode" :filterResult="filterResult" />
  <ChannelActiveTime :mode="mode" :filter="filterResult" />
  <PerformanceChannel :mode="mode" :filterResult="filterResult" />
  <Top5Influencers :mode="mode" :filter="filterResult" />
</template>

<script>
import { watch, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import ChannelOverallVue from './ChannelOverall.vue';
import ChannelTimeline from './ChannelTimeline.vue';
import ChannelActiveTime from './ChannelActiveTime.vue';
import PerformanceChannel from '../../Campaign/Performance/PerformanceChannel.vue';
import Top5Influencers from './Top5Influencers.vue';

export default {
  components: {
    ChannelOverallVue,
    ChannelTimeline,
    ChannelActiveTime,
    PerformanceChannel,
    Top5Influencers,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    filter: Object,
  },
  setup() {
    const store = useStore();
    const filterCriteria = computed(() => store.getters['filter/filterCriteria']);
    let filterResult = ref();

    const init = (fv) => {
      filterResult.value = {
        ...fv,
        source: [],
      };
    };

    watch(
      () => filterCriteria.value,
      () => {
        init(filterCriteria.value);
      }
    );

    onMounted(() => {
      init(filterCriteria.value);
    });

    return {
      filterResult,
    };
  },
};
</script>

<style lang="scss" scoped></style>
