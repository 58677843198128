<template>
  <a-card class="top-influencer" :widget="true" :title="'Top 5 Influencer'">
    <template #extra>
      <span v-if="!loading && onlyTop5Data.length === 0" class="logo top-menu">
        <a class="view-all disabled-link">
          View All
          <FeatherIcon class="button-icon" type="chevron-right" :size="16" />
        </a>
      </span>
      <router-link v-else class="logo top-menu" :to="{ name: 'influencer' }">
        <a class="view-all">
          View All
          <FeatherIcon class="button-icon" type="chevron-right" :size="16" />
        </a>
      </router-link>
    </template>
    <div class="campaign-channel-column">
      <SourceButton
        v-if="!isMobileScreen"
        :selectedSource="selectedDataAspect"
        @handleModeClick="handleModeClick"
        :noData="(!loading && onlyTop5Data.length === 0)"
      ></SourceButton>
      <SourceSelection
        v-else
        :sourceSelected="selectedDataAspect"
        class="source-select"
        @onSourceSelect="handleModeClick"
        :noData="!loading && onlyTop5Data.length === 0"
      ></SourceSelection>
    </div>

    <div v-if="loading" class="top-influencer-loading">
      <a-skeleton :loading="loading" active />
    </div>

    <TopInfluencerGrids
      v-if="!loading && onlyTop5Data.length !== 0 && !isMobileScreen"
      :influencerDataList="onlyTop5Data"
      :sortBy="mode"
      :filterResult="filter"
    ></TopInfluencerGrids>
    <InfluencerOverview
      v-if="!loading && onlyTop5Data.length !== 0 && isMobileScreen"
      :influencerDataList="onlyTop5Data"
      :sortBy="mode"
      :filterResult="filter"
    ></InfluencerOverview>

    <div v-else-if="!loading && onlyTop5Data.length === 0" class="no-data">
      <NodataFound></NodataFound>
    </div>
  </a-card>
</template>

<script>
import { ref, reactive, computed, toRefs, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import api from '@/services/api';
import helper from '@/services/helper';
import InfluencerOverview from '@/components/Lists/InfluencerOverview.vue';
import TopInfluencerGrids from '@/components/Grids/TopInfluencerGrids.vue';
import NodataFound from '@/components/Error/NoDataFound.vue';
import SourceSelection from '@/components/Selections/SourceSelection.vue';
import SourceButton from '@/components/Button/SourceButton.vue';

export default {
  name: 'Top5Influencers',
  components: {
    NodataFound,
    InfluencerOverview,
    TopInfluencerGrids,
    SourceSelection,
    SourceButton,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const { mode, filter } = toRefs(props);
    const loading = ref(true);
    const error = ref(false);
    const influencerTrendData = reactive([]);

    const onlyTop5Data = computed(() => {
      if (influencerTrendData && influencerTrendData.value) {
        return influencerTrendData.value.slice(0, 5);
      }
      return [];
    });

    let selectedDataAspect = ref('facebook');

    const modeOptions = [
      { label: 'Facebook', value: 'facebook', icon: 'facebook' },
      { label: 'Twitter', value: 'twitter', icon: 'twitter' },
      { label: 'Instagram', value: 'instagram', icon: 'instagram' },
      { label: 'Youtube', value: 'youtube', icon: 'youtube' },
      { label: 'Pantip', value: 'pantip', icon: 'comments' },
      { label: 'Blockdit', value: 'blockdit', icon: 'blockdit' },
      { label: 'Website', value: 'website', icon: 'globe' },
    ];

    const handleModeClick = (source) => {
      selectedDataAspect.value = source;
    };

    const getInfluencers = async (fv) => {
      if (fv) {
        let measurement = '';
        switch (mode.value) {
          case 'mention':
            measurement = 'count';
            break;
          case 'view':
            measurement = 'view_count';
            break;
          default:
            measurement = mode.value;
        }
        const result = await api.getInfluencerByMode(measurement, fv).catch(() => {
          notification.error({
            message: 'Error',
            description: 'Cannot get influencer data.',
          });
        });
        if (
          result &&
          result.message != null &&
          result.message.influencers != null &&
          result.message.peopleInfo != null
        ) {
          let finalResult = [];
          for (let peopleObj of result.message.influencers) {
            const info = result.message.peopleInfo[peopleObj.id];
            const newProfileImage = info.user_photo;
            const fallbackImage = info.fallback_user_photo;
            let totalEngage = peopleObj.total_engagement;
            let totalMention = 0;
            let totalView = 0;
            for (let a of peopleObj.actions) {
              totalMention += a.value;
            }
            for (let r of peopleObj.reactions) {
              if (r.key === 'view') totalView += r.value;
            }
            finalResult.push({
              ...peopleObj,
              info,
              totalEngage: helper.numeral(totalEngage),
              totalMention: helper.numeral(totalMention),
              totalView: helper.numeral(totalView),
              newProfileImage,
              fallbackImage,
            });
          }
          influencerTrendData.value = finalResult;
        }
      }
    };

    const init = async (fv) => {
      loading.value = true;
      error.value = false;

      await getInfluencers(fv);
      loading.value = false;
      // console.log(influencerTrendData.value);
    };

    const onClickInfluencer = (item) => {
      const { peopleInfo } = item;
      console.log(item.peopleInfo.id);
      let f = {
        type: 'influencer',
        payload: {
          title: `${peopleInfo.user_name}'s posts`,
        },
        criteria: {
          ...filter.value,
          userId: peopleInfo.id,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      store.dispatch('message/showMessageModal', f);
    };

    watchEffect(() => {
      const fv = {
        ...filter.value,
        source: [selectedDataAspect.value],
      };
      init(fv);
    });

    return {
      loading,
      error,
      onlyTop5Data,
      onClickInfluencer,
      isMobileScreen,
      handleModeClick,
      modeOptions,
      selectedDataAspect,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-influencer {
  .campaign-channel-column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-bottom: 24px;
    @media (max-width: 575px) {
      display: block;
    }
  }
  .view-all {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #3371ff;
    cursor: pointer;
    .button-icon {
      padding-left: 10px;
      font-size: 16px;
      line-height: 18px !important;
      vertical-align: middle;
    }
  }

  .no-data {
    height: 465px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top-influencer-loading {
    min-height: 465px;
    max-height: 465px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .disabled-link {
    cursor: not-allowed;
    color: gray;
  }
}
</style>
