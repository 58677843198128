<template>
  <div class="no-data-in-aspect">
    <img class="img-logo" src="../../assets/images/nodata/folder.svg" alt="no data" />
    <div class="no-data-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'NoDataAspect',
  props: {
    title: {
      type: String,
      default: 'No data in this measurement',
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.no-data-in-aspect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  .img-logo {
    width: 175px;
    height: auto;
    margin-bottom: 16px;
  }
  .no-data-title {
    line-height: 22px;
    font-size: 18px;
    color: #666d92;
    font-weight: 500;
  }
}
</style>
