<template>
  <div class="message-head">
    <div class="left-block">
      <div class="profile-img-wrapper">
        <a class="profile-img" @click.stop="onClickProfile">
          <div v-lazy-container="{ selector: 'img', error: onImageError }">
            <img :data-src="newProfileImage" class="user-img" />
          </div>
        </a>
        <div v-if="orderNo && orderNo > 0" class="count-badge">
          {{ orderNo }}
        </div>
        <div v-if="isRetweet" class="retweet-badge">
          <font-awesome-icon :icon="faRetweet" />
        </div>
      </div>
      <div class="left-title">
        <div class="username-row" @click.stop="onClickProfile">
          {{ username }}
        </div>
        <div class="source-row">
          <SourceTag :source="source" />
          <template v-if="showDate && source === 'twitter' && !isRetweet">
            <!-- Origin is tweeted + has retweet  -->
            <span class="date-tag"> | Tweeted at {{ date }} </span>
          </template>
          <template v-else-if="showDate && source === 'twitter' && isRetweet">
            <span class="date-tag"> | Retweeted at {{ date }} </span>
          </template>
          <template v-else-if="showDate && source === 'facebook' && commentInPage">
            <span class="date-tag">
              | Commented in page <strong>{{ commentInPage }}</strong> at {{ date }}
            </span>
          </template>
          <span v-else-if="showDate && date" class="date-tag"> | Posted at {{ date }} </span>
        </div>
      </div>
    </div>
    <div class="right-block">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import { faRetweet } from '@fortawesome/free-solid-svg-icons';

import SourceTag from '../SourceTag.vue';
import helper from '@/services/helper';

export default {
  components: {
    SourceTag,
  },
  props: {
    username: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: '',
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    date: {
      type: String,
      default: '',
    },
    updatedAt: {
      type: String,
      default: '',
    },
    updatedAtTitle: {
      type: String,
      default: '',
    },
    profileImage: {
      type: String,
      default: '',
    },
    fallbackImage: {
      type: String,
      default: '',
    },
    orderNo: {
      type: Number,
      default: 1,
    },
    userLink: {
      type: String,
      default: '',
    },
    isRetweet: {
      type: Boolean,
      default: false,
    },
    hasRetweet: {
      type: Boolean,
      default: false,
    },
    commentInPage: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { profileImage, source, userLink, fallbackImage } = toRefs(props);

    const newProfileImage = computed(() => {
      let oldProfile = profileImage.value;
      if (profileImage.value) {
        if (source.value === 'facebook') {
          return profileImage.value;
        }
        if (oldProfile.indexOf('cdn.ask-dom.com')) {
          oldProfile = oldProfile.replace('cdn.ask-dom.com', 'cdn.insightera.co.th');
        }
        return oldProfile;
      } else {
        return helper.getDefaultImageProfile(source.value);
      }
    });

    const onImageError = computed(() => {
      if (source.value === 'facebook') {
        if (fallbackImage.value && newProfileImage.value !== fallbackImage.value) {
          return fallbackImage.value;
        } else {
          const img = helper.getDefaultImageProfile(source.value);
          return img;
        }
      } else {
        const img = helper.getDefaultImageProfile(source.value);
        return img;
      }
    });

    const onClickProfile = () => {
      if (userLink.value) {
        window.open(userLink.value, '_blank');
      }
    };

    return {
      onClickProfile,
      faRetweet,
      newProfileImage,
      onImageError,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-block {
    display: flex;
    align-items: center;
    overflow: hidden;
    .profile-img-wrapper {
      margin-right: 15px;
    }
    .profile-img {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 40px;
      overflow: hidden;
      background-color: #ddd;
      border: 1px solid #ddd;
      .user-img {
        width: 40px;
        height: 40px;
      }
    }
    .username-row {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      white-space:pre;
    }
    .source-row {
      line-height: 16px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .date-tag {
        font-size: 13px;
        color: #9299b8;
        white-space: nowrap;
        margin-left: 4px;
      }
    }
    .website > svg {
      padding-top: 3px;
      height: 11px;
      margin-bottom: -1px;
    }
  }
  .profile-img-wrapper {
    position: relative;

    .count-badge,
    .retweet-badge {
      position: absolute;
      font-size: 10px;
      border-radius: 24px;
      border: 1px solid #fff;
      background: #3371ff;
      bottom: 0;
      right: 0;
      line-height: 12px;
      width: 16px;
      height: 16px;
      color: #fff;
      text-align: center;
      font-weight: 500;
    }
    .count-badge {
      line-height: 16px;
    }
    .retweet-badge {
      svg {
        margin-top: 2px;
      }
    }
  }
}
</style>
