
import { ref, onMounted, onUnmounted, Ref } from 'vue';
export default {
  name: 'Slider',
  setup() {
    const slider: Ref<HTMLDivElement | undefined> = ref<
      HTMLDivElement | undefined
    >();
    const sliderContainer: Ref<HTMLDivElement | undefined | null> = ref<
      HTMLDivElement | undefined | null
    >();

    const resizeObserver: ResizeObserver = new ResizeObserver(() => {
      setPrevNext();
    });

    const getItemWidth = (slider: HTMLDivElement) => {
      const el = slider.querySelector('[data-slider-item]');
      if (!el) {
        console.error('[data-slider-item] is not defined in element');
      }
      return el ? el.clientWidth : 0;
    };

    const isShowPrev: Ref<boolean> = ref<boolean>(false);
    const isShowNext: Ref<boolean> = ref<boolean>(false);

    const calculateShowPrev = (container: HTMLDivElement): boolean => {
      return container.scrollLeft > 0;
    };
    const calculateShowNext = (container: HTMLDivElement): boolean => {
      let reachEnd = true;
      if (container) {
        reachEnd =
          container.scrollLeft + container.clientWidth >= container.scrollWidth;
      }
      return !reachEnd;
    };

    const onNext = () => {
      const container = sliderContainer.value;
      if (container) {
        const itemWidth = getItemWidth(container);
        container.scrollTo({
          left: container.scrollLeft + itemWidth,
          behavior: 'smooth',
        });
      }
    };
    const onPrev = () => {
      const container = sliderContainer.value;
      if (container) {
        const itemWidth = getItemWidth(container);
        container.scrollTo({
          left: container.scrollLeft - itemWidth,
          behavior: 'smooth',
        });
      }
    };

    const setPrevNext = () => {
      const container = sliderContainer.value;
      if (container) {
        isShowNext.value = calculateShowNext(container);
        isShowPrev.value = calculateShowPrev(container);
      }
    };

    const onScroll = function () {
      setPrevNext();
    };

    onMounted(() => {
      sliderContainer.value = slider.value?.querySelector(
        '[data-slider-container]'
      );

      if (!sliderContainer.value) {
        console.error('[data-slider-container] is no defined in element');
      } else {
        sliderContainer.value.addEventListener('scroll', onScroll);
        setPrevNext();
        resizeObserver.observe(sliderContainer.value);
      }
    });

    onUnmounted(() => {
      sliderContainer.value?.removeEventListener('scroll', onScroll);
    });

    return {
      onPrev,
      onNext,
      isShowPrev,
      isShowNext,
      slider,
      sliderContainer,
    };
  },
};
