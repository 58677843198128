<template>
  <a-button class="filter-button" @click="toggleFilter">
    <FeatherIcon type="filter" color="#3371ff" size="12" />
    <span class="filter-button-text"> Filter </span>
  </a-button>
</template>

<script>
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    const toggleFilter = () => {
      store.dispatch('filter/toggleFilter', false);
    };
    return {
      toggleFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-button {
  height: 38px;
  border-radius: 4px !important;
  .filter-button-text {
    margin-left: 8px;
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
  }
}
</style>
