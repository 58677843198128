<template>
  <slot v-if="isLoading" name="loading"></slot>
  <slot v-else-if="hasError" name="error"></slot>
  <div ref="tweetContainer" v-bind="attrs"></div>
</template>

<script>
import { defineComponent, ref, onMounted, nextTick, watch } from 'vue';

export default defineComponent({
  props: {
    tweetId: {
      type: String,
      required: true,
    },
    conversation: {
      type: String,
      default: 'all',
    },
    cards: {
      type: String,
      default: 'visible',
    },
    width: {
      type: [String, Number],
      default: 'auto',
    },
    align: {
      type: [String, undefined],
      default: undefined,
    },
    theme: {
      type: String,
      default: 'light',
    },
    lang: {
      type: String,
      default: 'en',
    },
    dnt: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['tweet-load-success', 'tweet-load-error'],
  setup(props, { attrs, emit }) {
    const isLoading = ref(true);
    const hasError = ref(false);
    const tweetContainer = ref();

    onMounted(() => {
      renderTweet();
    });

    watch(props, () => {
      renderTweet();
    });

    function renderTweet() {
      if (!(window['twttr'] && window['twttr'].ready)) {
        addScript('https://platform.twitter.com/widgets.js', renderTweet);
        return;
      }

      window['twttr'].ready().then(({ widgets }) => {
        isLoading.value = true;
        hasError.value = false;
        // Clear previously rendered tweet before rendering the updated tweet id
        if (tweetContainer.value) {
          tweetContainer.value.innerHTML = '';
        }

        const { tweetId, ...options } = props;
        widgets.createTweet(tweetId, tweetContainer.value, options).then(async (twitterWidgetElement) => {
          // Since we're mutating the DOM directly with the embed we need to tell Vue wait until the DOM update
          await nextTick();

          if (twitterWidgetElement) {
            emit('tweet-load-success', twitterWidgetElement);
          } else {
            hasError.value = true;
            emit('tweet-load-error');
          }

          isLoading.value = false;
        });
      });
    }

    function addScript(src, cb) {
      const s = document.createElement('script');
      s.setAttribute('src', src);
      s.addEventListener('load', () => cb(), false);
      document.body.appendChild(s);
    }

    return { tweetContainer, isLoading, hasError, attrs };
  },
});
</script>
