<template>
  <Collapsible
    title="Sentiment"
    :sub-title="sentimentTag.length"
    :is-expand="isExpanding"
    :tags="sentimentTag"
    @toggle="isExpanding = !isExpanding"
  >
    <template #expand>
      <div class="select-block-wrapper">
        <div
          v-for="s in choices"
          :key="s.value"
          class="select-block"
          :class="{ checked: localState[s.value] }"
          @click="toggleChoice(s.value)"
        >
          <div class="inner-block">
            <div class="icon">
              <FeatherIcon :style="localState[s.value] ? s.style : {}" :type="s.icon" :size="14" />
            </div>
            <div class="title">
              {{ s.title }}
            </div>
          </div>
          <div class="checkbox-wrapper">
            <a-checkbox :checked="localState[s.value]"></a-checkbox>
          </div>
        </div>
      </div>
    </template>
  </Collapsible>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import Collapsible from '../Collapsible.vue';

const defaultList = ['positive', 'neutral', 'negative'];
const defaultState = {
  positive: true,
  neutral: true,
  negative: true,
};

export default {
  components: {
    Collapsible,
  },
  setup() {
    const store = useStore();
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const isExpanding = ref(false);

    const choices = [
      {
        title: 'Positive',
        value: 'positive',
        icon: 'smile',
        style: { color: '#20C997' },
      },
      {
        title: 'Neutral',
        value: 'neutral',
        icon: 'meh',
        style: { color: '#febc3c' },
      },
      {
        title: 'Negative',
        value: 'negative',
        icon: 'frown',
        style: { color: '#FF4D4F' },
      },
    ];
    const localState = reactive({ ...defaultState });
    const sentimentTag = computed(() => {
      const arr = [];
      for (let k of defaultList) {
        if (localState[k])
          arr.push({
            name: k,
            text: helper.capitalize(k),
          });
      }
      return arr;
    });

    const toggleChoice = (name) => {
      localState[name] = !localState[name];
      // Set back to filter
      const arr = [];
      Object.keys(localState).forEach((k) => {
        if (localState[k]) arr.push(k);
      });
      store.dispatch('filter/updateTempCriteria', {
        sentiment: arr,
      });
    };

    watch(
      () => tempCriteria.value.sentiment,
      () => {
        // convert back
        if (tempCriteria.value.sentiment) {
          Object.keys(defaultState).forEach((k) => {
            if (tempCriteria.value.sentiment.includes(k)) {
              localState[k] = true;
            } else {
              localState[k] = false;
            }
          });
        }
      }
    );

    return {
      isExpanding,
      choices,
      sentimentTag,
      localState,
      toggleChoice,
    };
  },
};
</script>

<style lang="scss" scoped>
.select-block-wrapper {
  display: flex;
  padding: 16px 0;
  .select-block {
    flex: 1 1 auto;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
    height: 60px;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;
    &.checked {
      border: 1px solid #3371ff;
      background: #fff;
    }
    &:first-of-type {
      margin-left: 0;
    }
    .inner-block {
      // display: block;
      text-align: center;
      .icon {
        margin-top: 3px;
        margin-bottom: -4px;
        color: #9299b8;
      }
      .title {
        color: #5a5f7d;
        font-size: 13px;
      }
    }
    .checkbox-wrapper {
      position: absolute;
      right: 4px;
      top: 0px;
    }
  }
}
</style>
