import { defineCustomElement } from 'vue';
import CustomInfoWindow from './CustomInfoWindow.ce.vue';

// convert into custom element constructor
const infoWindowElem = defineCustomElement(CustomInfoWindow);

// register
customElements.define('custom-info-window', infoWindowElem);
// document.body.appendChild(
//   new infoWindowElem({
//     // initial props (optional)
//   })
// );
