import dayjs from 'dayjs';
// import numeral from 'numeral';

import helper from '@/services/helper';

const getColor = helper.getColor;

const getPieChartOption = () => {
  return {
    //   tooltip: {
    //     trigger: 'item',
    //     formatter: '{b} <br/> xxx%',
    //   },
    legend: {
      type: 'scroll',
      textStyle: {
        color: 'rgba(134, 142, 174, 1)',
      },
      icon: 'circle',
      itemGap: 20.5,
      itemHeight: 10,
      left: 'center',
      top: 'bottom',
      // bottom: 0,
      data: [],
    },
    series: [
      {
        // name: 'Channel',
        //   id: 'test',
        type: 'pie',
        radius: ['0%', '70%'],
        center: ['50%', '40%'], // x, y
        //   top: '0%',
        //   bottom: '20%',
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
            formatter: '{name|{b}}\n{count|{d}%}',
            rich: {
              name: {
                fontSize: 12,
                color: '#999',
                fontWeight: 400,
              },
              count: {
                fontSize: 15,
                color: '#4DD4AC',
                fontWeight: 700,
              },
            },
          },
        },
        // labelLine: {
        //   length: 5,
        // },
        data: [],
      },
    ],
  };
};

const getDoughnutChartOption = () => {
  return {
    legend: {
      textStyle: {
        color: 'rgba(134, 142, 174, 1)',
      },
      icon: 'circle',
      itemGap: 20.5,
      itemHeight: 10,
      left: 'center',
      top: 'bottom',
      // bottom: 0,
      data: [],
    },
    series: [
      {
        name: 'Sentiment',
        //   id: 'test',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '40%'], // x, y
        //   top: '0%',
        //   bottom: '20%',
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
            formatter: '{name|{b}}\n{count|{d}%}',
            rich: {
              name: {
                fontSize: 12,
                color: '#999',
              },
              count: {
                fontSize: 17,
                color: '#febc3c',
                fontWeight: 'bold',
              },
            },
          },
        },
        labelLine: {
          length: 3,
        },
        data: [],
      },
    ],
  };
};

const getChartGranularity = (sinceDate, untilDate) => {
  const since = dayjs(sinceDate);
  const until = dayjs(untilDate);
  const diff = until.diff(since, 'days');
  if (diff <= 1) return 'hourly';
  else if (diff <= 91) return 'daily';
  else if (diff <= 1000) return 'monthly';
  else return 'yearly';
};

const modifyGranularity = (opt, args) => {
  console.log(args);
  const chartOptions = JSON.parse(JSON.stringify(opt));
  let granularity = 'daily';
  let newArgs = {};
  if (args && args.time) {
    if (!args.graphTime) newArgs.graphTime = {};
    newArgs.graphTime.sinceDate = args.time.sinceDate;
    newArgs.graphTime.untilDate = args.time.untilDate;
    granularity = getChartGranularity(args.time.sinceDate, args.time.untilDate);
    newArgs.graphTime.timeRange = granularity;
    newArgs.time = undefined;
  } else if (args && args.graphTime.timeRange) {
    granularity = args.graphTime.timeRange;
    // args.granularity = args.graphTime.timeRange;
    // TODO check if needed for tickInterval or pointRange
  } else {
    try {
      console.log('Modify Granularity - no time?');
      // chartOptions.xAxis.tickInterval = null;
      // chartOptions.plotOptions.series.pointRange = null;
    } catch (e) {
      // console.log('Chart error', e);
    }
  }
  console.log('MG', args, newArgs);

  return {
    graphCriteria: newArgs,
    chartOptions,
    granularity,
  };
};

export default {
  getColor,
  getPieChartOption,
  getDoughnutChartOption,
  getChartGranularity,
  modifyGranularity,
};
