<template>
  <div>
    <highcharts ref="trendingChartRef" :options="option"></highcharts>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { toRefs, watch, ref, reactive } from 'vue';
// import _ from 'lodash';
import api from '@/services/api';

const SourceChart = {
  name: 'SourceChart',
  components: {
    highcharts: Chart,
  },
  props: {
    series: VueTypes.array.def([]),
    chartType: VueTypes.string.def('line'),
  },
  setup(props) {
    let trendingChartRef = ref(null);
    let { chartType, series } = toRefs(props);
    const sList = api.getSocialSource();
    const socialList = reactive(sList);
    const sourceLists = ref([...socialList, 'website']);
    const option = reactive({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
      chart: {
        type: chartType.value,
        height: 450,
      },
      credits: {
        enabled: false,
      },

      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      xAxis: {
        categories: sourceLists,
        labels: {
          useHTML: true,
          style: {
            textTransform: 'capitalize',
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        itemStyle: {
          color: '#868EAE',
          fontFamily: 'DM Sans, IBM Plex Sans Thai, serif',
          fontSize: '13px',
          fontWeight: '400',
        },
        // verticalAlign: 'middle',
      },

      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        column: {
          stacking: 'normal',
        },
      },

      tooltip: {
        formatter: function () {
          let str =
            '<span>' + this.points[0].key.charAt(0).toUpperCase() + this.points[0].key.slice(1) + '</span><br/>';
          for (let i in this.points) {
            str +=
              '<span style="color:' +
              this.points[i].color +
              '">\u25CF</span> ' +
              this.points[i].series.name +
              ' : <b>' +
              Highcharts.numberFormat(this.points[i].y, 0, '.', ',') +
              '</b><br/>';
          }
          return str;
        },
        shared: true,
        useHTML: true,
        outside: false,
      },

      series: series.value,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    });
    watch(series, (current, previous) => {
      if (current !== previous) {
        option.series = current;
        // trendingChartRef.value.chart.update({ series: current }, true, true, true);
      }
    });
    watch(chartType, (current, previous) => {
      if (current !== previous) {
        option.chart = {
          type: current,
        };
        // trendingChartRef.value.chart.update(
        //   {
        //     chart: {
        //       type: current,
        //     },
        //   },
        //   true,
        //   true,
        //   true
        // );
      }
    });
    // const onResize = _.debounce(({ width }) => {
    //   console.log(trendingChartRef);
    //   trendingChartRef.value.chart.setSize(width, 350);
    // }, 100);
    return {
      option,
      // onResize,
      trendingChartRef,
    };
  },
};

export default SourceChart;
</script>

<style scoped>
@media screen and (max-width: 775px) {
  :deep(.highcharts-legend .highcharts-legend-item .highcharts-point) {
    y: 5 !important;
  }
}
</style>
