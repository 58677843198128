<template>
  <div class="button-container">
    <a-row>
      <a-col
        :xxl="{ span: 8, offset: 0 }"
        :xl="{ span: 8, offset: 0 }"
        :lg="{ span: 8, offset: 0 }"
        :md="{ span: 8, offset: 0 }"
        :sm="{ span: 8, offset: 0 }"
        :xs="{ span: 8, offset: 0 }"
      >
        <TrendingStatButton
          v-if="isMobileScreen"
          class="cursor-pointer"
          :selected="selectedValueType === 'engagement'"
          title="Engagement"
          :loading="loading"
          :disable-tooltip="true"
          :value="engagementData.value"
          :percent-change="engagementData.percentChange"
          @click="select('engagement')"
        />
        <TrendingModal
          v-if="isMobileScreen"
          :mode="'Engagement'"
          :visible="engagementTrendingModal"
          :total="engagementData.value"
          :comments="commentData.value"
          :comments-percent-change="commentData.percentChange"
          :reaction="reactionData.value"
          :reaction-percent-change="reactionData.percentChange"
          :share="shareData.value"
          :share-percent-change="shareData.percentChange"
          @closeModal="toggleTrendingByModal"
        ></TrendingModal>
        <a-popover v-if="!isMobileScreen" placement="bottomLeft" trigger="hover">
          <template #content>
            <EngagementDetail
              :total="engagementData.value"
              :comments="commentData.value"
              :comments-percent-change="commentData.percentChange"
              :reaction="reactionData.value"
              :reaction-percent-change="reactionData.percentChange"
              :share="shareData.value"
              :share-percent-change="shareData.percentChange"
            />
          </template>
          <TrendingStatButton
            class="cursor-pointer"
            :selected="selectedValueType === 'engagement'"
            title="Engagement"
            :loading="loading"
            :disable-tooltip="true"
            :value="engagementData.value"
            :percent-change="engagementData.percentChange"
            @click="select('engagement')"
          />
        </a-popover>
      </a-col>
      <a-col
        :xxl="{ span: 8, offset: 0 }"
        :xl="{ span: 8, offset: 0 }"
        :lg="{ span: 8, offset: 0 }"
        :md="{ span: 8, offset: 0 }"
        :sm="{ span: 8, offset: 0 }"
        :xs="{ span: 8, offset: 0 }"
      >
        <TrendingStatButton
          v-if="isMobileScreen"
          class="cursor-pointer"
          :selected="selectedValueType === 'mentioned'"
          :disable-tooltip="true"
          title="Mentioned"
          :loading="loading"
          :value="mentionData.value"
          :percent-change="mentionData.percentChange"
          @click="select('mentioned')"
        />
        <TrendingModal
          v-if="isMobileScreen"
          :mode="'Mentioned'"
          :visible="mentionTrendingModal"
          :total="mentionData.value"
          :mention="mentionData.formatted"
          :mention-percent-change="mentionData.percentChange"
          @closeModal="toggleTrendingByModal"
        ></TrendingModal>
        <a-popover v-if="!isMobileScreen" :mode="selectedValueType" placement="bottomLeft" trigger="hover">
          <template #content>
            <div class="pop-data-wrapper">
              <div class="title">Total</div>
              <div class="value-wrapper">
                <div class="value mb-0">{{ mentionData.formatted }}</div>
                <div class="sub-value">({{ postPerDayData.formatted }} mentions/day)</div>
              </div>

              <div class="title">Post</div>
              <div class="value-wrapper">
                <div class="value">{{ originCountData.formatted }}</div>
              </div>

              <div class="title">Comment</div>
              <div class="value-wrapper">
                <div class="value">{{ commentCountData.formatted }}</div>
              </div>
            </div>
          </template>
          <TrendingStatButton
            class="cursor-pointer"
            :selected="selectedValueType === 'mentioned'"
            :disable-tooltip="true"
            title="Mentioned"
            :loading="loading"
            :value="mentionData.value"
            :percent-change="mentionData.percentChange"
            @click="select('mentioned')"
          />
        </a-popover>
      </a-col>
      <a-col
        :xxl="{ span: 8, offset: 0 }"
        :xl="{ span: 8, offset: 0 }"
        :lg="{ span: 8, offset: 0 }"
        :md="{ span: 8, offset: 0 }"
        :sm="{ span: 8, offset: 0 }"
        :xs="{ span: 8, offset: 0 }"
      >
        <TrendingStatButton
          v-if="isMobileScreen"
          class="cursor-pointer"
          :selected="selectedValueType === 'view'"
          :disable-tooltip="true"
          title="View"
          :loading="loading"
          :value="viewData.value"
          :percent-change="viewData.percentChange"
          @click="select('view')"
        />
        <TrendingModal
          v-if="isMobileScreen"
          :mode="'View'"
          :visible="viewTrendingModal"
          :total="viewData.value"
          :view="viewData.formatted"
          :page-view="pageViewData.formatted"
          :video-view="videoViewData.formatted"
          :view-percent-change="viewData.percentChange"
          @closeModal="toggleTrendingByModal"
        ></TrendingModal>
        <a-popover v-if="!isMobileScreen" placement="bottomLeft" trigger="hover">
          <template #content>
            <div class="pop-data-wrapper">
              <div class="title">Total</div>
              <div class="value-wrapper">
                <div class="value">{{ viewData.formatted }}</div>
              </div>
              <div class="title">Page View</div>
              <div class="value-wrapper">
                <div class="value">{{ pageViewData.formatted }}</div>
              </div>
              <div class="title">Video View</div>
              <div class="value-wrapper">
                <div class="value">{{ videoViewData.formatted }}</div>
              </div>
            </div>
          </template>
          <TrendingStatButton
            class="cursor-pointer"
            :selected="selectedValueType === 'view'"
            :disable-tooltip="true"
            title="View"
            :loading="loading"
            :value="viewData.value"
            :percent-change="viewData.percentChange"
            @click="select('view')"
          />
        </a-popover>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import numeral from 'numeral';
import TrendingStatButton from '@/components/Button/TrendingStatButton';
import { ref, toRefs, reactive, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import VueTypes from 'vue-types';
import api from '@/services/api';
import EngagementDetail from '@/components/Detail/EngagementDetail';
import TrendingModal from '@/components/Modal/TrendingModal';

export const TrendingStatSummary = {
  name: 'TrendingStatSummary',
  components: {
    TrendingStatButton,
    EngagementDetail,
    TrendingModal,
  },
  props: {
    filter: VueTypes.object.def({}),
    value: VueTypes.string.def('engagement'),
  },
  setup(props, { emit }) {
    const { state } = useStore();
    const { filter, value } = toRefs(props);
    const selectedValueType = ref(value.value);
    const isMobileScreen = computed(() => state.isMobileScreen);
    const engagementTrendingModal = ref(false);
    const mentionTrendingModal = ref(false);
    const viewTrendingModal = ref(false);
    const loading = ref(true);
    const error = ref(false);

    const engagementData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const mentionData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const viewData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const videoViewData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const pageViewData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const reactionData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const commentData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const shareData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });

    const originCountData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const commentCountData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });
    const postPerDayData = reactive({
      value: 0,
      percentChange: 0,
      formatted: '0',
    });

    const select = (valueType) => {
      selectedValueType.value = valueType;
      if (isMobileScreen.value) {
        if (valueType === 'engagement') {
          engagementTrendingModal.value = true;
        } else if (valueType === 'mentioned') {
          mentionTrendingModal.value = true;
        } else {
          viewTrendingModal.value = true;
        }
      }
      emit('typeChange', valueType);
    };
    const toggleTrendingByModal = (value) => {
      if (selectedValueType.value === 'engagement') {
        engagementTrendingModal.value = value;
      } else if (selectedValueType.value === 'mentioned') {
        mentionTrendingModal.value = value;
      } else {
        viewTrendingModal.value = value;
      }
    };
    const loadData = async (apiFilter) => {
      const qs = reactive({});
      const result = await api.getSocialStatSummaryData(apiFilter, qs).catch(() => {
        // console.error('Social stat summary api call error', result);
        error.value = true;
      });
      if (result.message != null) {
        if (result.message.engagement != null) {
          Object.assign(engagementData, result.message.engagement);
        }
        if (result.message.mention != null) {
          Object.assign(mentionData, result.message.mention);
          mentionData.formatted = numeral(mentionData.value).format('0,0');
        }
        if (result.message.view != null) {
          Object.assign(viewData, result.message.view);
          viewData.formatted = numeral(viewData.value).format('0,0');
        }
        if (result.message.videoView != null) {
          Object.assign(videoViewData, result.message.videoView);
          videoViewData.formatted = numeral(videoViewData.value).format('0,0');
        }
        if (result.message.pageView != null) {
          Object.assign(pageViewData, result.message.pageView);
          pageViewData.formatted = numeral(pageViewData.value).format('0,0');
        }
        if (result.message.reaction != null) {
          Object.assign(reactionData, result.message.reaction);
        }
        if (result.message.comment != null) {
          Object.assign(commentData, result.message.comment);
        }
        if (result.message.share != null) {
          Object.assign(shareData, result.message.share);
        }
        if (result.message.originCount != null) {
          Object.assign(originCountData, result.message.originCount);
          originCountData.formatted = numeral(originCountData.value).format('0,0');
        }
        if (result.message.commentCount != null) {
          Object.assign(commentCountData, result.message.commentCount);
          commentCountData.formatted = numeral(commentCountData.value).format('0,0');
        }
        if (result.message.postPerDay != null) {
          Object.assign(postPerDayData, result.message.postPerDay);
          postPerDayData.formatted = numeral(postPerDayData.value).format('0,0');
        }
      }
      loading.value = false;
    };
    watch(
      filter,
      () => {
        loading.value = true;
        error.value = false;
        loadData(filter.value);
      }
      // { deep: true }
    );
    // watchEffect(() => {
    //   loading.value = true;
    //   error.value = false;
    //   loadData(filter.value).finally(() => {
    //     loading.value = false;
    //   });
    // });
    onMounted(async () => {
      loading.value = true;
      error.value = false;
      await loadData(filter.value);
      loading.value = false;
    });
    return {
      // breakpoints,
      commentCountData,
      originCountData,
      postPerDayData,
      loading,
      select,
      selectedValueType,
      engagementData,
      viewData,
      videoViewData,
      pageViewData,
      mentionData,
      reactionData,
      commentData,
      shareData,
      isMobileScreen,
      engagementTrendingModal,
      mentionTrendingModal,
      viewTrendingModal,
      toggleTrendingByModal,
    };
  },
};
export default TrendingStatSummary;
</script>
<style lang="scss" scoped>
.sub-value {
  font-size: 0.8125rem;
  color: #5a5f7d;
  margin-bottom: 10px;
}
.button-container {
  /*display: flex;*/
  width: 100%;
}
.pop-data-wrapper {
  .title {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #9299b8;
  }

  .value {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #272b41;
    margin-bottom: 10px;
  }

  .mb-0 {
    margin-bottom: 0;
  }
}
</style>
