<template>
  <a-alert
    v-if="displayAlert"
    message="DOM is gathering data"
    :description="descriptionText"
    type="info"
    class="box-alert"
    show-icon
    closable
  />
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import api from '@/services/api';
import moment from 'moment';

export default {
  name: 'MessageAlertNewSetup',

  setup() {
    let intervalId;
    const runMounted = ref(false);

    const minuteDisplay = ref(100);
    const displayAlert = ref(false);

    const descriptionText = computed(() => {
      return `It may take up to ${minuteDisplay.value} minutes to an hour before stats show up for your account. While you're waiting, add more keywords to get more stats.`;
    });

    const getTimeDiffOldestCreateAt = async () => {
      const result = await api
        .getKeywordPagination(1, 1, 'created_at', 'asc')
        .catch(() => {});
      if (result != undefined) {
        const createAtTime = result.message[0].createdAt;
        const createdAtTimestamp = moment(createAtTime).add(30, 'minutes');
        const now = moment();
        const timeDifferenceInMinutes = createdAtTimestamp.diff(now, 'minutes');

        return timeDifferenceInMinutes;
      }

      return -1;
    };

    const countDownTimer = () => {
      intervalId = setInterval(() => {
        if (minuteDisplay.value > 1) {
          minuteDisplay.value -= 1;
        } else {
          clearInterval(intervalId);
          displayAlert.value = false;
        }
      }, 60000); // 60000 milliseconds = 1 minute
    };

    onMounted(async () => {
      const timeDiffDisplay = await getTimeDiffOldestCreateAt();
      if (0 < timeDiffDisplay && timeDiffDisplay <= 30) {
        minuteDisplay.value = timeDiffDisplay;
        displayAlert.value = true;
        runMounted.value = true;
        countDownTimer();
      }
    });

    onBeforeUnmount(() => {
      if (runMounted.value) {
        clearInterval(intervalId);
        displayAlert.value = false;
      }
    });

    return {
      descriptionText,
      displayAlert,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';

.box-alert {
  border-radius: 4px;
  background: $info-background-color;
  border: 1px solid $info-color;
  margin-bottom: 16px;

  :deep(.ant-alert-icon) {
    font-size: 16px;
    padding: 4px;
  }

  :deep(.ant-alert-message) {
    color: $info-color;
    font-weight: 500;
    font-size: 15px;
  }

  :deep(.ant-alert-description) {
    color: $info-color;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
