<template>
  <Collapsible
    title="Category"
    :sub-title="categoryTag.total"
    :is-expand="isExpanding"
    :tags="categoryTag.tags"
    @toggle="isExpanding = !isExpanding"
  >
    <template #expand>
      <div class="d-flex mt-2" style="color: #515f7d">
        <span class="mr-3">Match Condition:</span>
        <a-radio-group
          v-model:value="tempCriteria.matchCategoryMode"
          name="category-select-group"
          class="m-0 category-mode-select"
          :default-value="'any'"
          @change="toggleAndOrCategory"
        >
          <a-radio :value="'any'"> OR</a-radio>
          <a-radio :value="'all'"> AND</a-radio>
        </a-radio-group>
      </div>

      <ExpandableTreeSelect
        content="category"
        :tree-data="filteredCategory.treeData"
        :selected="filteredCategory.selectedData"
        @change="onCategorySelect"
      />
    </template>
  </Collapsible>
</template>

<script>
import { ref, computed, watchEffect } from 'vue';
import _ from 'lodash';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import Collapsible from '../Collapsible.vue';
import ExpandableTreeSelect from '../ExpandableTreeSelect.vue';

export default {
  components: {
    Collapsible,
    ExpandableTreeSelect,
  },
  setup() {
    const store = useStore();
    const route = computed(() => useRoute());
    const campaignLevel = computed(() => store.state.account.campaignMenuData.categoryLevel);
    const isCampaign = computed(() => {
      if (route.value.fullPath.split('/')[1] === 'campaign' && route.value.fullPath !== '/campaign/comparison') {
        return true;
      } else {
        return false;
      }
    });
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const isExpanding = ref(false);

    const categoryTreeData = computed(() => store.getters['config/categoryTreeData']);

    const filteredCategory = computed(() => {
      const clonedCategoryData = _.cloneDeep(categoryTreeData.value);
      clonedCategoryData.tree = clonedCategoryData.tree.map((v) => ({ ...v, hasSearch: true, searchPlaceholder: 'Search category name' }));

      let clonedRawCategory = tempCriteria.value.rawCategory;
      const campaignCategoryData = clonedCategoryData.tree[campaignLevel.value];
      if (isCampaign.value) {
        if (campaignCategoryData) {
          const campaignLevelName = campaignCategoryData.id;
          clonedCategoryData.tree.splice(campaignLevel.value, 1);
          delete clonedCategoryData.defaultState[campaignLevelName];
          if (clonedRawCategory) {
            delete clonedRawCategory[campaignLevelName];
          }
        }
      }

      return {
        treeData: clonedCategoryData,
        selectedData: clonedRawCategory,
      };
    });
    watchEffect(() => {
      console.log('campaign-raw-category', tempCriteria.value.rawCategory);
    });
    const defaultList = computed(() => store.getters['config/defaultCategoryVisibleMap']);
    const onCategorySelect = (obj) => {
      // console.log('OBJ', obj);
      if (obj && obj.localState) {
        const catObj = obj.localState;
        const result = helper.convertRawCategory(catObj, defaultList.value);
        store.dispatch('filter/updateTempCriteria', {
          rawCategory: obj.localState,
          ...result,
        });
      }
    };
    const categoryTag = computed(() => {
      const result = {
        total: 0,
        tags: [],
      };
      if (tempCriteria.value.rawCategory && categoryTreeData.value) {
        Object.keys(tempCriteria.value.rawCategory).forEach((k) => {
          // console.log(categoryTreeData.value.defaultState[k]);
          let defaultLength = 0;
          if (categoryTreeData.value.defaultState[k]) {
            defaultLength = categoryTreeData.value.defaultState[k].length;
          }
          const currentLength = tempCriteria.value.rawCategory[k].length;
          result.total += currentLength;
          if (currentLength > 0) {
            let trimName = k;
            if (k.length > 10) {
              trimName = k.slice(0, 10) + '...';
            }
            result.tags.push({
              name: k,
              text: `${trimName} ${currentLength === defaultLength ? ': all ' : ''}(${currentLength})`,
            });
          }
        });
      }
      return result;
    });

    const toggleAndOrCategory = (e) => {
      // console.log(e);
      store.dispatch('filter/updateTempCriteria', {
        matchCategoryMode: e.target.value,
      });
    };

    return {
      isExpanding,
      categoryTreeData,
      categoryTag,
      onCategorySelect,
      toggleAndOrCategory,
      tempCriteria,
      filteredCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
.category-mode-select {
  margin-top: 16px;
}
</style>
