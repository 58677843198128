// import Vue from 'vue';
import api from '@/services/api';
import helper from '@/services/helper';

const HlStartText = '<strong class="text-highlight">';
const HlEndText = '</strong>';
const HlLength = HlStartText.length + HlEndText.length;

const ShStartText = '<strong class="search-highlight">';
const ShEndText = '</strong>';
const ShLength = ShStartText.length + ShEndText.length;

const getCatLevelNameObj = (categoryData) => {
  let result = {
    categories: '',
  };
  if (categoryData) {
    for (let i of categoryData) {
      if (i.level === 0) {
        result.categories = i.name;
      } else {
        result[`sub_categories_level_${i.level}`] = i.name;
      }
    }
  }
  return result;
};

const legacyHighlightKeyword = (getters, data) => {
  console.time('hk0');
  const {
    messageList,
    truncateAt = 250,
    forceRun = false,
    searchKeyword = '',
  } = data;
  // TODO find more efficient way e.g. merge all text -> find -> split

  for (let i in messageList) {
    const item = messageList[i];
    let targetText;
    let joinTextMode = false;
    if (!item._id) {
      item._id = item.info.id;
    }
    // SKIP if already highlight
    if (item.highlighted_text && !forceRun) {
      continue;
    }
    if (item.analytic && item.analytic.highlighted_text) {
      // Highlight search result this will already in <em> tag
      targetText = item.analytic.highlighted_text.join('%|%');
      joinTextMode = true;
    } else {
      // Else normal case that highlight by retreived keyword list
      if (truncateAt > 0) {
        targetText = helper.truncate(item.raw_data.text, truncateAt);
      } else {
        (targetText = item.raw_data.text), truncateAt;
      }
    }
    let keywordList;
    if (searchKeyword) {
      keywordList = [...getters.keywordList, searchKeyword];
    } else {
      keywordList = getters.keywordList;
    }
    if (targetText && keywordList) {
      let lowCaseText, temp, temp2, temp3, current, keyIndex;
      for (let keyword of keywordList) {
        keyIndex = 0;
        current = 0;
        do {
          // Find keyword from given start
          lowCaseText = targetText.toLowerCase();
          keyIndex = lowCaseText.indexOf(keyword, current);
          if (keyIndex > -1) {
            current = keyIndex + keyword.length;
            temp = targetText.slice(0, keyIndex);
            temp2 = targetText.slice(keyIndex, current);
            temp3 = targetText.slice(current);
            if (searchKeyword && searchKeyword === keyword) {
              targetText = `${temp}${ShStartText}${temp2}${ShEndText}${temp3}`;
              // Move start position
              current = current += ShLength;
            } else {
              targetText = `${temp}${HlStartText}${temp2}${HlEndText}${temp3}`;
              // Move start position
              current = current += HlLength;
            }
          }
        } while (keyIndex >= 0);
      }
      if (joinTextMode) {
        // Remove joiner from text
        targetText = targetText.replaceAll('%|%', '');
      }
      item.highlighted_text = targetText;
    }
  }
  console.timeEnd('hk0');
  return messageList;
};

export default {
  namespaced: true,
  state: {
    categoryData: [],
    keywordData: [],
    locationData: [],
    ownMediaData: [],
  },
  getters: {
    ownMediaData: (state) => state.ownMediaData,
    defaultOwnMediaData: () => [],
    categoryData: (state) => state.categoryData,
    totalCategory({ categoryData }) {
      if (categoryData) return categoryData.length;
      return 0;
    },
    categoryLevelNameList({ categoryData }) {
      if (categoryData) {
        return categoryData.map((c) => c.name);
      }
      return ['categories'];
    },
    categoryLevelNameObject({ categoryData }) {
      return getCatLevelNameObj(categoryData);
    },
    categoryLevelKey({ categoryData }) {
      return Object.keys(getCatLevelNameObj(categoryData));
    },
    keywordList({ keywordData }) {
      if (keywordData) {
        return keywordData
          .map((k) => k.id)
          .filter((k) => k.length > 0)
          .sort((a, b) => b.length - a.length);
      }
      return [];
    },
    categoryNameList({ categoryData }) {
      if (categoryData && categoryData[0]) {
        return categoryData[0].categories
          .filter((c) => c.visible)
          .map((c) => c.category);
      }
      return [];
    },
    subCategoryList({ categoryData }) {
      if (categoryData && categoryData.length > 1) {
        return categoryData
          .filter((c) => c.level > 0)
          .map((c) => {
            return {
              level: c.level,
              category: c.categories.map((x) => x.category),
            };
          });
      }
      return [];
    },
    visibleCatList({ categoryData }) {
      if (categoryData && categoryData.length > 0) {
        return categoryData.map((c) => {
          return {
            name: c.name,
            level: c.level,
            category: c.categories.map((x) => x.category),
          };
        });
      }
      return [];
    },
    categoryTreeData({ categoryData }) {
      const result = {
        tree: [],
        idMap: {},
        defaultState: {},
      };
      if (categoryData && categoryData.length > 0) {
        for (let catLevelObj of categoryData) {
          const minData = [];
          const { level, categories, name } = catLevelObj;
          const levelData = [];
          categories
            .filter((x) => x.visible)
            .forEach((x) => {
              const v = `${level}::${x.category}`;
              levelData.push({
                value: v,
                label: helper.capitalize(x.category),
                category: name,
                color: x.color,
              });
              minData.push(v);
            });
          result.tree.push({
            id: name,
            name: name,
            children: levelData,
            level: level,
          });
          result.defaultState[name] = minData;
        }
      }
      return result;
    },
    defaultCategoryVisibleMap({ categoryData }) {
      // Visible only list by each level
      const result = [];
      if (categoryData && categoryData.length > 0) {
        for (let i in categoryData) {
          // const { level, categories } = catLevelObj;
          // result[level] = categories.filter((x) => x.visible).map((o) => o.category);
          const { categories } = categoryData[i];
          result.push(
            categories.filter((x) => x.visible).map((o) => o.category)
          );
        }
      }
      return result;
    },
    // categoryTreeDefault({ categoryData }) {
    //   const result = {};
    //   if (categoryData && categoryData.length > 0) {
    //     for (let catLevelObj of categoryData) {
    //       const { level, categories, name } = catLevelObj;
    //       result[name] = categories
    //         .filter((x) => x.visible)
    //         .map((x) => {
    //           return `${level}::${x.category}`;
    //         });
    //     }
    //   }
    //   return result;
    // },
    locationData: (state) => state.locationData,
    locationNameList: ({ locationData }) => {
      if (locationData) {
        return locationData.map((obj) => obj.name);
      }
      return [];
    },
    locationNameMap: ({ locationData }) => {
      let result = {};
      if (locationData) {
        for (let l of locationData) {
          result[l.name] = l;
        }
      }
      return result;
    },
  },
  actions: {
    async getAccountCategory({ commit }) {
      const result = await api.getAccountCategory().catch(() => {});
      if (result && result.message) {
        commit('setCategoryData', result.message);
      }
      return result.message;
    },
    async getAccountKeyword({ commit }) {
      const result = await api.getAccountKeyword().catch(() => {});
      if (result && result.message) {
        commit('setKeywordData', result.message);
      }
    },
    highlightKeywords({ getters }, data) {
      return legacyHighlightKeyword(getters, data);
    },
    async getLocationData({ commit }) {
      const result = await api.getAllLocation().catch(() => {});
      if (result && result.message) {
        commit('setLocationData', result.message);
      }
    },
    async getOwnMedia({ commit }) {
      const result = await api.getSocialMediaGroups().catch(() => {});
      if (result && result.message) {
        commit('setOwnMedia', result.message);
      }
    },
    setVisible({ commit }, configData) {
      commit('setVisible', configData);
    },
  },
  mutations: {
    setCategoryData(state, data) {
      // Object.assign(state, 'categoryData', [...data]);
      state.categoryData = [...data];
      // Set backup category data in localStorage
      localStorage.setItem(
        'category-name',
        JSON.stringify(getCatLevelNameObj(data))
      );
      // console.log(state.categoryData, data);
    },
    setKeywordData(state, data) {
      state.keywordData = [...data];
    },
    setLocationData(state, data) {
      state.locationData = [...data];
    },
    setVisible(state, data) {
      for (let i in state.categoryData[data.level].categories) {
        if (
          state.categoryData[data.level].categories[i].category.match(data.name)
        ) {
          state.categoryData[data.level].categories[i].visible = data.visible;
        }
      }
    },
    setOwnMedia(state, data) {
      state.ownMediaData = [...data];
    },
  },
};
