<template>
  <a-card title="Top 10 Most Engaged Post">
    <div class="list-wrapper">
      <div v-if="loading" class="load-wrapper">
        <a-skeleton :loading="loading" active />
      </div>
      <div v-if="!loading && topPostList.data.length <= 0" class="load-wrapper">
        <NoDataFound></NoDataFound>
      </div>
      <div v-if="!loading && topPostList.data.length > 0">
        <MessageEngagement :topPost="topPostList"></MessageEngagement>
      </div>
    </div>
  </a-card>
</template>

<script>
import { onMounted, reactive, watch, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import MessageEngagement from './MessageEngagement.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';

export default {
  name: 'TopEngagePost',
  components: {
    MessageEngagement,
    NoDataFound,
  },
  props: {
    filterResult: Object,
    mode: String,
  },
  setup(props) {
    const store = useStore();
    const { filterResult, mode } = toRefs(props);
    // const filterCriteria = computed(() => store.getters['filter/filterCriteria']);
    let loading = ref(false);
    const error = ref(false);
    let sortBy = ref();
    const topPostList = reactive({
      data: [],
      subCategoryName: {},
    });

    const initEngagement = async (fv) => {
      if (mode.value === 'engagement') {
        sortBy.value = 'engagement_score';
      } else if (mode.value === 'view') {
        sortBy.value = 'view_count';
      } else if (mode.value === 'mention') {
        sortBy.value = 'comment_count';
      }
      loading.value = true;
      error.value = false;
      const result = await api
        .getSearchData({
          ...fv,
          sort: {
            field: sortBy.value,
            direction: 'desc',
          },
          highlight: {
            enable: true,
          },
          source: fv.source,
        })
        .catch(() => {
          error.value = true;
        });
      if (result && result.message && result.message.data) {
        const highlightResult = await store.dispatch('config/highlightKeywords', {
          messageList: result.message.data,
          truncateAt: 0,
        });
        topPostList.data = highlightResult;
        topPostList.subCategoryName = result.message.subCategoryName;
        loading.value = false;
      }
    };

    watch(
      () => filterResult.value,
      () => {
        initEngagement(filterResult.value);
      }
    );

    watch(
      () => mode.value,
      () => {
        initEngagement(filterResult.value);
      }
    );

    onMounted(() => {
      initEngagement(filterResult.value);
    });

    return {
      // filterResult,
      topPostList,
      initEngagement,
      loading,
      error,
      sortBy,
    };
  },
};
</script>

<style></style>
