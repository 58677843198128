<template>
  <a-row class="social-movement-filter-mode">
    <a-radio-group
      v-model:value="selectedDataAspect"
      button-style="solid"
      size="default"
      class="source-group"
    >
      <a-radio-button
        v-for="mode in modeOptions"
        :key="mode.value"
        :value="mode.value"
        class="margin-tab"
        :class="{
          'btn-facebook': mode.icon == 'facebook',
          'btn-twitter': mode.icon == 'twitter',
          'btn-instagram': mode.icon == 'instagram',
          'btn-youtube': mode.icon == 'youtube',
          'btn-blockdit': mode.icon == 'blockdit',
          'btn-tiktok': mode.icon == 'tiktok',
          'btn-website': mode.icon == 'globe',
          'btn-comments': mode.icon == 'comments',
          disabled: noData,
        }"
        style="height: 34px"
        @click="handleModeClick(mode.value)"
      >
        <span
          v-if="mode.value !== 'facebook' && mode.value !== 'blockdit'"
          class="source-icon-div"
        >
          <FeatherIcon
            v-if="mode.value === 'website'"
            :type="mode.icon"
            class="icon-website"
          />
          <FontAwesomeIcon
            v-if="mode.value === 'pantip'"
            :icon="['fas', mode.icon]"
            class="icon-comments"
          />
          <FontAwesomeIcon
            v-else
            :icon="['fab', mode.icon === 'twitter' ? 'x-twitter' : mode.icon]"
            :class="{
              'icon-twitter': mode.icon == 'twitter',
              'icon-instagram': mode.icon == 'instagram',
              'icon-youtube': mode.icon == 'youtube',
              'icon-tiktok': mode.icon == 'tiktok',
            }"
          />
        </span>
        <img
          v-if="mode.value !== 'facebook' && mode.value === 'blockdit'"
          class="source-img icon-blockdit"
          :src="require('@/assets/social/Blockdit.svg')"
          alt="logo"
        />
        <FontAwesomeIcon
          v-else-if="mode.value === 'facebook' && mode.value !== 'blockdit'"
          :icon="['fab', mode.icon]"
          class="icon-facebook"
        />
        <span class="source-title">{{
          mode.icon === 'twitter' ? 'X (Twitter)' : mode.label
        }}</span>
      </a-radio-button>
    </a-radio-group>
  </a-row>
</template>

<script>
import { toRefs, ref } from 'vue';
import api from '../../services/api';

export default {
  name: 'SourceButton',
  props: {
    selectedSource: String,
    noData: Boolean,
  },
  setup(props, { emit }) {
    const { selectedSource, noData } = toRefs(props);
    const selectedDataAspect = ref(noData.value ? '' : selectedSource.value);
    const allSources = api.getAllSource();
    const modeOptionInfos = [
      { label: 'Facebook', value: 'facebook', icon: 'facebook' },
      { label: 'Twitter', value: 'twitter', icon: 'twitter' },
      { label: 'Instagram', value: 'instagram', icon: 'instagram' },
      { label: 'Youtube', value: 'youtube', icon: 'youtube' },
      { label: 'Pantip', value: 'pantip', icon: 'comments' },
      { label: 'Blockdit', value: 'blockdit', icon: 'blockdit' },
      { label: 'Tiktok', value: 'tiktok', icon: 'tiktok' },
      { label: 'Website', value: 'website', icon: 'globe' },
    ];
    const modeOptions = allSources.map((source) =>
      modeOptionInfos.find((v) => v.value === source)
    );
    const handleModeClick = (source) => {
      selectedDataAspect.value = source;
      emit('handleModeClick', source);
    };

    return {
      selectedDataAspect,
      modeOptions,
      handleModeClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.social-movement-filter-mode {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1145px) {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }
  .source-group {
    flex: 1 1 auto;
    display: flex;
  }
  .fa-circle {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 14px;
  }
  .icon-social {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 8px;
    color: #fff;
    padding: 3px 0;
  }
  .icon-website {
    line-height: 14px !important;
    vertical-align: text-bottom;
  }
  .icon-facebook,
  .icon-twitter,
  .icon-instagram,
  .icon-youtube,
  .icon-tiktok,
  .icon-comments,
  .icon-blockdit,
  .icon-website {
    vertical-align: middle;
  }
  .ant-radio-button-wrapper-checked {
    .icon-facebook {
      color: #1890ff;
      background: #f3f8fe;
    }
    // .fa-stack {
    .icon-twitter {
      color: #000000;
      background: #f4fafe;
    }
    .icon-instagram {
      color: #dd2a7b;
      background: #fdf4f8;
    }
    .icon-youtube {
      color: #e32212;
      background: #fef4f3;
    }
    .icon-comments {
      color: #7459c8;
      background: #f8f7fc;
    }
    .icon-blockdit {
      color: #4a69ff;
      background: #f6f8ff;
    }
    .icon-tiktok {
      color: #252525;
      background: #f4f5f7;
    }
    .icon-website {
      color: #1bc5bd;
      background: #f4fcfc;
    }
    .icon-globe {
      color: #fff;
    }
    .icon-social {
      color: red;
    }
    // }
  }
  .source-img {
    height: 14px;
    width: 14px;
    &.icon-blockdit {
      margin-right: 3px;
    }
  }
  .ant-radio-button-wrapper {
    position: unset;
    font-weight: 500;
    font-size: 14px;
    color: #9299b8;
  }
  .social-movement-data-aspect {
    margin-right: 14px;
    font-size: 14px;
    color: #5a5f7d;
  }
  .margin-tab {
    margin: 0 10px 0 0;
    border: 1px solid #9299b8;
    border-radius: 4px;
    text-align: center;
    .source-title {
      padding-left: 8px;
    }
  }
  .ant-radio-button-wrapper-checked.btn-facebook {
    background-color: #f3f8fe !important;
    border-color: #1877f2 !important;
    color: #1877f2 !important;
  }
  .ant-radio-button-wrapper-checked.btn-twitter {
    background-color: #f4fafe !important;
    border-color: #000000 !important;
    color: #000000 !important;
  }
  .ant-radio-button-wrapper-checked.btn-instagram {
    background-color: #fdf4f8 !important;
    border-color: #dd2a7b !important;
    color: #dd2a7b !important;
  }
  .ant-radio-button-wrapper-checked.btn-youtube {
    background-color: #fef4f3 !important;
    border-color: #e32212 !important;
    color: #e32212 !important;
  }
  .ant-radio-button-wrapper-checked.btn-comments {
    background-color: #f8f7fc !important;
    border-color: #7459c8 !important;
    color: #7459c8 !important;
  }
  .ant-radio-button-wrapper-checked.btn-blockdit {
    background-color: #f6f8ff !important;
    border-color: #4a69ff !important;
    color: #4a69ff !important;
  }
  .ant-radio-button-wrapper-checked.btn-blockdit > span > img {
    content: url('../../assets/social/Blockdit-check.svg');
  }
  .ant-radio-button-wrapper-checked.btn-tiktok {
    background-color: #f4f5f7 !important;
    border-color: #252525 !important;
    color: #252525 !important;
  }
  .ant-radio-button-wrapper-checked.btn-website {
    background-color: #f4fcfc !important;
    border-color: #1bc5bd !important;
    color: #1bc5bd !important;
  }
}
</style>

<style scoped>
:deep(.icon-website .feather-globe) {
  width: 14px;
  height: 14px;
}
:deep(.margin-tab span) {
  width: fit-content;
  display: flex;
  vertical-align: middle;
  align-items: center;
}
:deep(
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
  ),
:deep(
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):hover
  ) {
  box-shadow: none;
}
:deep(
    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):active::before
  ) {
  box-shadow: none;
}
:deep(
    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      ):focus-within
  ) {
  box-shadow: none;
}
</style>
