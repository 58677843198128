import helper from '@/services/helper';

export function convertValue(value) {
  if (value) {
    return helper.numeral(value);
  }
  return 0;
}

export function mapChannelListToTable(channel) {
  const background = '#def7ef';
  return {
    dataIndex: channel.key,
    key: channel.key,
    align: 'center',
    slots: channel.slots,
    customRender: ({ text, record }) => {
      const convertedText = text ? convertValue(text.value) : '';
      if (record && record[channel.key]?.highestValue) {
        return {
          children: convertedText,
          props: {
            style: { background },
          },
        };
      }
      return {
        children: convertedText,
      };
    },
  };
}

export default {
  convertValue,
  mapChannelListToTable,
};
