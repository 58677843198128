<template>
  <AppLayout>
    <router-view v-slot="{ Component }">
      <Transition name="slide-fade">
        <component :is="Component" />
      </Transition>
    </router-view>
    <!-- <router-view /> -->
    <MessageModal />
    <ConsentModal />
    <SeeMoreDialog />
  </AppLayout>
</template>

<script>
import AppLayout from './components/Layout/AppLayout.vue';
import MessageModal from './components/Message/MessageModal.vue';
import SeeMoreDialog from './components/Seemore/SeeMoreDialog.vue';
import ConsentModal from '@/components/Modal/ConsentModal.vue';

export default {
  components: {
    AppLayout,
    MessageModal,
    SeeMoreDialog,
    ConsentModal,
  },
  setup() {},
};
</script>

<style lang="scss">
@import './config/theme/colors.json';

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

// .slide-fade-leave-active {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

body {
  font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif !important;
}
.ant-tag.ant-select-selection-item {
  font-family: 'DM Sans', 'IBM Plex Sans Thai', sans-serif !important;
}

#app {
  height: 100vh;
  max-height: -webkit-fill-available;
  .app-wrapper {
    height: 100%;
  }
}

// Color helper
.pale-gray {
  color: #9299b8 !important;
}
.darker-gray {
  color: #5a5f7d !important;
}

// THEME default -- TODO should seperate this to another file
.ant-card.card-height .ant-card-head .ant-card-head-wrapper {
  height: 75px;
}
.ant-card {
  margin-bottom: 25px;
  .ant-card-head .ant-card-head-title {
    padding: 16.5px 0;
    line-height: 1.4;
  }
  .ant-card-head-title .ant-page-header-heading-title {
    font-weight: 500;
    font-size: 20px;
  }
  .ant-card-body {
    padding: 25px;
    table {
      .ant-tag {
        line-height: 18px;
        border: 0 none;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        &.early {
          background: $primary-color;
        }
        &.late {
          background: $warning-color;
        }
        &.complete {
          background: $success-color;
        }
        &.progress {
          background: $danger-color;
        }
      }
    }
  }
  &.ant-card-bordered {
    border-radius: 10px;
    overflow: hidden;
    border: none;
  }
  .custom-label {
    font-size: 13px;
    color: #868eae;
    display: inline-flex;
    align-items: center;
  }
  .custom-label:not(:last-child) {
    margin-right: 20px;
  }

  .custom-label span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 7px;
  }

  .ant-card-extra .ant-dropdown-trigger {
    line-height: 0;
    order: 1;
    margin-right: 20px;
  }

  .growth-downward h1 sub,
  .growth-upward h1 sub {
    font-size: 14px;
    font-weight: 600;
    bottom: 0;
    right: 5px;
  }

  &.no-body-padding {
    .ant-card-body {
      padding: 0;
    }
  }

  &.no-y-padding {
    .ant-card-body {
      padding: 0 25px;
    }
  }
}

.ant-select-dropdown
  .ant-select-item.ant-select-item-option-selected
  .ant-select-item-option-content {
  padding-left: 0 !important;
}

.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-placeholder {
  line-height: 24px !important;
}

.mini-title .ant-page-header-heading-title {
  font-size: 20px;
}

.ant-btn {
  border-width: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 15px;
  font-weight: 500;
  box-shadow: 0 0;
  color: #5a5f7d;
  height: 42px;
  // TODO write this as mixin + dynamic var
  &.ant-btn-white {
    background: $white-color;
    &:hover,
    &:focus {
      background: $white-hover;
    }
  }
  &.ant-btn-success {
    background: $success-color;
    color: #fff;
    &:hover,
    &:focus {
      background: $success-hover;
      color: #fff;
    }
  }
  &.ant-btn-primary {
    background: $primary-color;
    color: #fff;
    &:hover,
    &:focus {
      background: $primary-hover;
      color: #fff;
    }
  }
  &.ant-btn-dangerous {
    background: $danger-color;
    color: #fff;
    &:hover,
    &:focus {
      background: $danger-hover;
      color: #fff;
    }
  }
  &.ant-btn-warning {
    background: $warning-color;
    color: #fff;
    &:hover,
    &:focus {
      background: $warning-hover;
      color: #fff;
    }
  }
  &.ant-btn-dark {
    background: $dark-color;
    color: #fff;
    &:hover,
    &:focus {
      background: $dark-hover;
      color: #fff;
    }
    &.btn-transparent {
      background: transparentize($dark-color, 0.9);
      color: $dark-color;
      &:hover,
      &:focus {
        background: transparentize($dark-hover, 0.9);
        border-width: 0px;
        color: $dark-hover;
      }
    }
  }
  &.circular {
    border-radius: 50%;
  }
  &.ant-btn-lg {
    height: 48px;
  }
  &.ant-btn-sm {
    height: 36px;
  }
  &.ant-btn-xs {
    height: 29px;
  }

  i,
  svg,
  img {
    width: 16px;
    height: 16px;
  }
  // span {
  //   margin-left: 6px;
  // }
}

// checkbox override
.ant-checkbox-inner {
  border-radius: 4px;
}
.ant-checkbox-checked::after {
  border-radius: 4px;
}
.ant-checkbox-inner::after {
  width: 4px;
  height: 7.5px;
  top: 40%;
  left: 25%;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 4px;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  // override check size
  border: 1px solid #fff;
  border-top: 0;
  border-left: 0;
}
.ant-checkbox-group.vertical .ant-checkbox-wrapper {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-radius: 4px;
  background-color: #1890ff;
  border-color: #1890ff;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #fff;
  width: 8px;
  height: 1px;
}

// Custom checkbox
.ex-tree-select span {
  color: #5a5f7d !important;
}
.filter-content .ant-checkbox-wrapper {
  color: #5a5f7d !important;
}
.ex-leaf {
  .ant-checkbox-group.vertical .ant-checkbox-wrapper {
    margin-bottom: 12px;
  }
}

// Override Select box
.rounded-select {
  .ant-select-selector {
    border: none !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-item {
    color: #5a5f7d;
  }
}

// pageheader
.page-header-actions {
  button,
  div {
    margin: 0 0 0 8px !important;
    font-size: 14px;
  }
  button {
    height: 38px !important;
  }
}

// Remove global padding popover

// sentiment-selector popover
.sentiment-selector-wrap {
  .ant-popover-inner-content {
    padding: 10px;
  }
}
.sentiment-selector {
  position: relative;
  width: 88px;
  margin-top: -15px;
  margin-left: -15px;
  .inner-wrapper {
    position: absolute;
    display: flex;
    column-gap: 10px;
    .icon {
      cursor: pointer;
    }
  }
}

.no-dropdown {
  display: none !important;
}

.and-content {
  flex-wrap: nowrap;
  overflow-x: auto;
  .ant-select-selection-overflow-item {
    position: relative;
  }
  .ant-select-selection-overflow-item:not(:first-child):not(:last-child) {
    margin-left: 40px;
    &::before {
      content: 'AND';
      position: absolute;
      left: -35px;
      top: 4px;
    }
  }
  .ant-select-selection-overflow-item-suffix::after {
    content: '' !important;
  }
}

.or-content {
  flex-wrap: nowrap;
  overflow-x: auto;
  .ant-select-selection-overflow-item {
    position: relative;
  }
  .ant-select-selection-overflow-item:not(:first-child):not(:last-child) {
    margin-left: 40px;
    &::before {
      content: 'OR';
      position: absolute;
      left: -35px;
      top: 4px;
    }
  }
  .ant-select-selection-overflow-item-suffix::after {
    content: '' !important;
  }
}

.pantip-icon svg {
  height: 10px;
  margin-left: 1px;
  padding-top: 1px;
}
// .website-icon {
// padding-left: 1px;
// }

// Override overview > category menu
#menu-group {
  .ant-dropdown-menu-item-group {
    padding: 5px 0;
  }
  .ant-dropdown-menu-item-group-title {
    font-size: 12px;
  }
}

// Override daterangepicker
.daterangepicker {
  font-family: 'DM Sans', sans-serif !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #3371ff !important;
}
.daterangepicker .ranges li.active {
  background-color: #3371ff !important;
}
.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: none !important;
}
.daterangepicker .applyBtn {
  background-color: #3371ff;
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 8px 12px !important;
  cursor: pointer;
}
.daterangepicker .cancelBtn {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #333;
  padding: 8px 12px !important;
  cursor: pointer;
}
// Influencer page
.vis-network {
  outline: none !important;
  .vis-tooltip {
    position: absolute !important;
    background-color: #fff;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 10px;
    z-index: 121;
    .name {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 600;
    }
    .graph-info {
      text-align: center;
      font-size: 12px;
      u {
        color: rgba(0, 0, 0, 0.87);
        font-weight: 600;
      }
    }
    table tr td {
      padding: 10px;
      margin: 10px;
    }
  }
  .vis-navigation {
    .vis-button {
      height: 30px;
      width: 30px;
      position: absolute;
      &.vis-up {
        bottom: 50px;
        right: 50px;
        background-image: url(https://app.ask-dom.com/images/network/upArrow.png);
      }
      &.vis-down {
        bottom: 10px;
        right: 50px;
        background-image: url(https://app.ask-dom.com/images/network/downArrow.png);
      }
      &.vis-left {
        bottom: 10px;
        right: 90px;
        background-image: url(https://app.ask-dom.com/images/network/leftArrow.png);
      }
      &.vis-right {
        bottom: 10px;
        right: 10px;
        background-image: url(https://app.ask-dom.com/images/network/rightArrow.png);
      }
      &.vis-zoomIn {
        top: 10px;
        right: 10px;
        background-image: url(https://app.ask-dom.com/images/network/plus.png);
      }
      &.vis-zoomOut {
        top: 50px;
        right: 10px;
        background-image: url(https://app.ask-dom.com/images/network/minus.png);
      }
      &.vis-zoomExtends {
        top: 10px;
        right: 50px;
        background-image: url(https://app.ask-dom.com/images/network/zoomExtends.png);
      }
    }
  }
}

// all message
.highlight-text-content em,
.search-highlight {
  color: #3371ff !important;
}
.text-highlight {
  color: #272b41;
}

// location page
#loc-auto {
  .ant-select-selection-placeholder {
    padding: 6px 11px;
  }
  input.ant-select-selection-search-input {
    border: none;
  }
}
#loc-auto-mobile {
  .ant-select-selection-search-input {
    height: 32px;
    border-radius: 18px;
    font-size: 14px;
  }
  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 32px;
  }
}

#sort-selector,
#count-selector,
#alert-review-selector,
#level-select {
  .text-title {
    color: #5a5f7d;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    // border: none !important;
    border: 1px solid #d9d9d9 !important;

    .ant-select-selection-placeholder {
      color: #5a5f7d;
      line-height: 36px !important;
      font-weight: 500;
    }
  }
  .ant-select-selector .ant-select-selection-item {
    // line-height: 38px !important;
    line-height: 36px !important;
    color: #5a5f7d;
    font-weight: 500;
  }
  &.mobile {
    .ant-select {
      width: 100%;
    }
    .ant-select-selector {
      border: 1px solid #d9d9d9 !important;
    }
    .ant-select-selector .ant-select-selection-item {
      line-height: 36px !important;
    }
  }
}
// Setting page

#filter-select {
  .ant-select-selector {
    border: 1px solid #e3e6ef;
    border-radius: 4px !important;
  }
  .ant-select-selector .ant-select-selection-item {
    color: #5a5f7d !important;
  }
}

#role-input {
  .ant-select-selector {
    border-radius: 4px;
    height: 42px;
  }
  .ant-select-selector .ant-select-selection-item {
    line-height: 42px !important;
  }
}

// THEME override -- TODO should seperate this to another file

// Helper class

// padding and margin - material style 4x
$pos: (
  'a': '',
  't': '-top',
  'l': '-left',
  'r': '-right',
  'b': '-bottom',
);
@mixin paddingHelper($size) {
  @each $pa, $pn in $pos {
    .p#{$pa}-#{$size} {
      padding#{$pn}: #{$size * 4}px;
    }
    .m#{$pa}-#{$size} {
      margin#{$pn}: #{$size * 4}px;
    }
  }
}
@for $i from 0 through 4 {
  @include paddingHelper($i);
}

.w-100 {
  width: 100%;
}

.no-margin-tab {
  .ant-tabs-bar {
    margin: 0;
  }
}

.message-card .ant-card-body {
  padding: 0 !important;
}

.d-flex {
  display: flex;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-align-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal {
  top: 24px;
}
.cursor-pointer {
  cursor: pointer;
}
.word-break-all {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// mediablock + safari fix
.media-block {
  .tweet-wrapper > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

// Comparison
.mode-menu-mobile-2 {
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
  }
  .ant-select-selector {
    border: none;
  }
}
#mode-menu-mobile {
  box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
  border-radius: 0px 0px 6px 6px;

  .ant-dropdown-menu-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.active {
      background-color: #5f63f205;
    }
  }

  .ant-dropdown-menu-item-group:hover {
    background-color: #fff;
  }

  .ant-dropdown-menu-item-group {
    padding: 5px 0px;
  }
  .ant-dropdown-menu-item-group-title {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    color: #9299b8;
  }
  .ant-dropdown-menu-item-group-list {
    padding: 0px;
    margin: 0px;
  }
  .ant-dropdown-menu-item {
    padding: 5px 13px;
  }
}
</style>
