<template>
  <Collapsible
    title="Type of Message"
    :sub-title="messageTypeTag.length"
    :is-expand="isExpanding"
    :tags="messageTypeTag"
    @toggle="isExpanding = !isExpanding"
  >
    <template #expand>
      <a-checkbox-group
        v-model:value="localState"
        class="vertical mt-20"
        name="checkboxgroup-type-of-message"
        :options="choices"
        @change="onChange"
      />
    </template>
    ></Collapsible
  >
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import Collapsible from '../Collapsible.vue';

const defaultList = ['origin', 'comment'];
const defaultChoices = [
  { label: 'Post', value: 'origin' },
  { label: 'Comment', value: 'comment' },
];

export default {
  components: {
    Collapsible,
  },
  setup() {
    const store = useStore();
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const isExpanding = ref(false);
    const choices = defaultChoices;

    const localState = ref([...defaultList]);

    watch(
      () => tempCriteria.value.messageType,
      () => {
        if (tempCriteria.value.messageType) {
          localState.value = [...tempCriteria.value.messageType];
        }
      }
    );

    const messageTypeTag = computed(() => {
      const arr = [];
      for (let k of defaultChoices) {
        if (localState.value.includes(k.value))
          arr.push({
            name: k.value,
            text: k.label,
          });
      }
      return arr;
    });

    const onChange = () => {
      store.dispatch('filter/updateTempCriteria', {
        messageType: localState.value,
      });
    };

    return {
      isExpanding,
      choices,
      localState,
      messageTypeTag,
      onChange,
    };
  },
};
</script>

<style lang="scss" scoped></style>
