<template>
  <a-input
    v-model:value="searchInput"
    class="search-input"
    placeholder="Search..."
    size="large"
    @change="quickSearch"
  >
    <template #prefix>
      <FeatherIcon type="search" size="16" color="#5a5f7d" />
    </template>
    <template #suffix>
      <span
        v-if="searchInput.length > 0"
        class="btn-search"
        @click="resetSearchInput"
      >
        <FeatherIcon type="x" size="14" color="#ADB4D2" />
      </span>
      <a-popover
        v-model:visible="searchPopoverVisible"
        :getPopupContainer="(trigger) => trigger.parentNode"
        placement="bottom"
        arrow-point-at-center
        trigger="click"
      >
        <template #content>
          <div class="advance-search-content">
            <div class="wrapper advance-search-modal">
              <div class="head-row">
                <div class="title-text">Advanced Search</div>
                <div class="right-tool">
                  <a-button
                    type="ghost"
                    class="ant-btn-sm circular"
                    style="width: 34px"
                    @click="close"
                  >
                    <FeatherIcon type="x" size="18" />
                  </a-button>
                </div>
              </div>
              <a-tabs v-model:activeKey="activeKey" class="no-margin-tab">
                <a-tab-pane key="1" tab="Basic">
                  <div class="tab-title">
                    <FeatherIcon type="key" color="#3371ff" size="13" />
                    Keywords & Hashtag
                  </div>
                  <div class="title-row">
                    contain <span class="blue">ALL</span> of these words
                  </div>
                  <a-select
                    v-model:value="containAllList"
                    class="and-content"
                    dropdown-class-name="no-dropdown"
                    mode="tags"
                    style="width: 100%; max-height: 150px; max-width: 1024px"
                    placeholder="Enter keywords or hashtags or a phrases separated by commas"
                    :options="options"
                    :token-separators="[',']"
                  >
                  </a-select>
                  <div class="example-row">
                    Example: cats dogs · contains both “cats” and “dogs”
                  </div>
                  <div class="title-row">
                    contain <span class="blue">ANY</span> of these words
                  </div>
                  <a-select
                    v-model:value="containAnyList"
                    class="or-content"
                    dropdown-class-name="no-dropdown"
                    mode="tags"
                    style="width: 100%; max-height: 150px; max-width: 1024px"
                    placeholder="Enter keywords or hashtags or a phrases separated by commas"
                    :options="options"
                    :token-separators="[',']"
                  >
                  </a-select>
                  <div class="example-row">
                    Example: cats dogs · contains either “cats” or “dogs” (or
                    both)
                  </div>

                  <div class="title-row">
                    <span class="blue">NONE</span> of these words
                  </div>
                  <a-select
                    v-model:value="containNoneList"
                    class="and-content"
                    dropdown-class-name="no-dropdown"
                    mode="tags"
                    style="width: 100%; max-height: 150px; max-width: 1024px"
                    placeholder="Enter keywords or hashtags or a phrases separated by commas"
                    :options="options"
                    :token-separators="[',']"
                  >
                  </a-select>
                  <div class="example-row">
                    Example: cats dogs · does not contain “cats” and does not
                    contain “dogs”
                  </div>
                  <div class="tab-title">
                    <FeatherIcon type="user" color="#3371ff" size="13" />
                    Username
                  </div>

                  <div class="flex gap mb-4 gray-text username-wrapper">
                    <div class="option-include">
                      <label class="mr-2">Options:</label>
                      <a-radio-group
                        class="custom-radio"
                        v-model:value="usernameOption"
                      >
                        <a-radio-button value="INCLUDE"
                          ><div class="opt-text">
                            <FeatherIcon type="check" size="14" class="mr-1" />
                            <span>Include</span>
                          </div></a-radio-button
                        >
                        <a-radio-button value="EXCLUDE" class="exclude-radio"
                          ><div class="opt-text">
                            <FeatherIcon type="slash" size="14" class="mr-1" />
                            <span>Exclude</span>
                          </div></a-radio-button
                        >
                      </a-radio-group>
                    </div>
                    <div
                      class="option-seperator"
                      style="width: 1px; background-color: #dcdde4"
                    >
                      &nbsp;
                    </div>
                    <div class="option-condition">
                      <label class="mr-2">Conditions:</label>
                      <a-radio-group
                        v-model:value="usernameMode"
                        class="usermode gray-text"
                        name="username-mode"
                      >
                        <a-radio :value="'BEGINS_WITH'">begins with</a-radio>
                        <a-radio :value="'IS_EXACTLY'">is exactly</a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                  <a-select
                    v-model:value="usernameList"
                    class="and-content"
                    dropdown-class-name="no-dropdown"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Enter username separated by commas"
                    :options="options"
                    :token-separators="[',']"
                  >
                  </a-select>
                  <div class="example-row">
                    Example: InsighteraTH · posted from @InsighteraTH
                  </div>
                </a-tab-pane>
              </a-tabs>
              <div class="footer">
                <div>
                  <a-button type="default" @click="clearSearchAndApply">
                    <FeatherIcon class="mr-1" type="x" size="14" />
                    Clear all
                  </a-button>
                </div>
                <div>
                  <a-button type="default" class="mr-1" @click="close"
                    >Cancel</a-button
                  >
                  <a-button type="primary" @click="searchData">Search</a-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <span class="btn-search">
          <FeatherIcon type="sliders" size="14" color="#ADB4D2" />
        </span>
      </a-popover>
    </template>
  </a-input>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

export default {
  setup() {
    const store = useStore();

    const searchPopoverVisible = ref(false);
    const activeKey = ref('1');
    const containAllList = ref([]);
    const containAnyList = ref([]);
    const containNoneList = ref([]);
    const usernameList = ref([]);
    const usernameMode = ref('BEGINS_WITH');
    const usernameOption = ref('INCLUDE');

    const prepareAdvanceSearchStr = () => {
      let str = '';
      const allStr = containAllList.value.join('" AND "');
      const orStr = containAnyList.value.join('" OR "');
      const noneStr = containNoneList.value
        .map((word) => `-"${word}"`)
        .join(' AND ');
      if (containAllList.value.length > 0) {
        str = `("${allStr}")`;
      }
      if (containAnyList.value.length > 0) {
        if (str.length === 0) {
          str = `("${orStr}")`;
        } else {
          str = `${str} AND ("${orStr}")`;
        }
      }
      if (containNoneList.value.length > 0) {
        if (str.length === 0) {
          str = `${noneStr}`;
        } else {
          str = `${str} AND -("${noneStr}")`;
        }
      }
      return str;
    };

    const searchData = async () => {
      const filterData = {
        keywordPhrase: '',
        excludeKeywordPhrase: '',
        username: [],
        excludeUsername: [],
      };
      // Do prepare include data
      // (a AND b) AND (c OR d)

      filterData.keywordPhrase = prepareAdvanceSearchStr();
      // Do prepare username
      if (
        usernameMode.value === 'BEGINS_WITH' ||
        usernameMode.value === 'IS_EXACTLY'
      ) {
        filterData.usernameMatchMode = usernameMode.value;
      }

      if (usernameOption.value === 'INCLUDE') {
        // include
        filterData.username = usernameList;
        filterData.excludeUsername = [];
      } else {
        //exclude
        filterData.username = [];
        filterData.excludeUsername = usernameList;
      }
      await store.dispatch('filter/updateTempCriteria', filterData);
      await store.dispatch('filter/applyFilter');
      // await store.dispatch('filter/createQueryUrl');

      // clear and close
      // clearSearch(true);
      close();
    };

    // Header search
    const searchInput = ref('');
    const quickSearch = _.debounce(() => {
      const advStr = prepareAdvanceSearchStr();
      if (advStr && advStr !== searchInput.value) {
        // TODO Do sync back to adv search field
        // Example ("gg" AND "aa") AND ("uu" OR "xx") AND -("GG")
        // AND REGEX ("xxx" [AND "yyy"])
        // OR REGEX ("xxx" [OR "yyy"])
        // EXCLUDE REGEXP = -("xxx" AND "yyy")

        // if has only and
        containAllList.value = [];
        containAnyList.value = [];
        containNoneList.value = [];
      }
      // console.log('search ', searchInput.value);
      store.dispatch('filter/updateTempCriteria', {
        keywordPhrase: searchInput.value,
      });
      store.dispatch('filter/applyFilter');
    }, 1500);

    const filterSearchInput = computed(
      () => store.getters['filter/searchInput']
    );
    watch(
      () => filterSearchInput.value,
      () => {
        searchInput.value = filterSearchInput.value;
      }
    );

    const resetSearchInput = () => {
      searchInput.value = '';
      // should i clear all list
      store.dispatch('filter/updateTempCriteria', {
        keywordPhrase: searchInput.value,
        username: [],
        excludeUsername: [],
      });
      store.dispatch('filter/applyFilter');
      // const rawStoredFilter = localStorage.getItem('stored-filter');
      // if (rawStoredFilter) {
      //   store.dispatch('filter/createQueryUrl');
      // } else {
      //   clearQueryUrl();
      // }
    };

    const clearSearch = (isClose) => {
      containAllList.value = [];
      containAnyList.value = [];
      containNoneList.value = [];
      usernameList.value = [];
      usernameMode.value = 'BEGINS_WITH';
      usernameOption.value = 'INCLUDE';
      if (isClose) {
        close();
      }
    };

    // const clearQueryUrl = () => {
    //   const searchURL = new URL(window.location);
    //   if (searchURL.searchParams.get('shortId')) {
    //     searchURL.searchParams.delete('shortId');
    //     window.history.pushState({}, '', searchURL);
    //     window.location.reload();
    //   }
    // };

    const clearSearchAndApply = () => {
      clearSearch(true);
      // if has searchinput ? should it clear
      resetSearchInput();
    };

    const close = () => {
      searchPopoverVisible.value = false;
    };

    return {
      searchPopoverVisible,
      activeKey,
      containAllList,
      containAnyList,
      containNoneList,
      usernameList,
      usernameMode,
      usernameOption,
      searchInput,
      quickSearch,
      searchData,
      resetSearchInput,
      clearSearch,
      clearSearchAndApply,
      close,
    };
  },
};
</script>

<style lang="scss">
.search-input {
  border-radius: 4px;
  overflow: hidden;
  border: none;
  background-color: #f8f9fb;
  input.ant-input {
    background-color: #f8f9fb !important;
  }
  .btn-search {
    padding: 8px 12px 8px 12px;
    border-radius: 40px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    &:hover {
      background-color: #edeff4;
    }
  }
}
.advance-search-modal {
  overflow-y: auto;
  height: 87vh;
  max-height: 770px;
  padding-right: 1%;
}
.advance-search-content {
  width: 80vw;
  max-width: 1024px;
  .head-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title-text,
  .tab-title {
    font-size: 16px;
    font-weight: 500;
  }
  .tab-title {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .title-row {
    font-size: 14px;
    font-weight: 500;
    margin-top: 24px;
    .blue {
      color: #3371ff;
    }
  }
  .and-content,
  .or-content {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      ) {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    &.ant-select-multiple {
      .ant-select-selector {
        border: none;
        border-radius: 0;
        transition: none;
        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          ) {
          border-color: transparent;
          border-right-width: 0 !important;
          box-shadow: 0;
        }
      }
      .ant-select-selection-item-content {
        max-width: 925px;
      }
      .ant-select-selection-item-remove {
        vertical-align: middle;
        padding: 4px 0;
      }
    }
  }
  .example-row {
    font-size: 13px;
    color: #9299b8;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    .clear-button {
      display: flex;
      align-items: center;
      color: #9299b8;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';
.flex-align-center {
  display: flex;
  align-items: center;
}

.gap {
  gap: 1.5rem;
}

:deep(.ant-radio-wrapper) {
  color: $gray-color;
}
.gray-text {
  color: $gray-color;
}

:deep(.custom-radio) {
  .opt-text {
    line-height: 30px;
    font-weight: 500;
  }

  .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    line-height: 30px;
  }

  .ant-radio-button-wrapper {
    color: $gray-color;

    &.exclude-radio {
      &:hover {
        color: $warning-color;
      }
    }

    &:hover {
      color: $primary-color;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }

    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $primary-color !important;
      border-color: $primary-color;
      background-color: rgba(51, 113, 255, 0.05);

      &::before {
        background-color: $primary-color;
        border-color: $primary-color;
      }

      &.exclude-radio {
        color: $warning-color !important;
        border-color: $warning-color;
        background-color: rgba(250, 139, 12, 0.05);
        &::before {
          background-color: $warning-color;
          border-color: $warning-color;
        }
      }
    }
  }
}

.username-wrapper {
  display: flex;
  align-items: start;
  flex-direction: column;

  .option-seperator {
    display: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .option-seperator {
      display: block;
    }
  }
}
</style>
