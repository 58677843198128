<template>
  <a-card class="campaign-performance-trending" :widget="true" :title="'Timeline'">
    <template #extra>
      <div v-if="!isMobileScreen" class="trending-representation-selector-wrapper">
        <div class="trending-accumulation-wrapper">
          <a-button
            :type="chartType === 'line' && !noData ? 'primary' : 'ghost'"
            class="circular"
            :class="{ 'button-primary-light': chartType === 'line' && !noData }"
            style="width: 42px"
            @click="handleActiveChange('line')"
            :disabled="noData"
          >
            <FeatherIcon type="trending-up" size="15" />
          </a-button>
          <a-button
            :type="chartType === 'column' && !noData ? 'primary' : 'ghost'"
            class="circular"
            style="width: 42px"
            :class="{ 'button-primary-light': chartType === 'column' && !noData }"
            @click="handleActiveChange('column')"
            :disabled="noData"
          >
            <FeatherIcon type="bar-chart" size="15" />
          </a-button>
        </div>
      </div>
      <div v-else class="chart-type">
        <a-dropdown placement="bottomRight" :getPopupContainer="(trigger) => trigger.parentNode">
          <FeatherIcon class="more-menu" type="more-horizontal" size="16" />
          <template #overlay>
            <a-menu id="mode-menu-mobile">
              <a-menu-item-group title="Graph:">
                <a-menu-item style="background: #fff">
                  <a-button
                    :type="chartType === 'line' && !noData ? 'primary' : 'ghost'"
                    class="circular btn-line"
                    :class="{ 'button-primary-light': chartType === 'line' && !noData }"
                    style="width: 42px"
                    @click="chartType = 'line'"
                    :disabled="noData"
                  >
                    <FeatherIcon type="trending-up" size="18" />
                  </a-button>
                  <a-button
                    :type="chartType === 'column' && !noData ? 'primary' : 'ghost'"
                    class="circular"
                    :class="{ 'button-primary-light': chartType === 'column' && !noData }"
                    style="width: 42px"
                    @click="chartType = 'column'"
                    :disabled="noData"
                  >
                    <FeatherIcon type="bar-chart" size="18" /> </a-button
                ></a-menu-item>
              </a-menu-item-group>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </template>
    <div v-if="loading" class="loading-wrapper">
      <a-skeleton :loading="loading" active />
    </div>

    <TrendingChart
      v-else-if="!loading && !noData"
      class="chart"
      :chart-type="chartType"
      :series="selectedSerie"
    ></TrendingChart>

    <div v-if="!loading && noData" class="no-data-wrapper">
      <NoResult></NoResult>
    </div>
  </a-card>
</template>

<script>
import { toRefs, ref, reactive, computed, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';

import TrendingChart from '@/components/Chart/TrendingChart';
import NoResult from '@/components/Error/NoResult.vue';

export default {
  name: 'ChannelTimeline',

  components: {
    NoResult,
    TrendingChart,
  },

  props: {
    mode: String,
    filterResult: Object,
  },

  setup(props) {
    const { mode, filterResult } = toRefs(props);
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const brand = computed(() => store.state.account.brand);

    const sList = api.getAllSource();
    const socialList = reactive(sList);

    let lineActive = ref(true);
    let barActive = ref(false);

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    const chartType = ref('line');
    const seriesData = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const selectedSerie = ref([]);
    let loading = ref(false);
    const noData = ref(false);

    const currentGraphCriteria = ref({});

    const drilldownFn = (pointOption, payload = { title: 'data' }) => {
      const { graphTime } = currentGraphCriteria.value;
      const { x } = pointOption;
      const { time, title } = helper.getTimeOpt(x, graphTime);
      let f = {
        payload: {
          title: `${helper.capitalize(payload.title)} ${helper.capitalize(title)}`,
        },
        criteria: {
          ...filterResult.value,
          time,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
          source: [payload.title.toLowerCase()],
          sentiment: payload.criteria.sentiment,
        },
      };
      store.dispatch('message/showMessageModal', f);
    };

    const init = async (fv) => {
      loading.value = true;
      if (fv) {
        const { graphCriteria, granularity } = chartHelper.modifyGranularity({}, fv);
        currentGraphCriteria.value = graphCriteria;
        const filterCriteria = {
          ...fv,
          ...graphCriteria,
        };

        const result = await api.getCampaignPerformanceChannelTimeline(filterCriteria, brand.value).catch(() => {
          console.error('History api call error', result);
        });
        if (result.message && result.message.graphData && Object.keys(result.message.graphData).length !== 0) {
          const newEngagementSeries = [];
          const newCountSeries = [];
          const newViewSeries = [];
          for (let social of socialList) {
            const engagementDataList = [];
            const countDataList = [];
            const viewDataList = [];
            let name = helper.capitalize(social);
            const data = result.message.graphData.data;
            data.forEach((entry) => {
              let x = entry.date;
              if (entry.source != null) {
                let yEngagement = entry.source.engagement[social];
                engagementDataList.push([x, yEngagement]);
                let yCount = entry.source.count[social];
                countDataList.push([x, yCount]);
                let yView = entry.source.view[social];
                viewDataList.push([x, yView]);
              }
            });
            const pl = {
              title: name,
              criteria: {},
            };
            pl.criteria[name] = [name];
            newEngagementSeries.push({
              name: name,
              color: helper.getColorChannel(social),
              data: engagementDataList,
              point: {
                events: {
                  click: (e) => drilldownFn(e.point.options, pl),
                },
              },
              custom: {
                granularity,
              },
            });
            newCountSeries.push({
              name: name,
              color: helper.getColorChannel(social),
              data: countDataList,
              point: {
                events: {
                  click: (e) => drilldownFn(e.point.options, pl),
                },
              },
              custom: {
                granularity,
              },
            });
            newViewSeries.push({
              name: name,
              color: helper.getColorChannel(social),
              data: viewDataList,
              point: {
                events: {
                  click: (e) => drilldownFn(e.point.options, pl),
                },
              },
              custom: {
                granularity,
              },
            });
          }
          seriesData.engagement = newEngagementSeries;
          seriesData.mention = newCountSeries;
          seriesData.view = newViewSeries;

          selectedSerie.value = seriesData[mode.value];

          // check no data
          checkSelectedSerieData();
        } else {
          let arr = [];
          seriesData.engagement = arr;
          seriesData.mention = arr;
          seriesData.view = arr;
          selectedSerie.value = seriesData[mode.value];

          noData.value = true;
        }
      }
      loading.value = false;
    };

    const checkSelectedSerieData = () => {
      let checkHaveZeroValueAtOne = true;
      for (const category of selectedSerie.value) {
        for (const dataHaveTime of category.data) {
          if (dataHaveTime[1] !== 0 && dataHaveTime[1] !== undefined) {
            checkHaveZeroValueAtOne = false;
            break;
          }
        }

        if (!checkHaveZeroValueAtOne) {
          break;
        }
      }

      noData.value = checkHaveZeroValueAtOne
    };

    const selectedCategoryLevelStyle = {
      'background-color': '#f2f7fe',
      color: '#337df6',
    };

    const unselectedCategoryLevelStyle = {
      color: '#868EAE',
    };

    const handleActiveChange = (value) => {
      if (value === 'line') {
        lineActive.value = true;
        barActive.value = false;
      } else {
        barActive.value = true;
        lineActive.value = false;
      }
      chartType.value = value;
    };

    watch(
      () => mode.value,
      () => {
        selectedSerie.value = seriesData[mode.value];
        checkSelectedSerieData();
      }
    );

    watchEffect(() => {
      init(filterResult.value);
    });

    return {
      chartType,
      selectedSerie,
      loading,
      noData,
      isMobileScreen,
      selectedCategoryLevelStyle,
      unselectedCategoryLevelStyle,
      selectedModeStyle,
      unSelectedModeStyle,
      seriesData,
      handleActiveChange,
    };
  },
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';
.campaign-performance-trending {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }
  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;
    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .trending-representation-selector-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
  }
  .chart-type {
    width: fit-content;
    margin-left: auto;
    padding: 0;
    line-height: 1.4;
  }
  @media screen and (max-width: 560px) {
    .trending-representation-selector-wrapper {
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .trending-accumulation-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 16px;
    column-gap: 16px;
    .button-primary-light {
      /* TODO Refactor to outline btn */
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;

      &:focus {
        background: #2c99ff15;
        border-width: 0px;
        color: $primary-color;
      }
    }
    .trending-mode-toggle {
      .ant-dropdown-trigger {
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
    display: block;
  }
  @media screen and (max-width: 560px) {
    .ant-card-head-wrapper .ant-card-extra {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      column-gap: 12px;
    }
  }
  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 200px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0;
    }
  }

  .chart-type {
    width: fit-content;
    margin-left: auto;
    padding: 15px 0;
    line-height: 1.4;
  }

  @media screen and (max-width: 560px) {
    .chart-type {
      margin-left: 0;
      justify-content: flex-end;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }
}

@media only screen and (max-width: 768px) {
  .btn-line {
    margin-right: 5px;
  }
}
</style>
