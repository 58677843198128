<template>
  <OverallVue :mode="mode" :filterResult="filterResult"></OverallVue>
</template>

<script>
import OverallVue from '../../../components/Contents/Overall.vue';

export default {
  name: 'ChannelOverall',
  components: {
    OverallVue,
  },
  props: {
    mode: String,
    filterResult: Object,
  },
};
</script>
