import api from '@/services/api';
import helper from '@/services/helper';

const getOriginMessage = (socialNetworkDataList) => {
  return socialNetworkDataList.find((m) => {
    return !!m.info.is_origin;
  });
};

const getLastComment = (socialNetworkDataList) => {
  // backward loop
  let lastComment;
  let index = socialNetworkDataList.length - 1;
  for (; index >= 0; index--) {
    if (socialNetworkDataList[index].lineage.level === 1) {
      lastComment = socialNetworkDataList[index];
      break;
    }
  }
  return lastComment;
};

const prepareLineageControl = (
  message,
  prev,
  next,
  messageIDMap,
  socialNetworkDataList
) => {
  const lineageControl = {
    hasNext: false,
    hasFinalNext: false,
    hasPrev: false,
    hasReplies: false,
    nextCount: 0,
    finalNextCount: 0,
  };
  if (message.lineage.level > 0) {
    // Start -- Check prev
    if (message.lineage.index > 0) {
      if (prev && message.lineage.level > prev.lineage.level) {
        lineageControl.hasPrev = true;
      }
    }
    // End -- Check Prev
    // Start -- Check next + reply
    if (next) {
      if (message.lineage.level === 2 && next.lineage.level === 1) {
        // End of current reply -- check for parent child count for sure
        const parent = messageIDMap[message.info.parent_id];
        if (parent) {
          const lastChildIndex = parent.lineage.child_count - 1;
          if (message.lineage.index !== lastChildIndex) {
            lineageControl.nextCount = lastChildIndex - message.lineage.index;
            if (lineageControl.nextCount > 0) {
              lineageControl.hasNext = true;
            }
          }
        }
      }
      if (
        next.lineage.level === message.lineage.level &&
        message.lineage.child_count > 0
      ) {
        lineageControl.hasReplies = true;
      }
    } else {
      // Last item of the list but need check parent child count for sure
      // Its parent still had children
      const parent = messageIDMap[message.info.parent_id];
      if (parent) {
        const lastChildIndex = parent.lineage.child_count - 1;
        // If last message is comment or subcomment should apply this
        if (message.lineage.index !== lastChildIndex) {
          lineageControl.nextCount = lastChildIndex - message.lineage.index;
          if (lineageControl.nextCount > 0) {
            lineageControl.hasNext = true;
          }
        }
        // If last message is subcomment need check origin next child
        if (message.lineage.level === 2) {
          // Get last level 1 (comment) and check with origin
          const originMessage = getOriginMessage(socialNetworkDataList);
          const originChildLastIndex = originMessage.lineage.child_count - 1;
          const lastComment = getLastComment(socialNetworkDataList);
          if (originChildLastIndex !== lastComment.lineage.index) {
            lineageControl.lastCommentId = lastComment.info.id;
            lineageControl.finalNextCount =
              originChildLastIndex - lastComment.lineage.index;
            if (lineageControl.finalNextCount > 0) {
              lineageControl.hasFinalNext = true;
            }
          }
        }
      }
      // Last item is comment and also has replies
      if (message.lineage.child_count > 0) {
        lineageControl.hasReplies = true;
      }
    }
    // End -- Check next
  }
  return lineageControl;
};

const prepareLineageList = (socialNetworkDataList, updateOnly, targetId) => {
  const rawMessage = [];
  const messageIDMap = {};
  socialNetworkDataList.forEach((m) => {
    messageIDMap[m.info.id] = m;
  });
  let message, prev, next;
  for (let i = 0; i < socialNetworkDataList.length; i += 1) {
    message = socialNetworkDataList[i];
    if (targetId && message.info.id === targetId) {
      message.isTarget = true;
    }
    if (updateOnly && message.lineageControl) {
      rawMessage.push(message);
      continue;
    }
    // TODO refactor this if possible
    if (i > 0) {
      prev = socialNetworkDataList[i - 1];
    } else {
      prev = null;
    }
    if (i === socialNetworkDataList.length - 1) {
      next = null;
    } else {
      next = socialNetworkDataList[i + 1];
    }
    const lineageControl = prepareLineageControl(
      message,
      prev,
      next,
      messageIDMap,
      socialNetworkDataList
    );
    rawMessage.push({
      ...message,
      lineageControl,
    });
  }
  return rawMessage;
};

export default {
  namespaced: true,
  state: {
    open: false,
    loading: false,
    isLineage: false,
    messageList: [],
    resultData: {},
    queue: [],
  },
  getters: {
    messageList: (state) => state.messageList,
    messageIDMap: ({ messageList }) => {
      const result = {};
      if (messageList) {
        messageList.forEach((msg) => {
          result[msg.info.id] = msg;
        });
      }
      return result;
    },
    messageIDList: ({ messageList }) => {
      const result = {};
      if (messageList) {
        return messageList.map((msg) => msg.info.id);
      }
      return result;
    },
    originMessage: ({ messageList }) => {
      if (messageList) {
        return getOriginMessage(messageList);
      }
      return {};
    },
    originId: ({ messageList }) => {
      if (messageList && messageList[0]) {
        return messageList[0].info.origin_id;
      }
      return {};
    },
    subCategoryName: ({ resultData }) => {
      if (resultData && resultData.subCategoryName) {
        return resultData.subCategoryName;
      }
      return {};
    },
    currentCriteria: ({ resultData }) => {
      if (resultData && resultData.criteria) {
        return resultData.criteria;
      }
      return {};
    },
    currentPage: ({ resultData }) => {
      if (resultData && resultData.currentPage) {
        return resultData.currentPage;
      }
      return 0;
    },
    totalPage: ({ resultData }) => {
      if (resultData && resultData.totalPage) {
        return resultData.totalPage;
      }
      return 0;
    },
    totalRecord: ({ resultData }) => {
      if (resultData && resultData.totalRecord) {
        return resultData.totalRecord;
      }
      return 0;
    },
  },
  actions: {
    async show({ commit, dispatch, rootGetters, state }, message) {
      if (!message || !message.info) return;
      const { source, origin_id, id } = message.info;
      state.queue.push(id);
      // check is the message is lineage available
      let isLineage = false;
      // console.log('SKIP', source, origin_id);

      if (source !== 'twitter') {
        const lineageResult = await api
          .isLineageAvailable(origin_id)
          .catch(() => {});
        if (lineageResult && lineageResult.message) {
          isLineage = lineageResult.message;
        }
      }

      commit('setDialogState', {
        loading: true,
        open: true,
        isLineage,
        resultData: {},
      });

      if (state.queue[state.queue.length - 1] !== id) return;

      let result;
      if (isLineage) {
        result = await api.getSeemoreLineage(id).catch(() => {});
      } else {
        result = await api
          .getSeemoreData(origin_id, {
            page: 1,
            recordPerPage: 25,
          })
          .catch(() => {});
      }
      if (result && result.message && result.message.socialNetworkDataList) {
        let rawMessage = [];
        const { subCategoryName, socialNetworkDataList, totalMentioned } =
          result.message;
        if (isLineage) {
          rawMessage = prepareLineageList(socialNetworkDataList, false, id);
        } else {
          rawMessage = socialNetworkDataList;
        }
        let opt = {
          messageList: rawMessage,
          truncateAt: 0,
        };
        const searchInput = rootGetters['filter/searchInput'];
        if (searchInput && searchInput.indexOf('(') === -1) {
          // NOTE: this is not support highlight for advance syntax
          // TODO implement support highlight advance syntax
          opt.searchKeyword = searchInput;
        }
        const highlighted = await dispatch('config/highlightKeywords', opt, {
          root: true,
        });
        commit('setDialogState', {
          resultData: result.message,
          messageList: highlighted,
          totalMentioned: totalMentioned,
          subCategoryName,
          loading: false,
        });
      }
      state.queue = [];
    },
    async getRepliesLineage({ dispatch }, message) {
      const { id } = message.info;
      const result = await api.getLineageReplies(id).catch(() => {});
      if (result && result.message) {
        const dataList = result.message.socialNetworkDataList;
        dispatch('updateDataList', {
          message,
          dataList,
          mode: 'append',
        });
      }
    },
    async getNextLineage({ dispatch }, message) {
      const { id } = message.info;
      const result = await api.getNextLineage(id).catch(() => {});
      if (result && result.message) {
        const dataList = result.message.socialNetworkDataList;
        dispatch('updateDataList', {
          message,
          dataList,
          mode: 'append',
        });
      }
    },
    async getFinalNextLineage({ getters, dispatch }, id) {
      const { messageIDMap } = getters;
      const message = messageIDMap[id];
      if (message) {
        console.log('UMM', message, id);
        const result = await api.getNextLineage(id).catch(() => {});
        if (result && result.message) {
          const dataList = result.message.socialNetworkDataList;
          dispatch('updateDataList', {
            message,
            dataList,
            mode: 'appendFinal',
          });
        }
      }
    },
    async getPreviousLineage({ dispatch }, message) {
      const { id } = message.info;
      const result = await api.getPreviousLineage(id).catch(() => {});
      if (result && result.message) {
        const dataList = result.message.socialNetworkDataList;
        dispatch('updateDataList', {
          message,
          dataList,
          mode: 'prepend',
        });
      }
    },
    async updateDataList(
      { commit, dispatch, getters },
      { message, dataList, mode }
    ) {
      console.log(message, dataList);
      const newDataList = await dispatch(
        'config/highlightKeywords',
        {
          messageList: dataList,
          truncateAt: 0,
        },
        { root: true }
      );
      const { messageIDList, messageList } = getters;
      let currentList = JSON.parse(JSON.stringify(messageList));
      const currentIndex = messageIDList.indexOf(message.info.id);
      // Remove current message lineage control for recheck
      currentList[currentIndex].lineageControl = null;
      if (mode === 'appendFinal') {
        currentList = prepareLineageList([...currentList, ...newDataList]);
      } else if (mode === 'append') {
        let count = 0;
        // TODO refactor this - reduce loop
        for (let i in newDataList) {
          const msg = newDataList[i];
          if (!messageIDList[msg.info.id]) {
            count += 1;
            currentList.splice(currentIndex + count, 0, msg);
          }
        }
        currentList = prepareLineageList(currentList, true);
      } else if (mode === 'prepend') {
        let count = 0;
        // TODO refactor this - reduce loop
        for (let i in newDataList) {
          const msg = newDataList[i];
          if (!messageIDList[msg.info.id]) {
            count += 1;
            currentList.splice(currentIndex - 1 + count, 0, msg);
          }
        }
        currentList = prepareLineageList(currentList, true);
      }
      commit('setDialogState', { messageList: currentList });
    },
    async changePage({ commit, getters, dispatch }, data) {
      const { pageNumber, pageSize } = data;
      commit('setDialogState', {
        loading: true,
      });
      const id = getters.originId;
      const paging = {
        page: pageNumber,
        recordPerPage: pageSize,
      };
      const result = await api.getSeemoreData(id, paging).catch(() => {});
      if (result && result.message && result.message.socialNetworkDataList) {
        const { subCategoryName, socialNetworkDataList } = result.message;
        let rawMessage = socialNetworkDataList;
        const highlighted = await dispatch(
          'config/highlightKeywords',
          {
            messageList: rawMessage,
            truncateAt: 0,
          },
          { root: true }
        );
        commit('setDialogState', {
          resultData: result.message,
          messageList: highlighted,
          subCategoryName,
          loading: false,
        });
      }
    },
    editMessage({ getters, commit }, e) {
      const newMsgList = JSON.parse(JSON.stringify(getters.messageList));
      helper.saveLocalMessage(e, newMsgList);
      commit('setMessageList', newMsgList);
    },
    close({ commit }) {
      commit('setDialogState', {
        open: false,
        loading: false,
        currentDataPage: 1,
        isLineage: false,
        messageList: [],
        subCategoryName: {},
      });
    },
  },
  mutations: {
    setDialogState(state, data) {
      const keys = Object.keys(data);
      for (let key of keys) {
        state[key] = data[key];
      }
    },
    setMessageList(state, data) {
      // Object.assign(state.resultData.data, data);
      state.messageList = data;
    },
  },
};
