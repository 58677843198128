<template>
  <a-card :title="title">
    <template #extra>
      <div v-if="!isMobileScreen" class="category-top-right-action d-flex">
        <slot name="category" v-bind:noData="noData"></slot>
        <a-button

          :type="tabActive === 'pie' && !noData ? 'primary' : 'ghost'"
          class="circular"
          :class="{ 'button-primary-light': tabActive === 'pie' && !noData }"
          style="width: 42px"
          @click="handleActiveChange('pie')"
          :disabled="noData"
          v-show="!noData"
        >
          <FeatherIcon type="pie-chart" size="18" />
        </a-button>
        <a-button
          :type="tabActive === 'progressbar' && !noData ? 'primary' : 'ghost'"
          class="circular"
          style="width: 42px"
          :class="{
            'button-primary-light': tabActive === 'progressbar' && !noData,
          }"
          @click="handleActiveChange('progressbar')"
          :disabled="noData"
          v-show="!noData"
        >
          <FeatherIcon type="list" size="18" />
        </a-button>
      </div>
      <div v-else class="chart-type">
        <a-dropdown
          placement="bottomRight"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <FeatherIcon class="more-menu" type="more-horizontal" size="16" />
          <template #overlay>
            <a-menu id="mode-menu-mobile">
              <a-menu-item-group title="VIEW:">
                <a-menu-item :class="{ 'category-view': type === 'category' }">
                  <a-button
                    :type="tabActive === 'pie' && !noData ? 'primary' : 'ghost'"
                    class="circular"
                    :class="{
                      'button-primary-light': tabActive === 'pie' && !noData,
                    }"
                    style="width: 42px"
                    @click="handleActiveChange('pie')"
                    :disabled="noData"
                  >
                    <FeatherIcon type="pie-chart" size="18" />
                  </a-button>
                  <a-button
                    :type="tabActive === 'progressbar' && !noData ? 'primary' : 'ghost'"
                    class="circular"
                    style="width: 42px"
                    :class="{
                      'button-primary-light':
                        tabActive === 'progressbar' && !noData,
                    }"
                    @click="handleActiveChange('progressbar')"
                    :disabled="noData"
                  >
                    <FeatherIcon type="list" size="18" /> </a-button
                ></a-menu-item>
              </a-menu-item-group>
              <a-menu-item-group
                v-if="type === 'category'"
                title="SELECT CATEGORY:"
              >
                <a-menu-item
                  v-for="cat in categoryData"
                  :key="cat.level"
                  :style="
                    selectedCategoryLevel === cat.level
                      ? selectedCategoryLevelStyle
                      : unselectedCategoryLevelStyle
                  "
                  class="accumulation-sub-menu"
                  @click="handleMenuClick(cat.level)"
                >
                  <span class="accumulation-sub-menu-item">{{ cat.name }}</span>
                </a-menu-item>
              </a-menu-item-group>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </template>

    <div v-show="pieActive" class="piechart-content">
      <a-skeleton v-if="loading" :loading="loading" active />

      <NoResult
        v-else-if="
          (!loading && noResult) ||
          (!loading && selectedData && selectedData.length === 0)
        "
        class="piechart-no-data"
      />

      <EChart
        v-else
        :type="'pie'"
        :data="selectedData"
        :level="qs.level"
        :doughnut-chart="false"
        :chart-type="type"
        :category-by-level="categoryByLevel"
        @chartClick="onChartClick"
      />
    </div>
    <div v-show="progressbarActive">
      <div v-if="loading" class="progress-bar-loading">
        <a-skeleton :loading="loading" active />
      </div>
      <div v-if="noData" class="load-wrapper">
        <NoDataFound></NoDataFound>
      </div>
      <div v-else class="progress-bar-category">
        <ProgressBarList
          :data="selectedData"
          :chart-type="type"
          :mode="mode"
          @progressClick="onChartClick"
        />
      </div>
    </div>
  </a-card>
</template>

<script>
import { ref, reactive, computed, toRefs, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import api from '@/services/api';
import numeral from 'numeral';
import EChart from '@/components/Chart/Echart.vue';
import ProgressBar from '@/components/Chart/ProgressBar.vue';
import helper from '@/services/helper';
// import chartHelper from '@/services/chartHelper.js';
import NoResult from '../../../components/Error/NoResult.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';

const ChannelAndCategory = {
  name: 'ChannelAndCategory',

  props: {
    type: String,
    mode: String,
    filter: Object,
    categoryByLevel: Object,
    categoryData: Array,
  },

  components: {
    EChart,
    ProgressBarList: ProgressBar,
    NoResult,
    NoDataFound,
  },

  setup(props, { emit }) {
    let { mode, categoryByLevel, filter, type } = toRefs(props);
    const { state, dispatch } = useStore();
    const categoryData = computed(() => state.config.categoryData);
    const isMobileScreen = computed(() => state.isMobileScreen);
    const sList = api.getSocialSource();
    const socialList = reactive(sList);

    const tabActive = ref('');
    let loading = ref(true);
    let pieActive = ref(true);
    let progressbarActive = ref(false);

    let engagementData = ref([]);
    let countData = ref([]);

    const qs = reactive({
      'chart-type': 'pie',
      level: 0,
    });

    const selectedData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return engagementData.value;
        } else if (mode.value.toLowerCase() === 'count') {
          return countData.value;
        } else {
          return engagementData.value;
        }
      } else {
        return engagementData.value;
      }
    });

    const noResult = computed(() => {
      // channel pie chart is no data
      if (selectedData.value) {
        return selectedData.value.every((ele) => ele.value === 0);
      }
      return true;
    });

    const title = computed(() => {
      if (type.value === 'channel') {
        return 'Channel';
      } else if (type.value === 'category') {
        return 'Category';
      }
      return '';
    });

    const visibleCategories = computed(() => {
      if (
        categoryByLevel.value &&
        categoryData.value &&
        categoryData.value[categoryByLevel.value.level] &&
        categoryData.value[categoryByLevel.value.level].categories
      ) {
        return categoryData.value[categoryByLevel.value.level].categories
          .filter((cat) => cat.visible)
          .map((cat) => cat.category);
      } else {
        return [];
      }
    });

    const noData = computed(() => {
      return (
        (!loading.value && noResult.value) ||
        (!loading.value &&
          selectedData.value &&
          selectedData.value.length === 0)
      );
    });

    const getChannel = async (criteria, qs) => {
      // console.log('CNE', criteria, qs);
      let result = await api.getSourcePie(criteria, qs).catch(() => {});
      let engagementDataObjResponse = null;
      let countDataObjResponse = null;

      if (
        result &&
        result.message != null &&
        result.message.graphData != null &&
        result.message.graphData.engagement != null &&
        result.message.graphData.count != null
      ) {
        engagementDataObjResponse = result.message.graphData.engagement;
        countDataObjResponse = result.message.graphData.count;
      }

      let engagementResult = [];
      if (engagementDataObjResponse !== null) {
        let total = 0;
        let websiteTotal = 0;
        // let hasWebsite = false;
        // for (const [key, value] of Object.entries(engagementDataObjResponse)) {
        //   if (!socialList.includes(key.toLowerCase())) {
        //     hasWebsite = true;
        //     websiteTotal = websiteTotal + value;
        //   }
        //   total = total + value;
        // }

        // separate social and website
        // for (const [index, [key, value]] of Object.entries(Object.entries(engagementDataObjResponse))) {
        //   // push Social only
        //   if (socialList.includes(key)) {
        //     const color = chartHelper.getColor(key.toLowerCase(), parseInt(index));
        //     let valueAsPercent = convertValueToPercent(value, total);
        //     engagementResult.push({
        //       name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
        //       value: value,
        //       valueAsPercent: valueAsPercent,
        //       itemStyle: {
        //         color: color,
        //       },
        //     });
        //   }
        // }
        if (socialList && socialList.length > 0) {
          // for website
          for (const [key, value] of Object.entries(
            engagementDataObjResponse
          )) {
            if (!socialList.includes(key.toLowerCase())) {
              websiteTotal = websiteTotal + value;
            }
            total = total + value;
          }

          // for social
          for (const [idx, social] of socialList.entries()) {
            engagementResult.push({
              name: social.charAt(0).toUpperCase() + social.slice(1),
              value: engagementDataObjResponse[social]
                ? engagementDataObjResponse[social]
                : 0,
              valueAsPercent: convertValueToPercent(
                engagementDataObjResponse[social]
                  ? engagementDataObjResponse[social]
                  : 0,
                total
              ),
              itemStyle: {
                color: getColor(social, parseInt(idx)),
                // color: chartHelper.getColor(social, parseInt(idx)),
              },
            });
          }
        }

        // Sort by Social list
        engagementResult.sort(
          (a, b) =>
            socialList.indexOf(a.name.toLowerCase()) -
            socialList.indexOf(b.name.toLowerCase())
        );
        // engagementResult.sort(
        //   (a, b) => socialList.indexOf(a.name.toLowerCase()) - socialList.indexOf(b.name.toLowerCase())
        // );
        // push Website
        engagementResult.push({
          name: 'Website',
          value: websiteTotal,
          itemStyle: {
            color: getColor('website'),
          },
          valueAsPercent: convertValueToPercent(websiteTotal, total),
        });

        engagementData.value = engagementResult;
      }

      let countResult = [];
      if (countDataObjResponse !== null) {
        let total = 0;
        let websiteTotal = 0;
        // for (const [key, value] of Object.entries(countDataObjResponse)) {
        //   if (!socialList.includes(key.toLowerCase())) {
        //     websiteTotal = websiteTotal + value;
        //   }
        //   total = total + value;
        // }

        // separate social and website
        // for (const [index, [key, value]] of Object.entries(Object.entries(countDataObjResponse))) {
        //   // push Social only
        //   if (socialList.includes(key)) {
        //     const color = chartHelper.getColor(key.toLowerCase(), parseInt(index));
        //     let valueAsPercent = convertValueToPercent(value, total);
        //     countResult.push({
        //       name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
        //       value: value,
        //       valueAsPercent: valueAsPercent,
        //       itemStyle: {
        //         color: color,
        //       },
        //     });
        //   }
        // }

        if (socialList && socialList.length > 0) {
          // for website
          for (const [key, value] of Object.entries(countDataObjResponse)) {
            if (!socialList.includes(key.toLowerCase())) {
              websiteTotal = websiteTotal + value;
            }
            total = total + value;
          }

          // for social
          for (const [idx, social] of socialList.entries()) {
            countResult.push({
              name: social.charAt(0).toUpperCase() + social.slice(1),
              value: countDataObjResponse[social]
                ? countDataObjResponse[social]
                : 0,
              valueAsPercent: convertValueToPercent(
                countDataObjResponse[social] ? countDataObjResponse[social] : 0,
                total
              ),
              itemStyle: {
                color: getColor(social, parseInt(idx)),
                // color: chartHelper.getColor(social, parseInt(idx)),
              },
            });
          }
        }

        // Sort by Social list
        countResult.sort(
          (a, b) =>
            socialList.indexOf(a.name.toLowerCase()) -
            socialList.indexOf(b.name.toLowerCase())
        );
        // push Website
        countResult.push({
          name: 'Website',
          value: websiteTotal,
          itemStyle: {
            color: getColor('website'),
          },
          valueAsPercent: convertValueToPercent(websiteTotal, total),
        });

        countData.value = countResult;
      }
    };

    const getCategory = async (criteria, qs) => {
      // console.log(criteria, qs);
      // const visibleCategories = categoryData.value[categoryByLevel.value].categories
      //   .filter((cat) => cat.visible)
      //   .map((cat) => cat.category);
      //   let result = await api.getSourcePie(criteria, qs).catch(() => {});
      let result = await api.getCategoryPie(criteria, qs).catch(() => {});
      let engagementDataObjResponse = null;
      let countDataObjResponse = null;

      if (
        result &&
        result.message != null &&
        result.message.graphData != null &&
        result.message.graphData.engagement != null &&
        result.message.graphData.count != null
      ) {
        engagementDataObjResponse = result.message.graphData.engagement;
        countDataObjResponse = result.message.graphData.count;
      }

      // Engagement mode
      let engagementResult = [];
      if (engagementDataObjResponse !== null) {
        // check visible data
        let visibleEngagementDataTotal = 0;
        let visibleEngagementData = [];
        for (const [key, value] of Object.entries(engagementDataObjResponse)) {
          if (visibleCategories.value.includes(key)) {
            visibleEngagementDataTotal = visibleEngagementDataTotal + value;
            visibleEngagementData.push({
              name: key,
              value: value,
            });
          }
        }

        if (visibleEngagementData && visibleEngagementData.length) {
          for (let [index, data] of visibleEngagementData.entries()) {
            const color = getColor(data.name.toLowerCase(), parseInt(index));
            // const color = chartHelper.getColor(data.name.toLowerCase(), parseInt(index));
            // check data >= 2%
            // if (data.value && (data.value * 100) / visibleEngagementDataTotal >= 2) {
            let valueAsPercent = convertValueToPercent(
              data.value,
              visibleEngagementDataTotal
            );
            engagementResult.push({
              name: data.name
                ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                : data.name,
              value: data.value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
            // }
          }
        }

        // for (const [index, [key, value]] of Object.entries(Object.entries(engagementDataObjResponse))) {
        //   const color = chartHelper.getColor(key.toLowerCase(), parseInt(index));
        //   let valueAsPercent = convertValueToPercent(value, total);
        //   if (visibleCategories.includes(key) && valueAsPercent >= minimumPercentage.value) {
        //     engagementResult.push({
        //       name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
        //       value: value,
        //       valueAsPercent: valueAsPercent,
        //       itemStyle: {
        //         color: color,
        //       },
        //     });
        //   }
        // }
      }

      if (engagementResult && engagementResult.length) {
        engagementData.value = sortOther(
          engagementResult.sort((a, b) =>
            a.value > b.value ? -1 : b.value > a.value ? 1 : 0
          )
        );
      } else {
        engagementData.value = sortOther(engagementResult);
      }

      // Count (Mentioned) mode
      let countResult = [];
      if (countDataObjResponse !== null) {
        // check visible data
        let visibleCountDataTotal = 0;
        let visibleCountData = [];
        for (const [key, value] of Object.entries(countDataObjResponse)) {
          if (visibleCategories.value.includes(key)) {
            visibleCountDataTotal = visibleCountDataTotal + value;
            visibleCountData.push({
              name: key,
              value: value,
            });
          }
        }

        if (visibleCountData && visibleCountData.length) {
          for (let [index, data] of visibleCountData.entries()) {
            const color = getColor(data.name.toLowerCase(), parseInt(index));
            // const color = chartHelper.getColor(data.name.toLowerCase(), parseInt(index));
            // check data >= 2%
            // if (data.value && (data.value * 100) / visibleCountDataTotal >= 2) {
            let valueAsPercent = convertValueToPercent(
              data.value,
              visibleCountDataTotal
            );
            countResult.push({
              name: data.name
                ? data.name.charAt(0).toUpperCase() + data.name.slice(1)
                : data.name,
              value: data.value,
              valueAsPercent: valueAsPercent,
              itemStyle: {
                color: color,
              },
            });
            // }
          }
        }

        if (countResult && countResult.length) {
          countData.value = sortOther(
            countResult.sort((a, b) =>
              a.value > b.value ? -1 : b.value > a.value ? 1 : 0
            )
          );
        } else {
          countData.value = sortOther(countResult);
        }

        // let total = 0;
        // for (const value of Object.values(countDataObjResponse)) {
        //   total = total + value;
        // }

        // for (const [index, [key, value]] of Object.entries(Object.entries(countDataObjResponse))) {
        //   const color = chartHelper.getColor(key.toLowerCase(), parseInt(index));
        //   let valueAsPercent = convertValueToPercent(value, total);
        //   if (valueAsPercent >= minimumPercentage.value) {
        //     countResult.push({
        //       name: key ? key.charAt(0).toUpperCase() + key.slice(1) : key,
        //       value: value,
        //       valueAsPercent: valueAsPercent,
        //       itemStyle: {
        //         color: color,
        //       },
        //     });
        //   }
        // }
        // countData.value = countResult.sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
      }
    };

    const sortOther = (lists) => {
      return lists.sort((a, b) => {
        if (a.name === 'Others') {
          return 1;
        } else if (b.name === 'Others') {
          return -1;
        } else {
          return 0;
        }
      });
    };

    const getColor = (catKey, index) => {
      let color;
      let result;
      if (
        categoryData.value &&
        categoryData.value[qs.level] &&
        categoryData.value[qs.level].categories
      ) {
        const categories = categoryData.value[qs.level].categories;
        result = categories.find(
          (c) => c.visible && c.category === catKey.toLowerCase()
        );
      }
      color = result && result.color ? result.color : null;

      if (!color) {
        color = helper.getColor(catKey, index);
      }
      return color;
    };

    const convertValueToPercent = (value, total) => {
      return numeral((value / total) * 100).format('0.00');
    };
    const handleActiveChange = (value) => {
      if (value === 'pie') {
        pieActive.value = true;
        progressbarActive.value = false;
      } else {
        progressbarActive.value = true;
        pieActive.value = false;
      }
      tabActive.value = value;
    };

    // Modal
    const onChartClick = (params) => {
      const newFilter = JSON.parse(JSON.stringify(filter.value));
      let f = {
        payload: {
          title: '',
        },
        criteria: {
          ...newFilter,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (type.value.toLowerCase() === 'category') {
        // level > 0 --> sub-category
        const category = params.name.toLowerCase();
        f.payload.title = categoryByLevel.value.name
          ? `${categoryByLevel.value.name}: ${params.name}`
          : `${type.value}: ${params.name}`;
        if (categoryByLevel.value.level > 0) {
          // f.criteria['category'] = [];
          if (filter.value.matchCategoryMode === 'all') {
            if (filter.value.subCategory.length > 0) {
              for (let i in f.criteria['subCategory']) {
                if (!f.criteria['subCategory'][i].category.includes(category)) {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                } else {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                  // f.criteria['subCategory'] = JSON.parse(JSON.stringify(filter.value.subCategory));
                }
              }
              f.criteria['subCategory'] = f.criteria['subCategory'].concat({
                level: categoryByLevel.value.level.toString(),
                category: [category],
              });
            } else {
              f.criteria['subCategory'] = [category];
            }
          } else {
            if (filter.value.subCategory.length > 0) {
              for (let i in f.criteria['subCategory']) {
                if (!f.criteria['subCategory'][i].category.includes(category)) {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                } else {
                  if (
                    f.criteria['subCategory'][i].level ==
                    categoryByLevel.value.level
                  ) {
                    f.criteria['subCategory'][i].category = [category];
                  }
                }
              }

              const levelList = f.criteria['subCategory'].map(
                (item) => item.level
              );
              if (!levelList.includes(categoryByLevel.value.level)) {
                f.criteria['subCategory'].push({
                  level: `${categoryByLevel.value.level}`,
                  category: [category],
                });
              }
            } else {
              f.criteria['subCategory'] = [
                {
                  level: categoryByLevel.value.level,
                  category: [category],
                },
              ];
            }
          }
        } else {
          if (filter.value.matchCategoryMode === 'all') {
            if (filter.value.category.length > 0) {
              f.criteria['category'] = JSON.parse(
                JSON.stringify(filter.value.category)
              );
            } else {
              f.criteria['category'] = [category];
            }
          } else {
            f.criteria['category'] = [category];
          }
        }
        // console.log('FIN', f.criteria);
      } else if (type.value.toLowerCase() === 'channel') {
        const source = params.name.toLowerCase();

        if (filter.value.source.length > 0) {
          if (
            JSON.parse(JSON.stringify(filter.value.source).includes(source))
          ) {
            f.criteria['source'] = [source];
          } else {
            f.criteria['source'] = JSON.parse(
              JSON.stringify(filter.value.source)
            );
          }
        } else {
          f.criteria['source'] = [source];
        }

        f.payload.title = `${params.name} channel`;
        f.criteria['title'] = params.name.toLowerCase();
        f.criteria['type'] = 'channel';
      } else if (type.value.toLowerCase() === 'sentiment') {
        const sentiment = params.name.toLowerCase();
        f.criteria['sentiment'] = [sentiment];
        f.payload.title = `${params.name} sentiment`;
      }
      dispatch('message/showMessageModal', f);
    };

    const handleMenuClick = (level) => {
      emit('handleMenuClick', level);
    };

    // INIT part
    const init = async () => {
      const filterValue = filter.value;
      loading.value = true;
      if (props.type === 'channel') {
        await getChannel(filterValue, qs);
        progressbarActive.value = true;
        pieActive.value = false;
        tabActive.value = 'progressbar';
      } else if (props.type === 'category') {
        await getCategory(filterValue, qs);
        pieActive.value = true;
        progressbarActive.value = false;
        tabActive.value = 'pie';
      }
      loading.value = false;
    };

    watch(
      () => filter.value,
      () => {
        init();
      }
    );

    watch(categoryByLevel, () => {
      // change level on category pie chart
      qs.level = categoryByLevel.value.level;
      init();
    });

    onMounted(() => {
      init();
    });

    return {
      title,
      tabActive,
      pieActive,
      progressbarActive,
      handleActiveChange,
      loading,
      selectedData,
      onChartClick,
      noResult,
      qs,
      isMobileScreen,
      handleMenuClick,
      noData,
    };
  },
};

export default ChannelAndCategory;
</script>

<style lang="scss" scoped>
@import '../../../config/theme/colors.json';

.piechart-content {
  height: 470px;
  display: flex;
  align-items: center;

  .piechart-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.progress-bar-loading {
  min-height: 470px;
  max-height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.progress-bar-category {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 470px;
  max-height: 470px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 6px;
  }
}

.category-top-right-action {
  align-items: center;

  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;

    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }
  // button {
  //   padding: 0;
  //   min-width: 40px;
  //   height: 40px;
  //   border-radius: 50%;
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   span {
  //     margin-right: 0;
  //     color: #fff;
  //   }
  // }
}
</style>
<style>
.category-view {
  border-bottom: 1px solid #f1f2f6;
  margin-bottom: 12px;
  padding-bottom: 14px !important;
}
</style>
