import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    fanpages: [],
    instagramAccounts: [],
    youtubeChannels: [],
  },
  getters: {
    fanpages: (state) => state.fanpages,
    instagramAccounts: (state) => state.instagramAccounts,
    youtubeChannels: (state) => state.youtubeChannels,
  },
  actions: {
    async getFanpages({ commit }) {
      let result = await api.getAccountFanpage();
      if (result.code === 200) {
        let data = result.message.map((v) => {
          return {
            ...v,
            _id: v.id,
            type: v.isConsiderKeyword ? 'Matched keyword' : 'All messages',
            type_value: v.isConsiderKeyword,
            name: v.fanpageName,
            id: v.id,
          };
        });
        commit('setFanpages', data);
      }
    },
    async getInstagramAccounts({ commit }) {
      let result = await api.getInstagramUserByAccountId();
      if (result.code === 200) {
        let data = result.message.map((v) => {
          return {
            ...v,
            _id: v.id,
            type: v.isConsiderKeyword ? 'Matched keyword' : 'All messages',
            type_value: v.isConsiderKeyword,
            sourceItemId: v.userId,
            name: v.fullName,
            id: v.userId,
          };
        });
        commit('setInstagramAccounts', data);
      }
    },
    async getYoutubeChannels({ commit }) {
      let result = await api.getYoutubeChannelByAccountId();
      if (result.code === 200) {
        let data = result.message.map((v) => {
          return {
            ...v,
            _id: v.id,
            type: v.isConsiderKeyword ? 'Matched keyword' : 'All messages',
            type_value: v.isConsiderKeyword,
            sourceItemId: v.channelId,
            name: v.channelName,
            id: v.channelId,
          };
        });
        commit('setYoutubeChannels', data);
      }
    },
  },
  mutations: {
    setFanpages(state, data) {
      state.fanpages = [...data];
    },
    setInstagramAccounts(state, data) {
      state.instagramAccounts = [...data];
    },
    setYoutubeChannels(state, data) {
      state.youtubeChannels = [...data];
    },
  },
};
