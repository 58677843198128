<template>
  <a-modal v-model:visible="isVisible" :title="mode" centered :footer="null" :width="400" @cancel="close">
    <template #closeIcon>
      <a-button class="ant-btn-sm circular" style="width: 36px; height: 36px; background-color: #f0f2f5; top: 2px">
        <FeatherIcon type="x" size="18" />
      </a-button>
    </template>
    <div v-if="mode === 'Engagement'" class="trending-data">
      <div>
        <div class="title">Total</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedTotal }}</div>
        </div>
      </div>
      <div class="margin-top">
        <div class="title">Reaction</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedReaction }}</div>
          <div
            class="percent-content"
            v-bind:class="[
              'percent-content',
              reactionPercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
            ]"
          >
            <font-awesome-icon :icon="reactionPercentChange >= 0 ? faArrowUp : faArrowDown" />
            {{ formattedReactionPercentChange }}
          </div>
        </div>
      </div>
      <div class="margin-top">
        <div class="title">Comments</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedComments }}</div>
          <div
            class="percent-content"
            v-bind:class="[
              'percent-content',
              commentsPercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
            ]"
          >
            <font-awesome-icon :icon="commentsPercentChange >= 0 ? faArrowUp : faArrowDown" />
            {{ formattedCommentsPercentChange }}
          </div>
        </div>
      </div>
      <div class="margin-top">
        <div class="title">Share</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedShare }}</div>
          <div
            class="percent-content"
            v-bind:class="[
              'percent-content',
              sharePercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
            ]"
          >
            <font-awesome-icon :icon="sharePercentChange >= 0 ? faArrowUp : faArrowDown" />
            {{ formattedSharePercentChange }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="mode === 'Mention'" class="trending-data">
      <div>
        <div class="title">Total</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedTotal }}</div>
        </div>
      </div>
      <div class="margin-top">
        <div class="title">Mentioned</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedMention }}</div>
          <div
            class="percent-content"
            v-bind:class="[
              'percent-content',
              mentionPercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
            ]"
          >
            <font-awesome-icon :icon="mentionPercentChange >= 0 ? faArrowUp : faArrowDown" />
            {{ formattedMentionPercentChange }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="mode === 'View'" class="trending-data">
      <div>
        <div class="title">Total</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedTotal }}</div>
          <div
            class="percent-content"
            v-bind:class="[
              'percent-content',
              viewPercentChange >= 0 ? 'percent-content-positive' : 'percent-content-negative',
            ]"
          >
            <font-awesome-icon :icon="viewPercentChange >= 0 ? faArrowUp : faArrowDown" />
            {{ formattedViewPercentChange }}
          </div>
        </div>
      </div>
      <div class="margin-top">
        <div class="title">Page View</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedPageView }}</div>
        </div>
      </div>
      <div class="margin-top">
        <div class="title">Video View</div>
        <div class="value-wrapper">
          <div class="value">{{ formattedVideoView }}</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import numeral from 'numeral';
import VueTypes from 'vue-types';
import { toRefs, computed, ref, watch } from 'vue';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
export default {
  name: 'TrendingModal',
  props: {
    mode: String,
    visible: Boolean,
    total: Number,
    mention: VueTypes.number.def(0),
    mentionPercentChange: VueTypes.number.def(0.0),
    view: VueTypes.number.def(0),
    pageView: VueTypes.number.def(0),
    videoView: VueTypes.number.def(0),
    viewPercentChange: VueTypes.number.def(0.0),
    reaction: VueTypes.number.def(0),
    reactionPercentChange: VueTypes.number.def(0.0),
    comments: VueTypes.number.def(0),
    commentsPercentChange: VueTypes.number.def(0.0),
    share: VueTypes.number.def(0),
    sharePercentChange: VueTypes.number.def(0.0),
  },
  setup(props, { emit }) {
    let {
      total,
      reaction,
      comments,
      share,
      mention,
      view,
      pageView,
      videoView,
      mentionPercentChange,
      viewPercentChange,
      reactionPercentChange,
      commentsPercentChange,
      sharePercentChange,
      visible,
    } = toRefs(props);
    let isVisible = ref(false);
    const formattedReactionPercentChange = computed(() => {
      return numeral(reactionPercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedCommentsPercentChange = computed(() => {
      return numeral(commentsPercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedSharePercentChange = computed(() => {
      return numeral(sharePercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedReaction = computed(() => {
      return numeral(reaction.value).format('0,0');
    });
    const formattedComments = computed(() => {
      return numeral(comments.value).format('0,0');
    });
    const formattedShare = computed(() => {
      return numeral(share.value).format('0,0');
    });
    const formattedTotal = computed(() => {
      return numeral(total.value).format('0,0');
    });
    const formattedMention = computed(() => {
      return numeral(mention.value).format('0,0');
    });
    const formattedMentionPercentChange = computed(() => {
      return numeral(mentionPercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const formattedView = computed(() => {
      return numeral(view.value).format('0,0');
    });
    const formattedPageView = computed(() => {
      return numeral(pageView.value).format('0,0');
    });
    const formattedVideoView = computed(() => {
      return numeral(videoView.value).format('0,0');
    });
    const formattedViewPercentChange = computed(() => {
      return numeral(viewPercentChange.value / 100)
        .format('0a %')
        .replace('-', '');
    });
    const close = () => {
      isVisible.value = false;
      emit('closeModal', isVisible.value);
    };
    watch(
      () => visible.value,
      () => {
        if (visible.value) {
          isVisible.value = visible.value;
        } else {
          isVisible.value = false;
        }
      }
    );
    return {
      faArrowUp,
      faArrowDown,
      formattedReactionPercentChange,
      formattedCommentsPercentChange,
      formattedSharePercentChange,
      formattedReaction,
      formattedComments,
      formattedShare,
      formattedTotal,
      formattedMention,
      formattedMentionPercentChange,
      formattedView,
      formattedPageView,
      formattedVideoView,
      formattedViewPercentChange,
      isVisible,
      close,
    };
  },
};
</script>

<style scoped>
.title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9299b8;
}

.value {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #272b41;
}

.margin-top {
  margin-top: 10px;
}

.wrapper {
  min-width: 143px;
}

.value-wrapper {
  display: flex;
}

.percent-content {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-left: 25px;
  margin-right: 3px;
}

.percent-content-positive {
  color: #5bbe94;
}

.percent-content-negative {
  color: #ff4d4f;
}
</style>
