import moment from 'moment';
import helper from '@/services/helper';

const defaultTempFilter = () => {
  return {
    rawCategory: {},
    rawSource: {},
    category: [],
    subCategory: [],
    source: [],
    sentiment: ['positive', 'neutral', 'negative'],
    matchCategoryMode: 'any',
    contentType: ['text', 'photo', 'video'],
    ownMedia: ['website', 'blog', 'facebookPage', 'twitterAccount', 'youtubeChannel'],
    messageType: ['origin', 'comment'],
    keywordPhrase: '',
    time: {
      sinceDate: moment().subtract(1, 'months').startOf('day').toISOString(),
      untilDate: moment().endOf('day').toISOString(),
    },
  };
};

export default {
  namespaced: true,
  state: {
    camapaign: {},
    filterCriteria: {},
    filterResult: {
      level: 0,
      categoryLists: [],
      valid: true,
      filterData: {},
    },
    mode: 'engagement',
  },
  getters: {
    campaign: (state) => state.campaign,
    filterCriteria: (state) => state.filterCriteria,
    filterResult: (state) => state.filterResult,
    mode: (state) => state.mode,
  },
  actions: {
    setDefaultFilter({ commit }) {
      commit('setDefaultFilter', defaultTempFilter());
    },
    async initFilter({ commit, rootGetters, state, dispatch }, forceInit) {
      if (forceInit) {
        await dispatch('config/getAccountCategory', null, { root: true });
        await dispatch('config/getOwnMedia', null, { root: true });

        const newTemp = defaultTempFilter();
        const srcTree = rootGetters['account/sourceTreeData'];
        newTemp.rawSource = srcTree.defaultState;
        // category
        const catTree = rootGetters['config/categoryTreeData'];
        const defaultList = rootGetters['config/defaultCategoryVisibleMap'];
        newTemp.rawCategory = catTree.defaultState;
        const catResult = helper.convertRawCategory(catTree.defaultState, defaultList);
        newTemp.category = catResult.category;
        const campaign = state.campaign;
        const campaignCat = [];
        for (let cat of catResult.subCategory) {
          if (parseInt(cat.level) === campaign.level) {
            const subCat = cat.category;
            const obj = {
              level: cat.level,
              category: [],
            };
            for (let sub of subCat) {
              if (sub === campaign.name) {
                obj.category.push(sub);
              }
            }
            campaignCat.push(obj);
          }
        }
        newTemp.subCategory = campaignCat;
        // own media
        const defaultOwnMedia = rootGetters['config/defaultOwnMediaData'];
        newTemp.ownMedia = [...defaultOwnMedia];
        commit('syncFilterCriteria', newTemp);
        commit('setFilterResult', newTemp);
      }
    },
    setCampaign({ commit, dispatch }, data) {
      commit('setCampaign', data);
      dispatch('initFilter', true);
    },
    clearCampaign({ commit }) {
      commit('resetCampaign');
    },
    setMode({ commit, dispatch }, data) {
      commit('setMode', data);
      dispatch('initFilter', true);
    },
    setSocialOverview({ commit }, data) {
      commit('clearSocialSource');
      commit('setSocialSource', data);
    },
    clearSocialOverview({ commit }) {
      commit('clearSocialSource');
    },
  },
  mutations: {
    setCampaign(state, data) {
      state.campaign = data;
    },
    resetCampaign(state) {
      state.campaign = '';
    },
    setDefaultFilter(state, data) {
      state.filterCriteria = data;
    },
    syncFilterCriteria(state, data) {
      const f = JSON.parse(JSON.stringify(data));
      // Remove raw...
      delete f.rawCategory;
      delete f.rawSource;
      state.filterCriteria = f;
    },
    setFilterResult(state, data) {
      state.filterResult.categoryLists = data.category;
      state.filterResult.filterData = state.filterCriteria;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setSocialSource(state, data) {
      state.filterCriteria.source.push(data);
    },
    clearSocialSource(state) {
      state.filterCriteria.source = [];
    },
  },
};
