<template>
  <div>
    <highcharts ref="heatmapChartRef" :options="option"></highcharts>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import VueTypes from 'vue-types';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap.js';
import { Chart } from 'highcharts-vue';
import { toRefs, computed, ref } from 'vue';
import helper from '@/services/helper';
// import _ from 'lodash';

HighchartsHeatmap(Highcharts);
const DayObj = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const Heatmap = {
  name: 'Heatmap',
  components: {
    highcharts: Chart,
  },
  props: {
    data: VueTypes.array,
    xAxisCategories: VueTypes.array,
    yAxisCategories: VueTypes.array,
    dataLabel: VueTypes.string,
    tooltipFormatterFunction: VueTypes.func,
    descriptionFormatterFunction: VueTypes.func,
    maxColor: VueTypes.string,
    type: VueTypes.string,
    source: VueTypes.string,
  },
  setup(props) {
    const store = useStore();
    const filterCriteria = computed(() => store.getters['filter/filterCriteria']);

    let heatmapChartRef = ref(null);
    let {
      xAxisCategories,
      yAxisCategories,
      data,
      dataLabel,
      tooltipFormatterFunction,
      descriptionFormatterFunction,
      maxColor,
      type,
      source,
    } = toRefs(props);

    const drilldownFn = (pointOption) => {
      // console.log('CLICK', pointOption);
      const { x, y } = pointOption;
      const day = DayObj[y];
      const filter = {
        payload: {
          title: '',
        },
        criteria: {
          time: {
            sinceDate: filterCriteria.value.time.sinceDate,
            untilDate: filterCriteria.value.time.untilDate,
            dayOfWeek: [day],
            hourOfDay: [x],
          },
          sort: {
            field: 'engagement_score',
            direction: 'desc',
          },
          highlight: {
            enable: true,
          },
        },
      };
      if (type.value === 'channel') {
        filter.payload.title = `${helper.capitalize(source.value)} on ${yAxisCategories.value[y]} at ${
          xAxisCategories.value[x]
        }`;
        filter.criteria['source'] = [source.value];
      } else {
        filter.payload.title = `Data on ${yAxisCategories.value[y]} at ${xAxisCategories.value[x]}`;
      }
      store.dispatch('message/showMessageModal', filter);
    };
    let option = computed(() => {
      return {
        lang: {
          thousandsSep: ',',
        },
        chart: {
          type: 'heatmap',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: xAxisCategories.value,
        },
        yAxis: {
          categories: yAxisCategories.value,
          title: null,
          reversed: true,
        },
        title: {
          text: null,
        },

        accessibility: {
          point: {
            descriptionFormatter: descriptionFormatterFunction.value,
          },
        },

        colorAxis: [
          {
            reversed: false,
            min: 0,
            minColor: '#F2F2F2',
            maxColor: maxColor.value ? maxColor.value : '#3371ff',
          },
        ],

        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 0,
          symbolHeight: 320,

          // I tried setting this to true and false but neither fix my problem
          // reversed: true
        },

        tooltip: {
          formatter: tooltipFormatterFunction.value,
          borderWidth: 3,
          borderRadius: 10,
        },

        series: [
          {
            name: dataLabel.value,
            borderWidth: 3,
            borderRadius: 10,
            borderColor: '#FFFFFF',
            data: data.value,
            states: {
              hover: {
                borderWidth: 3,
                borderRadius: 10,
                borderColor: '#FFFFFF',
              },
              normal: {
                borderWidth: 3,
                borderRadius: 10,
                borderColor: '#FFFFFF',
              },
              select: {
                borderWidth: 3,
                borderRadius: 10,
                borderColor: '#FFFFFF',
              },
              inactive: {
                borderWidth: 3,
                borderRadius: 10,
                borderColor: '#FFFFFF',
              },
            },
            point: {
              events: {
                click: (e) => drilldownFn(e.point.options),
              },
            },
          },
        ],

        // responsive: {
        //   rules: [
        //     {
        //       condition: {
        //         maxWidth: 500,
        //       },
        //       chartOptions: {
        //         yAxis: {
        //           labels: {
        //             formatter: function() {
        //               return this.value.charAt(0);
        //             },
        //           },
        //         },
        //       },
        //     },
        //   ],
        // },
      };
    });
    // const onResize = _.debounce(({ width, height }) => {
    //   heatmapChartRef.value.chart.setSize(width, height);
    // }, 100);
    return {
      // onResize,
      option,
      heatmapChartRef,
    };
  },
};

export default Heatmap;
</script>
