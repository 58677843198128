import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c94badc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "slider",
  class: "slider-container"
}
const _hoisted_2 = { class: "slider-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatherIcon = _resolveComponent("FeatherIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: "btn-angle-left",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onPrev && $setup.onPrev(...args)))
    }, [
      _createVNode(_component_FeatherIcon, {
        type: "chevron-left",
        size: "16"
      })
    ], 512), [
      [_vShow, $setup.isShowPrev]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "btn-angle-right",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onNext && $setup.onNext(...args)))
    }, [
      _createVNode(_component_FeatherIcon, {
        type: "chevron-right",
        size: "16"
      })
    ], 512), [
      [_vShow, $setup.isShowNext]
    ])
  ], 512))
}