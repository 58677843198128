<template>
  <div class="message-user-bar">
    <div class="left-block"></div>
    <div class="right-block">
      <span class="date-tag"> Last edited on {{ updateDateStr }} </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    updateDateStr: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.message-user-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #f1f2f6;
  overflow: auto;
  .right-block {
    display: flex;
    align-items: center;
    margin-left: 8px;
    column-gap: 8px;
    .date-tag {
      font-size: 13px;
      color: #9299b8;
      white-space: nowrap;
      margin-left: 4px;
    }
  }
}
</style>
