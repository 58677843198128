<template>
  <a-dropdown v-model:visible="visible" :trigger="['click']" :get-popup-container="(trigger) => trigger.parentNode">
    <a-button class="export-button" :class="{ mobile: mobile }" @click.stop="">
      <span v-if="mobile">
        <FeatherIcon type="download" color="#5A5F7D" size="14"></FeatherIcon>
      </span>
      <span v-else>
        <FeatherIcon type="download" color="#3371ff" size="12" />
        <span class="export-button-text"> Export </span>
      </span>
    </a-button>
    <template #overlay>
      <div class="sort-content-wrapper">
        <a-menu mode="inline">
          <a-menu-item
            v-for="type in fileTypeOptions"
            :key="type.version"
            :disabled="disabledExport"
            @click="toggleExport(type)"
          >
            <img v-if="type.type.toLowerCase() === 'pdf'" :src="require('@/assets/images/export/pdf-file.svg')" />
            <img v-if="type.type.toLowerCase() === 'csv'" :src="require('@/assets/images/export/csv-file.svg')" />
            <img v-if="type.type.toLowerCase() === 'json'" :src="require('@/assets/images/export/json-file.svg')" />
            <span class="type-name"> {{ type.name }} </span>
          </a-menu-item>
        </a-menu>
        <a-divider />
        <a-menu mode="inline">
          <a-menu-item @click="$router.push({ name: 'export' })">
            <span class="advanced-export-text"> Advanced Export </span>
          </a-menu-item>
        </a-menu>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
import { ref } from 'vue';

const fileTypeList = [
  { version: 'report', type: 'pdf', name: 'Report (PDF)' },
  { version: 'report3.2', type: 'pdf', name: 'Report 3.2 (PDF)' },
  { version: 'csv', type: 'csv', name: 'Raw data (CSV)' },
  { version: 'json', type: 'json', name: 'Raw data (JSON)' },
];

export default {
  name: 'ExportButton',
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    disabledExport: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleExport'],
  setup(props, { emit }) {
    const visible = ref(false);
    const fileTypeOptions = fileTypeList;

    const toggleExport = (fileType) => {
      visible.value = false; // close dropdown
      emit('toggleExport', fileType);
    };

    return {
      visible,
      toggleExport,
      fileTypeOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.export-button {
  height: 38px;
  .export-button-text {
    margin-left: 8px;
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 500;
  }
  &.mobile {
    height: 36px;
    width: 36px;
  }
}

.sort-content-wrapper {
  .ant-menu-item {
    padding-left: 12px !important;
    margin-bottom: 0 !important;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #5a5f7d;
    &.active {
      color: #3371ff;
      background: #f1f7ff;
    }
  }
  .ant-divider {
    margin: 10px 0 0 0;
  }

  .type-name {
    margin-left: 0.5rem;
  }

  .advanced-export-text {
    color: #097cff;
  }
}
</style>
