<template>
  <div class="ex-tree-select">
    <div v-for="obj of localList" :key="obj.id" class="ex-branch">
      <div class="ex-head">
        <template v-if="obj.children">
          <a-checkbox
            :checked="isSelectAll[obj.name]"
            :indeterminate="isSelectSome[obj.name]"
            :disabled="disabled"
            @change="selectAll($event, obj.name)"
          >
            <div class="checkbox-name-wrapper">
              <span
                v-if="content === 'source' && obj.name !== 'all Channels'"
                class="logo-website"
              >
                <FeatherIcon
                  v-if="obj.name === 'Website'"
                  type="globe"
                  class="icon-website"
                />
              </span>
              <span class="checkbox-name">{{ capitalize(obj.name) }}</span>
              <span v-if="obj.children && localState[obj.name]" class="count">
                ({{ localSelected[obj.name].length }})
              </span>
            </div>
          </a-checkbox>
          <div
            v-if="
              (content === 'source' && !disabled) ||
              content !== 'source' ||
              !disabled
            "
            class="ex-right"
            @click="toggleExpandName(obj.name)"
          >
            <FeatherIcon v-if="expandingName === obj.name" type="minus" />
            <FeatherIcon v-else type="plus" />
          </div>
        </template>
        <template
          v-else-if="content === 'source' && obj.name === 'all Channels'"
        >
          <a-checkbox
            v-model:checked="localState[obj.name]"
            :indeterminate="isSelectSourceSome && localState[obj.name]"
            :disabled="disabled"
            @change="onCheckChild($event, obj.name)"
          >
            <span class="item-name">{{ capitalize(obj.name) }}</span>
          </a-checkbox>
        </template>
        <template v-else>
          <a-checkbox
            v-model:checked="localState[obj.name]"
            :disabled="disabled"
            @change="onCheckChild($event, obj.name)"
          >
            <span v-if="content === 'source'" class="logo">
              <FeatherIcon
                v-if="obj.name === 'pantip'"
                type="message-square"
                class="icon-pantip"
              />
              <img
                v-else-if="obj.name === 'blockdit'"
                class="source-img icon-blockdit"
                :src="
                  require('@/assets/images/source-icon/Blockdit Primary Logo.png')
                "
                alt="logo"
              />
              <FontAwesomeIcon
                v-else-if="obj.name === 'facebook'"
                :icon="['fab', obj.name]"
                class="icon-facebook"
              />
              <FontAwesomeIcon
                v-else
                :icon="['fab', obj.name === 'twitter' ? 'x-twitter' : obj.name]"
                :class="{
                  'icon-twitter': obj.name == 'twitter',
                  'icon-instagram': obj.name == 'instagram',
                  'icon-youtube': obj.name == 'youtube',
                  'icon-tiktok': obj.name == 'tiktok',
                }"
              />
            </span>
            <span class="item-name">{{
              obj.name === 'twitter' ? 'X (Twitter)' : capitalize(obj.name)
            }}</span>
          </a-checkbox>
        </template>
      </div>
      <div v-if="obj.hasSearch && expandingName === obj.name" class="ex-search">
        <a-input
          v-model:value="searchInput[obj.name]"
          :placeholder="obj.searchPlaceholder"
        >
          <template #suffix>
            <FeatherIcon type="search" />
          </template>
        </a-input>
      </div>
      <div
        v-if="
          obj.children && expandingName === obj.name && content === 'source'
        "
        class="ex-leaf"
      >
        <RecycleScroller
          v-slot="{ item }"
          :items="filteredSourceChild[obj.name]"
          :item-size="40"
          :disabled="disabled"
          key-field="value"
          class="scroller"
          :class="{
            'scroller-search':
              searchInput[obj.name] &&
              filteredSourceChild[obj.name].length < 20,
          }"
        >
          <a-checkbox
            v-model:checked="item.checked"
            class="vertical"
            @change="onCheckChildBoolean($event, obj.name, item)"
          >
            <span class="item-name">{{ capitalize(item.label) }}</span>
          </a-checkbox>
        </RecycleScroller>
      </div>
      <div
        v-else-if="
          obj.children && expandingName === obj.name && content !== 'source'
        "
        class="ex-leaf"
      >
        <a-checkbox-group
          v-model:value="localState[obj.name]"
          class="vertical"
          :disabled="disabled"
          :options="
            content === 'category'
              ? sortByAlphabet(filteredChild[obj.name])
              : filteredChild[obj.name]
          "
          @change="onCheckChild($event, obj.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/services/helper';
import { ref, toRef, watch, reactive, computed, onMounted } from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';

export default {
  props: {
    treeData: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Object,
      default: () => {},
    },
    content: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const treeData = toRef(props, 'treeData');
    const selected = toRef(props, 'selected');
    const content = toRef(props, 'content');
    const localSelected = reactive({});
    const localState = reactive({});
    const localList = ref([]);
    let defaultState = {};
    const searchLists = ref([]);
    const store = useStore();
    const route = computed(() => useRoute());
    const category = computed(() => store.getters['config/categoryData']);
    const campaignName = computed(() =>
      route.value.fullPath.split('/')[1] === 'campaign'
        ? decodeURIComponent(route.value.params.id).toLowerCase()
        : ''
    );

    const isSelectAll = computed(() => {
      let result = {};
      Object.keys(defaultState).forEach((k) => {
        if (defaultState[k] && localState[k]) {
          result[k] = defaultState[k].length === localState[k].length;
        } else {
          result[k] = false;
        }
      });
      return result;
    });

    const isSelectSome = computed(() => {
      let result = {};
      Object.keys(defaultState).forEach((k) => {
        if (defaultState[k] && localState[k]) {
          result[k] =
            localState[k].length > 0 &&
            defaultState[k].length !== localState[k].length;
        } else {
          result[k] = false;
        }
      });
      return result;
    });

    const isSelectSourceSome = computed(() => {
      let result = false;
      Object.keys(localState).forEach((k) => {
        if (k === 'Website' && localState[k].length === 0) {
          result = true;
        } else if (k !== 'Website' && k !== 'all Channels' && !localState[k]) {
          result = true;
        }
      });
      return result;
    });

    const selectAll = (e, name) => {
      let result = [];
      if (e.target && e.target.checked) {
        // do select all
        result = [...defaultState[name]];
        searchLists.value = [];
      } else {
        // TODO set at least other cat
      }
      localState[name] = result;
      // const lv = toRef(localState[name]);
      emit('change', { name, list: result, localState });
    };

    const onCheckChildBoolean = (event, name, item) => {
      let result = [];
      if (item.checked) {
        localState[name].push(item.value);
      } else {
        localState[name].splice(localState[name].indexOf(item.value), 1);
      }
      emit('change', { name, result, localState });
    };

    const onCheckChild = (e, name) => {
      let result = [];
      if (content.value === 'source') {
        if (name === 'all Channels' && e.target) {
          Object.keys(localState).forEach((k) => {
            if (k === 'Website' && !e.target.checked) {
              localState[k] = [];
            } else if (k === 'Website' && e.target.checked) {
              localState[k] = defaultState[k];
            } else {
              localState[k] = e.target.checked;
            }
          });
        }
      }
      if (searchInput[name] || searchLists.value.length !== 0) {
        //List has been filtered, find actual checked value instead
        const currentlySelectedSet = localSelected[name];
        const filterTotalSet =
          content.value === 'source'
            ? filteredSourceChild.value[name].map((it) => it.value)
            : filteredChild.value[name].map((it) => it.value);
        const filterSelectedSet = localState[name];
        const uncheckValue = _.difference(filterTotalSet, filterSelectedSet);
        const addCheckValue = _.difference(
          filterSelectedSet,
          currentlySelectedSet
        );
        const checkValue = _.difference(currentlySelectedSet, uncheckValue);
        localState[name] = checkValue.concat(addCheckValue);
      }
      emit('change', { name, result, localState });
    };

    // Expand
    const expandingName = ref('');
    const toggleExpandName = (name) => {
      if (expandingName.value === name) {
        expandingName.value = '';
      } else {
        expandingName.value = name;
      }
    };

    // Filter child
    const searchInput = reactive({});
    const filteredSourceChild = computed(() => {
      const result = {};
      if (treeData.value && treeData.value.tree) {
        for (let obj of treeData.value.tree) {
          if (obj.children) {
            let sInput = searchInput[obj.name];
            const list = [];
            if (sInput) {
              sInput = sInput.toLowerCase();
              const searchlists = obj.children
                .filter((c) => {
                  const lc = c.label.toLowerCase();
                  return lc.includes(sInput);
                })
                .sort((a, b) => {
                  if (a.label === 'Others') {
                    return 1;
                  } else if (b.label === 'Others') {
                    return -1;
                  } else {
                    return 0;
                  }
                });
              for (let search of searchlists) {
                list.push({
                  category: search.category,
                  label: search.label,
                  value: search.value,
                  checked: localState[obj.name].includes(search.value),
                });
              }
            } else {
              const children = obj.children.sort((a, b) => {
                if (a.label === 'Others') {
                  return 1;
                } else if (b.label === 'Others') {
                  return -1;
                } else {
                  return 0;
                }
              });
              for (let child of children) {
                list.push({
                  category: child.category,
                  label: child.label,
                  value: child.value,
                  checked: localState[obj.name].includes(child.value),
                });
              }
            }
            result[obj.name] = list;
          }
        }
      }
      return result;
    });
    const filteredChild = computed(() => {
      const result = {};
      if (treeData.value && treeData.value.tree) {
        for (let obj of treeData.value.tree) {
          if (obj.children) {
            const sInput = searchInput[obj.name];
            if (sInput) {
              result[obj.name] = obj.children.filter((c) => {
                const lc = c.label.toLowerCase();
                return lc.includes(sInput);
              });
            } else {
              result[obj.name] = obj.children;
            }
          }
        }
      }
      return result;
    });

    // Initialize
    const initTree = () => {
      if (treeData.value && treeData.value.tree) {
        localList.value = treeData.value.tree;
        Object.assign(defaultState, _.cloneDeep(treeData.value.defaultState));
        searchLists.value = [];
      }
    };
    onMounted(() => {
      initTree();
      if (selected.value) {
        Object.assign(localState, _.cloneDeep(selected.value));
        Object.assign(localSelected, _.cloneDeep(selected.value));
      }
    });
    watch(
      () => props.treeData,
      () => {
        initTree();
        if (selected.value) {
          Object.assign(localState, _.cloneDeep(selected.value));
          Object.assign(localSelected, _.cloneDeep(selected.value));
        }
      }
    );
    watch(
      () => campaignName.value,
      () => {
        initTree();
        if (selected.value) {
          Object.assign(localState, _.cloneDeep(selected.value));
          Object.assign(localSelected, _.cloneDeep(selected.value));
        }
      }
    );
    watch(
      () => props.selected,
      () => {
        Object.assign(localState, _.cloneDeep(selected.value));
        Object.assign(localSelected, _.cloneDeep(selected.value));
      }
    );

    const sortByAlphabet = (catList) => {
      let sortData = [];
      if (catList && catList.length) {
        sortData = catList.sort((a, b) => {
          const lowerA = a.label.toLowerCase();
          const lowerB = b.label.toLowerCase();
          if (lowerA === 'others') {
            return 1;
          } else if (lowerB === 'others') {
            return -1;
          } else {
            return lowerA - lowerB;
          }
        });
      }
      return sortData;
    };
    const sortByCreatedDate = (catList, level) => {
      // find others
      let othersItem = catList.find(
        (ele) =>
          ele.value.split('::')[1] && ele.value.split('::')[1] === 'others'
      );
      // find category by level to sort data
      let categoryConfig = [...category.value];
      let categoryData = categoryConfig.find((ele) => ele.level == level);
      let sorter = [];
      if (categoryData) sorter = initSorter(categoryData);

      // sort data by created date
      let sortData = [];
      if (catList && catList.length && sorter && sorter.length) {
        sortData = catList
          .sort((a, b) => {
            const aKey = Object.keys(a)[0];
            const bKey = Object.keys(b)[0];
            return sorter.indexOf(aKey) - sorter.indexOf(bKey);
          })
          .filter((data) => {
            return data.value.split('::')[1] !== 'others';
          });
      }
      // push others at last
      if (othersItem) sortData.push(othersItem);

      return sortData;
    };

    const initSorter = (categoryData) => {
      let result = [];
      if (
        categoryData &&
        categoryData.categories &&
        categoryData.categories.length > 0
      ) {
        result = categoryData.categories
          .sort((a, b) => {
            const ca = dayjs(a.createdAt).valueOf();
            const cb = dayjs(b.createdAt).valueOf();
            // sory by asc
            return ca - cb;
          })
          .map((data) => {
            return data.category;
          });
      }
      return result;
    };

    return {
      expandingName,
      toggleExpandName,
      localState,
      localSelected,
      localList,
      isSelectAll,
      isSelectSome,
      selectAll,
      onCheckChildBoolean,
      onCheckChild,
      capitalize: helper.capitalize,
      searchInput,
      filteredChild,
      sortByCreatedDate,
      sortByAlphabet,
      searchLists,
      defaultState,
      isSelectSourceSome,
      category,
      filteredSourceChild,
    };
  },
  emits: ['change'],
};
</script>

<style lang="scss">
.ex-tree-select {
  margin-top: 16px;

  .item-name {
    line-height: 18px;
  }

  .item-name-all {
    line-height: 18px;
    font-weight: 500;
  }

  .ex-right {
    cursor: pointer;
  }

  .ex-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .count {
      color: #9299b8 !important;
      font-size: 12px;
    }
    .logo-website {
      padding-right: 8px;
      .icon-website {
        vertical-align: middle;
        line-height: 18px !important;
        color: #36d9be;
        background: #f4f5f7;
      }
    }
    .logo {
      padding-right: 8px;
      .icon-facebook {
        color: #1890ff;
        background: #f3f8fe;
      }
      .icon-twitter {
        color: #000000;
        background: #f4fafe;
      }
      .icon-instagram {
        color: #dd2a7b;
        background: #fdf4f8;
      }
      .icon-youtube {
        font-size: 14px;
        line-height: 18px;
        color: #e32212;
        background: #fef4f3;
      }
      .icon-pantip {
        vertical-align: middle;
        color: #7459c8;
        background: #f8f7fc;
      }
      .icon-blockdit {
        vertical-align: middle;
        color: #4a69ff;
        background: #f6f8ff;
      }
      .icon-tiktok {
        color: #252525;
        background: #f3f8fe;
      }
      .icon-x {
        color: #1890ff;
        background: #f3f8fe;
      }
      .icon-social {
        color: red;
      }
      .source-img {
        height: 14px;
        width: 14px;
        margin-top: -4px;
      }
      .source-img.icon-blockdit {
        margin-right: 3px;
      }
    }
  }

  .ex-search {
    padding-left: 24px;
    margin: 16px 0;
  }

  .ex-leaf {
    padding-left: 24px;
    .scroller {
      max-height: 750px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    .scroller-search {
      height: auto;
    }
    .ant-checkbox-group {
      height: auto;
      overflow-y: unset;
    }
    .ant-checkbox-group.vertical .ant-checkbox-wrapper {
      margin-bottom: 12px;
      max-width: 240px;
    }
  }

  .ant-checkbox-wrapper {
    align-items: center;
    display: flex;
  }

  .ant-checkbox {
    margin-top: 2px;
  }

  .checkbox-name-wrapper {
    display: flex;
    align-items: center;
  }

  .checkbox-name {
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    margin-right: 4px;
  }
}
</style>
<style scoped>
:deep(.ant-checkbox-wrapper-disabled span .item-name),
:deep(.ant-checkbox-wrapper-disabled span .logo),
:deep(
    .ant-checkbox-wrapper-disabled span .checkbox-name-wrapper .checkbox-name
  ),
:deep(.ant-checkbox-wrapper-disabled span .checkbox-name-wrapper .logo-website),
:deep(.ant-checkbox-wrapper-disabled span .checkbox-name-wrapper .count) {
  opacity: 0.5;
}
</style>
