<template>
  <div class="consent-modal-wrapper">
    <a-modal
      v-model:visible="visible"
      :closable="false"
      title="Policy"
      :afterClose="handleCancel"
      :destroyOnClose="true"
    >
      <template #footer>
        <a-button key="cancel" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" @click="handleOk">Accept</a-button>
      </template>

      <div class="content-wrapper">
        <div v-html="content"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import api from '@/services/api';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const hasUserInfo = computed(() => store.getters['account/hasUserInfo']);
    const isAcceptConsent = computed(() => store.getters['account/isAcceptConsent']);
    // const loading = ref(false);
    const visible = ref(false);
    const content = ref();

    onMounted(() => {
      init();
    });

    watch(
      () => route.path,
      () => {
        init();
      }
    );

    const init = async () => {
      if (!isAcceptConsent.value && hasUserInfo.value) {
        const result = await api.getConsent();
        if (result && result.message) {
          const { detail } = result.message;
          content.value = detail;
        }
        visible.value = true;
      } else {
        visible.value = false;
      }
    };

    // const showModal = () => {
    //   visible.value = true;
    // };

    const handleOk = async () => {
      // loading.value = true;
      const result = await api.acceptConsent().catch(() => {
        handleCancel();
      });
      if (result && result.code === 200) {
        store.dispatch('account/setConsent', true);
      }

      setTimeout(() => {
        // loading.value = false;
        visible.value = false;
      }, 500);
    };

    const handleCancel = () => {
      visible.value = false;

      // close on hide > check isAccept
      if (!isAcceptConsent.value) {
        window.location = '/login?out=1';
      }
    };

    return {
      // loading,
      visible,
      // showModal,
      handleOk,
      handleCancel,
      content,
    };
  },
};
</script>

<style lang="scss" scoped>
.consent-modal-wrapper {
  margin-top: 16px;
}
</style>
