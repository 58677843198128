import moment from 'moment';
import api from '../../services/api';
import helper from '../../services/helper';

const storeTempFilterKey = 'stored-filter';

export default {
  namespaced: true,
  state: {
    visibleModal: false,
    loading: false,
    title: '',
    resultData: {},
    type: 'engage',
  },
  getters: {
    currentType: ({ type }) => type,
    messageList: ({ resultData }) => {
      if (resultData && resultData.data) {
        return resultData.data;
      }
      return [];
    },
    subCategoryName: ({ resultData }) => {
      if (resultData && resultData.subCategoryName) {
        return resultData.subCategoryName;
      }
      return {};
    },
    currentCriteria: ({ resultData }) => {
      if (resultData && resultData.criteria) {
        return resultData.criteria;
      }
      return {};
    },
    currentPage: ({ resultData }) => {
      if (resultData && resultData.currentPage) {
        return resultData.currentPage;
      }
      return 1;
    },
    totalPage: ({ resultData }) => {
      if (resultData && resultData.totalPage) {
        return resultData.totalPage;
      }
      return 0;
    },
    totalRecord: ({ resultData }) => {
      if (resultData && resultData.totalRecord) {
        return resultData.totalRecord;
      }
      return 0;
    },
  },
  actions: {
    showMessageModal({ rootGetters, commit, dispatch }, filter) {
      const { payload, criteria, type = 'engage' } = filter;
      const overrideCriteria = { ...criteria };
      const { title } = payload;

      // original criteria + override criteria
      const finalCriteria = {
        ...rootGetters['filter/filterCriteria'],
        ...overrideCriteria,
        paging: {
          recordPerPage: 25,
          page: 1,
        },
      };

      commit('setModalState', {
        visibleModal: true,
        loading: true,
        title: title,
        type,
      });
      if (type === 'engage') {
        dispatch('loadMessageData', finalCriteria);
      } else if (type === 'influencer') {
        dispatch('loadInfluencerMessage', finalCriteria);
      }
    },
    async loadMessageData({ commit, dispatch }, criteria) {
      let listResult = [];
      let l;
      const result = await api.getDrilldown(criteria).catch(() => {});
      if (result && result.message) {
        const highlighted = await dispatch(
          'config/highlightKeywords',
          {
            messageList: result.message.data,
            truncateAt: 0,
          },
          { root: true }
        );
        if (criteria.type === 'channel' && result.message.criteria.source.length > 0) {
          if (result.message.criteria.source.includes(criteria.title)) {
            l = { ...result.message, data: highlighted };
          } else {
            l = { totalRecord: 0 };
          }
          listResult = l;
        } else {
          listResult = { ...result.message, data: highlighted };
        }
      }
      commit('setModalState', {
        resultData: listResult,
        loading: false,
        visibleModal: true,
      });
    },
    async loadInfluencerMessage({ commit }, criteria) {
      let listResult = [];
      const result = await api.getInfluencerDrilldown(criteria).catch(() => {});
      if (result && result.message) {
        listResult = result.message;
      }
      commit('setModalState', {
        resultData: listResult,
        loading: false,
        visibleModal: true,
      });
    },
    changePage({ commit, getters, dispatch }, data) {
      const { pageNumber, pageSize } = data;
      commit('setModalState', {
        loading: true,
      });
      const currentCriteria = getters.currentCriteria;
      const currentType = getters.currentType;
      const paging = {
        recordPerPage: pageSize,
        page: pageNumber,
      };
      const finalCriteria = { ...currentCriteria, paging };
      if (currentType === 'engage') {
        dispatch('loadMessageData', finalCriteria);
      } else if (currentType === 'influencer') {
        dispatch('loadInfluencerMessage', finalCriteria);
      }
    },
    closeModal({ commit }) {
      commit('setModalState', {
        visibleModal: false,
        loading: false,
        title: '',
        resultData: {},
      });
    },
    editMessage({ getters, commit }, e) {
      const newMsgList = JSON.parse(JSON.stringify(getters.messageList));
      helper.saveLocalMessage(e, newMsgList);
      commit('setMessageList', newMsgList);
    },
    storeCurrentFilter({ state }) {
      const { resultData } = state;
      if (resultData && resultData.criteria) {
        const finalCriteria = JSON.parse(JSON.stringify(resultData.criteria));
        delete finalCriteria.paging;
        localStorage.setItem(storeTempFilterKey, JSON.stringify(finalCriteria));
      }
    },
    getStoreDateFilter({ dispatch }) {
      const rawStoreDate = localStorage.getItem('stored-date');
      console.log('Heyy', rawStoreDate);

      if (rawStoreDate) {
        const storedDate = JSON.parse(rawStoreDate);
        if (storedDate.sinceDate && storedDate.untilDate) {
          dispatch(
            'filter/setBothCriteria',
            {
              time: {
                sinceDate: moment(storedDate.sinceDate),
                untilDate: moment(storedDate.untilDate),
              },
            },
            { root: true }
          );
        }
        // localStorage.removeItem('stored-date');
      }
    },
    setStoreDateFilter({ getters }) {
      const criteria = getters.currentCriteria;
      if (criteria) {
        const { time, sort } = criteria;
        localStorage.setItem('stored-sort', JSON.stringify(sort));
        localStorage.setItem('stored-date', JSON.stringify(time));
      }
    },
  },
  mutations: {
    setModalState(state, data) {
      let keys = Object.keys(data);
      for (let key of keys) {
        state[key] = data[key];
      }
    },
    setMessageList(state, data) {
      // Object.assign(state.resultData.data, data);
      state.resultData.data = data;
    },
  },
};
