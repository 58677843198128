<template>
  <a-modal
    ref="rejectmessage"
    v-model:visible="isVisible"
    title="Confirm Reject"
    centered
    :z-index="1005"
    :width="520"
    :footer="null"
    @cancel="close(false)"
  >
    <template #closeIcon>
      <a-button class="ant-btn-sm circular" style="width: 36px; height: 36px; background-color: #f0f2f5; top: 2px">
        <FeatherIcon type="x" size="18" />
      </a-button>
    </template>
    <div class="reject-modal-content">
      <div class="ask-text">Would you like to reject this {{isOrigin ? 'whole post' : 'message'}}?</div>
      <div class="action-button-modal">
        <a-button size="large" type="default" class="cancel-button" :outlined="true" @click="close(false)">
          Cancel
        </a-button>
        <a-button size="large" type="default" class="reject-button" @click="rejectCurrent">Reject</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { notification } from 'ant-design-vue';
import { toRefs, watch, ref } from 'vue';
import api from '@/services/api';

export default {
  props: {
    data: Object,
    visible: Boolean,
  },
  setup(props, { emit }) {
    const { visible, data } = toRefs(props);
    const isVisible = ref(visible.value);
    const isOrigin = ref();
    watch(
      () => visible.value,
      () => {
        isVisible.value = visible.value;
        isOrigin.value = data.value.info.is_origin;
      }
    );

    const close = (result) => {
      emit('close', result);
    };

    const rejectCurrent = () => {
      const { id } = data.value.info;
      // TODO single mode?
      let result = false;
      api
        .rejectMessage(id, data.value.info.is_origin ? 'applyall' : 'single')
        .then((res) => {
          if (res.status === 200) {
            result = true;
            notification.success({
              message: 'Success',
              description: 'Message rejected',
            });
          }
        })
        .catch((e) => {
          console.error(e);
          if (e.status === 400) {
            notification.success({
              message: 'Success',
              description: 'This message is being rejected, please wait for a while.',
            });
          }
          notification.error({
            message: 'Error',
            description: 'Message reject failed',
          });
        })
        .finally(() => {
          close(result);
        });
    };

    return {
      isVisible,
      rejectCurrent,
      close,
      isOrigin
    };
  },
};
</script>

<style lang="scss" scoped>
.reject-modal-content {
  .ask-text {
    color: #5a5f7d;
    font-size: 15px;
    margin-bottom: 40px;
  }
  .action-button-modal {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      font-weight: 400;
      font-size: 14px;
      height: 44px;
    }
    .reject-button {
      background: #ff4d4f;
      color: #fff;
    }
    .cancel-button {
      background: #f8f9fb;
      color: #5a5f7d;
      margin-right: 8px;
      border: 1px solid #f1f2f6;
    }
  }
}
</style>
