<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <i :class="classes" style="line-height: 0" v-html="activity"></i>
</template>

<script>
import { icons } from 'feather-icons';
import { toRefs, watchEffect, ref } from 'vue';

export default {
  name: 'FeatherIcons',
  props: {
    type: {
      type: String,
      default: 'activity',
    },
    size: {
      type: [String, Number],
      default: 16,
    },
    stroke: {
      type: [String, Number],
      default: 2,
    },
    classes: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: 'transparent',
    },
  },
  setup(props) {
    const { size, color, fill, type, stroke, classes } = toRefs(props);

    let activity = ref(
      icons[type.value].toSvg({
        'stroke-width': stroke.value,
        width: size.value,
        height: size.value,
        color: color.value,
        fill: fill.value,
        class: classes.value,
      })
    );

    watchEffect(() => {
      activity.value = icons[type.value].toSvg({
        'stroke-width': stroke.value,
        width: size.value,
        height: size.value,
        color: color.value,
        fill: fill.value,
      });
    });

    return {
      activity,
    };
  },
};
</script>

<style lang="scss" scoped></style>
