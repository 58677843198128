<template>
  <div class="custom-page-header" :class="{ mobile: isMobileScreen }">
    <div class="title-row">
      <div class="title-name">
        <span class="title">
          {{ title }}
          <span v-if="title.length <= 60 && role != 'user'">
            <FeatherIcon
              v-if="isCampaign"
              type="settings"
              size="16"
              color="#5A5F7D"
              class="btnCampaignSetting"
              @click="goToEditCampaign()"
            />
          </span>
        </span>
        <span v-if="title.length > 60 && role != 'user'">
          <FeatherIcon
            v-if="isCampaign"
            type="settings"
            size="16"
            color="#5A5F7D"
            class="btnCampaignSetting"
            @click="goToEditCampaign()"
          />
        </span>
        <div
          class="subtitle-bottom"
          v-if="(hasSubtitle || subtitle) && hasBottomSubtitle"
        >
          {{ subtitle }}
        </div>
      </div>

      <div class="right-actions">
        <slot name="title-actions"></slot>
        <div v-if="isMobileScreen" class="flex-align-center">
          <ExportButton
            v-if="hasExport"
            class="mr-2"
            :mobile="true"
            :disabledExport="disabledExport"
            @toggleExport="toggleExport"
          ></ExportButton>
          <a-button
            v-if="hasFilter"
            class="square-btn"
            @click="isExpandingToolbar = !isExpandingToolbar"
          >
            <FeatherIcon
              type="sliders"
              size="14"
              :color="isExpandingToolbar ? '#3371FF' : '#5A5F7D'"
            ></FeatherIcon>
          </a-button>
        </div>
        <div v-else class="flex-align-center">
          <ExportButton
            v-if="hasExport"
            class="mr-2"
            :disabledExport="disabledExport"
            @toggleExport="toggleExport"
          ></ExportButton>
          <DateRangeButton
            display-format="YYYY-MM-DD"
            v-if="hasDatePicker"
            class="mr-2"
          />
          <!-- <DateRangePickerButton v-if="hasDatePicker" class="mr-2" /> -->
          <FilterButton v-if="hasFilterButton" />
        </div>
      </div>
    </div>
    <div
      v-if="hasSubtitle || subtitle"
      class="subtitle-row"
      :class="{ 'subtitle-mx-width': maxWidth }"
    >
      <div class="subtitle" v-if="!hasBottomSubtitle">
        {{ subtitle }}
      </div>

      <div class="left-actions">
        <slot name="subtitle-actions-left"></slot>
      </div>

      <div class="right-actions">
        <slot name="subtitle-actions"></slot>
      </div>
    </div>
    <div v-if="isMobileScreen && isExpandingToolbar" class="expand-row">
      <div class="right-actions mt-2">
        <DateRangeButton
          display-format="YYYY-MM-DD"
          v-if="hasDatePicker"
          style="flex: 1 1 auto"
        />
        <!-- <DateRangePickerButton v-if="hasDatePicker" style="flex: 1 1 auto" /> -->
        <FilterButton v-if="hasFilterButton" />
      </div>
      <div class="right-actions">
        <slot name="subtitle-actions-mobile"></slot>
      </div>
    </div>
    <div class="shadow-row"></div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import FilterButton from '@/components/Button/FilterButton.vue';
// import DateRangePickerButton from '@/components/Button/DateRangePickerButton.vue';
import DateRangeButton from '@/components/Button/DateRangeButton.vue';
import ExportButton from '@/components/Button/ExportButton.vue';

export default {
  components: {
    FilterButton,
    // DateRangePickerButton,
    DateRangeButton,
    ExportButton,
  },
  props: {
    maxWidth: { type: Boolean, default: false },
    hasBottomSubtitle: { type: Boolean, default: false },
    title: {
      type: String,
      default: 'Title',
    },
    hasSubtitle: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    hasFilter: {
      type: Boolean,
      default: true,
    },
    hasDatePicker: {
      type: Boolean,
      default: true,
    },
    hasExport: {
      type: Boolean,
      default: false,
    },
    hasSort: {
      type: Boolean,
      default: true,
    },
    sortType: {
      type: String,
      default: 'normal',
    },
    hasCountSelect: {
      type: Boolean,
      default: true,
    },
    disabledExport: {
      type: Boolean,
      default: false,
    },
    isCampaign: {
      type: Boolean,
      default: false,
    },
    hasFilterButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['toggleExport'],
  setup(props, { emit }) {
    const { title } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const isExpandingToolbar = ref(false);
    const role = computed(() => store.getters['account/userRole']);

    const toggleExport = (e) => {
      emit('toggleExport', e);
    };

    const goToEditCampaign = () => {
      router.push(`/campaign/edit/${encodeURIComponent(title.value)}`);
    };

    return {
      isMobileScreen,
      isExpandingToolbar,
      toggleExport,
      goToEditCampaign,
      role,
    };
  },
};
</script>

<style lang="scss">
.custom-page-header {
  padding: 20px 30px;
  background-color: #fff;
  border-top: 1px solid #f1f2f6;
  position: relative;
  .title-row,
  .subtitle-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .right-actions {
    display: flex;
    align-items: center;
    column-gap: 8px;
    .ant-btn {
      border: 1px solid #d9d9d9 !important;
    }
    .ant-select {
      border-radius: 4px !important;
      .ant-select-selector {
        height: 36px;
        border-radius: 4px;
      }
      .ant-select-selector .ant-select-selection-item {
        line-height: 36px !important;
      }
    }
    .square-btn {
      height: 36px;
      width: 36px;
    }
  }
  .title-row {
    .title {
      font-size: 20px;
      color: #272b41;
      font-weight: 500;
      text-transform: capitalize;
      margin-right: 3px;
    }
    .btnCampaignSetting {
      cursor: pointer;
    }
  }
  .subtitle-row {
    margin-top: 16px;
    .subtitle {
      font-size: 14px;
      color: #515f7d;
    }
  }
  .subtitle-bottom {
    font-size: 14px;
    color: #515f7d;
  }
  .shadow-row {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: 0px 2px 6px 1px rgb(227 231 235 / 79%);
    height: 1px;
    background-color: #f1f2f6;
  }
  &.mobile {
    padding: 20px 15px 15px 15px;
    .subtitle-row {
      margin-top: 8px;
    }
  }
  @media only screen and (max-width: 768px) {
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      font-size: 18px !important;
    }
    .title-name {
      width: 75%;
      display: flex;
      align-items: flex-end;
    }
    .btnCampaignSetting {
      line-height: 0.8em;
    }
  }
  @media only screen and (max-width: 480px) {
    .title {
      font-size: 18px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      // line-clamp: 2;
      overflow-wrap: anywhere;
      -webkit-box-orient: vertical;
      white-space: unset;
    }
    .title-name {
      width: 75%;
      align-items: flex-end;
      display: inline-flex;
    }
    .btnCampaignSetting {
      line-height: 0.8em;
    }
  }
}
.subtitle-mx-width {
  max-width: 800px;
  margin: auto;
}
</style>
