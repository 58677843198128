<template>
  <div>
    <highcharts ref="trendingChartRef" :options="option"></highcharts>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { toRefs, watch, ref, reactive } from 'vue';
// import _ from 'lodash';
import moment from 'moment';

const TrendingChart = {
  name: 'TrendingChart',
  components: {
    highcharts: Chart,
  },
  props: {
    series: VueTypes.array.def([]),
    chartType: VueTypes.string.def('line'),
  },
  setup(props) {
    let trendingChartRef = ref(null);
    let { chartType, series } = toRefs(props);
    const option = reactive({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
      chart: {
        type: chartType.value,
        height: 450,
      },
      time: {
        timezone: 'Asia/Bangkok',
        useUTC: false,
      },
      credits: {
        enabled: false,
      },

      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: null,
        },
      },
      xAxis: {
        type: 'datetime',
        labels: {
          overflow: 'justify',
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        itemStyle: {
          color: '#868EAE',
          fontFamily: 'DM Sans, IBM Plex Sans Thai, serif',
          fontSize: '13px',
          fontWeight: '400',
        },
        // verticalAlign: 'middle',
      },

      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        column: {
          stacking: 'normal',
        },
      },

      tooltip: {
        xDateFormat: '%d %B %Y',
        formatter: function () {
          // let granularity = this.points[0].series.tooltipOptions.granularity;
          // let time;
          // if (granularity === 'hourly') {
          //   time = moment(this.x, 'x').format('dddd, MMM D YYYY, h:mm a');
          // } else if (granularity === 'daily') {
          //   time = moment(this.x, 'x').format('dddd, MMM D YYYY');
          // } else if (granularity === 'monthly') {
          //   time = moment(this.x, 'x').format('MMM YYYY');
          // } else if (granularity === 'yearly') {
          //   time = moment(this.x, 'x').format('YYYY');
          // }
          let time = moment(this.x, 'x').format('dddd, MMM D YYYY');
          let str = '<span>' + time + '</span><br/>';
          for (let i in this.points) {
            str =
              str +
              '<span style="color:' +
              this.points[i].color +
              '">\u25CF</span> ' +
              this.points[i].series.name +
              ' : <b>' +
              Highcharts.numberFormat(this.points[i].y, 0, '.', ',') +
              '</b><br/>';
          }
          return str;
        },
        shared: true,
        useHTML: true,
        outside: false,
      },

      series: series.value,
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    });
    watch(series, (current, previous) => {
      if (current !== previous) {
        option.series = current;
        // trendingChartRef.value.chart.update({ series: current }, true, true, true);
      }
    });
    watch(chartType, (current, previous) => {
      if (current !== previous) {
        option.chart = {
          type: current,
        };
        // trendingChartRef.value.chart.update(
        //   {
        //     chart: {
        //       type: current,
        //     },
        //   },
        //   true,
        //   true,
        //   true
        // );
      }
    });
    // const onResize = _.debounce(({ width }) => {
    //   console.log(trendingChartRef);
    //   trendingChartRef.value.chart.setSize(width, 350);
    // }, 100);
    return {
      option,
      // onResize,
      trendingChartRef,
    };
  },
};

export default TrendingChart;
</script>

<style scoped>
@media screen and (max-width: 775px) {
  :deep(.highcharts-legend .highcharts-legend-item .highcharts-point) {
    y: 5 !important;
  }
}
</style>
