import api from '@/services/api';
export default {
  namespaced: true,
  state: {
    socialMediaAccounts: [],
  },
  getters: {},
  actions: {
    async loadSocialMediaAccounts({ commit }, { username }) {
      let res = await api.getSocialMediaUsers(username);
      let socialMediaUsers = res.message.data.map((v) => {
        let userLink = '';
        if (v.userLink) {
          userLink = v.userLink;
        }

        let id = '';
        if (v.source == 'facebook') {
          const parts = userLink.split('/');
          id = parts[parts.length - 1];
        } else {
          const parts = v.userId.split('_');
          id = parts[1];
        }

        return {
          name: v.userName,
          label: v.userName,
          value: v.userId,
          source: v.source,
          id: id,
        };
      });
      commit('setSocialMediaAccounts', socialMediaUsers);
    },
  },
  mutations: {
    setSocialMediaAccounts(state, data) {
      state.socialMediaAccounts = [...data];
    },
  },
};
