import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import account from './modules/account';
import config from './modules/config';
import filter from './modules/filter';
import message from './modules/message';
import seemore from './modules/seemore';
import user from './modules/user';
import socialMediaGroup from './modules/socialMediaGroup';
import sourceSetting from './modules/sourceSetting';
import campaign from './modules/campaign';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'domv3',
  reducer: (state) => ({ account: state.account }),
});

// Note *  main store is for uncategorized
// ------- before refactor
export default createStore({
  state: {
    sideNavCollapsed: false,
    isSmallScreen: false,
    isMobileScreen: false,
  },
  getters: {
    isSmallerMobileScreen: ({ isSmallScreen, isMobileScreen }) => isSmallScreen || isMobileScreen,
  },
  mutations: {
    setNavLayoutData(state, data) {
      state.sideNavCollapsed = data.sideNavCollapsed;
      state.isSmallScreen = data.isSmallScreen;
      state.isMobileScreen = data.isMobileScreen;
    },
  },
  actions: {
    setNavLayoutData({ commit }, data) {
      commit('setNavLayoutData', data);
    },
  },
  modules: {
    account,
    config,
    filter,
    message,
    seemore,
    user,
    socialMediaGroup,
    sourceSetting,
    campaign,
  },
  plugins: [vuexLocal.plugin],
});
