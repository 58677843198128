<template>
  <a-modal
    v-model:visible="localOpen"
    title="Message Detail"
    width="838px"
    :footer="null"
    :z-index="1001"
    :afterClose="handleCancel"
    :destroyOnClose="true"
  >
    <template #closeIcon>
      <a-button type="ghost" class="ant-btn-sm circular" style="width: 36px">
        <FeatherIcon type="x" size="18" />
      </a-button>
    </template>
    <div v-if="isLoading">
      <a-skeleton active />
    </div>
    <div v-else class="seemore-content">
      <!-- TODO No data -->
      <!-- TODO Video content -->
      <!-- TODO Image content -->
      <template v-if="isLineage">
        <LineageItem v-for="message in messageList" :mention-count="totalMentionedCount" :key="message.id" :message="message" :parentName="'seemore'" />
      </template>
      <template v-else-if="originMessage && originMessage.info && originMessage.info.source === 'twitter'">
        <MessageItem
          class="list-item"
          :item="originMessage"
          :show-retweet-bar="true"
          :retweet-list="messageList"
          :show-category="true"
          :media-block="true"
          :show-long-text="true"
          :parentName="'seemore'"
        />
      </template>
      <template v-else>
        <MessageItem
          v-for="message in messageList"
          :key="message.id"
          class="list-item"
          :item="message"
          :show-category="true"
          :media-block="true"
          :show-long-text="true"
          :parentName="'seemore'"
          :class="{ 'message-comment': !message.info.is_origin }"
        />
        <div class="text-center">
          <a-pagination
            v-model:pageSize="pageSize"
            v-model:current="pageNumber"
            show-size-changer
            :default-current="1"
            :total="totalRecord"
            :pageSizeOptions="['10', '25', '50', '100']"
            @showSizeChange="onPageSizeChange"
            @change="changePage"
          />
        </div>
      </template>
    </div>
  </a-modal>
</template>

<script>
import { ref, computed, provide, watch } from 'vue';
import { useStore } from 'vuex';

import LineageItem from '@/components/Seemore/LineageItem.vue';
import MessageItem from '@/components/Message/MessageItem.vue';

export default {
  components: {
    LineageItem,
    MessageItem,
  },
  setup() {
    const store = useStore();
    // Open state
    const localOpen = ref(false);
    const storeOpen = computed(() => store.state.seemore.open);
    const storeLoading = computed(() => store.state.seemore.loading);
    watch(
      () => storeOpen.value,
      () => {
        localOpen.value = storeOpen.value;
      }
    );

    // Message List
    const messageList = computed(() => store.state.seemore.messageList);
    const totalMentionedCount = computed(() => store.state.seemore.totalMentioned);
    const originMessage = computed(() => store.getters['seemore/originMessage']);
    const hasVideo = computed(() => {
      if (originMessage.value) {
        const { info, raw_data } = originMessage.value;
        // youtube
        if (raw_data.video) return true;
        // fb / ig / twitter
        if (info.has_video) return true;
      }
      return false;
    });

    const pageSize = ref(25);
    const pageNumber = ref(1);
    const changePage = (pn, ps) => {
      pageNumber.value = pn;
      pageSize.value = ps;
      store.dispatch('seemore/changePage', { pageSize: pageSize.value, pageNumber: pageNumber.value });
    };
    const onPageSizeChange = (c, ps) => {
      pageSize.value = ps;
      store.dispatch('seemore/changePage', { pageSize: pageSize.value, pageNumber: pageNumber.value });
    };

    watch(
      () => storeLoading.value,
      () => {
        setTimeout(() => {
          // console.log('SCROLL DAI MAI NA');
          const element = document.querySelector('.target-item');
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    );

    // Provide edit message data
    provide('onEditMessage', (e) => {
      store.dispatch('seemore/editMessage', e);
    });

    // Provide sub cat name
    provide(
      'subCategoryName',
      computed(() => store.getters['seemore/subCategoryName'])
    );

    return {
      localOpen,
      messageList,
      totalMentionedCount,
      originMessage,
      hasVideo,
      isLoading: computed(() => store.state.seemore.loading),
      // Lineage
      isLineage: computed(() => store.state.seemore.isLineage),
      handleCancel: () => store.dispatch('seemore/close'),
      // Non lineage
      currentPage: computed(() => store.getters['seemore/currentPage']),
      totalPage: computed(() => store.getters['seemore/totalPage']),
      totalRecord: computed(() => store.getters['seemore/totalRecord']),
      changePage,
      onPageSizeChange,
      pageSize,
      pageNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.seemore-content {
  border: 1px solid #f1f2f6;
  border-radius: 8px;
  padding-top: 20px;
  .image-card {
    padding: 0 1rem;
  }
  .video-card {
    background-color: #000;
    text-align: center;
  }
  .tweet-wrapper {
    display: flex;
    justify-content: center;
    min-height: 650px;
    height: 100%;
    .inner-tweet-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .message-comment {
    margin-left: 15px;
  }
}
</style>
