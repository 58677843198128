<template>
  <a-card :title="'Active Time'">
    <template v-if="!isMobileScreen" #extra>
      <div id="level-select" class="category-select-list">
        <a-select
          :get-popup-container="(trigger) => trigger.parentNode"
          style="width: 165px"
          :default-value="selectSource"
          @change="handleChangeSource"
          :disabled="loading"
        >
          <a-select-option
            v-for="(source, index) in sourceLists"
            :key="index"
            :value="source"
            class="select-option"
          >
            <span class="tree-icon">
              <div
                v-if="
                  source !== 'blockdit' &&
                  source !== 'pantip' &&
                  source !== 'website' &&
                  source !== 'tiktok'
                "
                class="circle-icon"
                :class="source"
              >
                <box-icon
                  type="logo"
                  :name="source"
                  class="icon"
                  color="#ffffff"
                ></box-icon>
              </div>
              <div
                v-if="source === 'pantip'"
                class="circle-icon"
                :class="source"
              >
                <font-awesome-icon
                  :icon="['fas', 'comments']"
                  style="color: #ffffff"
                  class="icon"
                />
              </div>
              <img
                v-if="source === 'blockdit'"
                class="icon-blockdit"
                :src="require('@/assets/images/source-icon/Blockdit-Icon.svg')"
              />

              <img
                v-if="source === 'tiktok'"
                class="icon-tiktok"
                :src="require('@/assets/images/source-icon/svg/tiktok.svg')"
              />

              <img
                v-if="source === 'website'"
                class="icon-website"
                :src="require('@/assets/images/source-icon/svg/website.svg')"
              />
              {{ source }}
            </span>
          </a-select-option>
        </a-select>
      </div>
    </template>
    <div v-if="loading" class="load-wrapper">
      <a-skeleton :loading="loading" active />
    </div>

    <SourceSelection
      v-if="!loading && isMobileScreen"
      :sourceSelected="selectSource"
      @onSourceSelect="handleChangeSource"
      :noData="!loading && selectedData && selectedData.length === 0"
    ></SourceSelection>

    <div
      v-if="!loading && selectedData && selectedData.length > 0"
      class="heatmap-activetime"
    >
      <Heatmap
        :x-axis-categories="xAxisCategories"
        :y-axis-categories="yAxisCategories"
        :data="selectedData"
        :data-lable="dataLabel"
        :tooltip-formatter-function="tooltipFormatterFunction"
        :description-formatter-function="descriptionFormatterFunction"
        :maxColor="maxColor"
        :type="'channel'"
        :source="selectSource"
      ></Heatmap>
    </div>

    <NoDataFound
      v-else-if="!loading && selectedData && selectedData.length === 0"
    />
  </a-card>
</template>

<script>
import { ref, reactive, watchEffect, computed, toRefs } from 'vue';
import { useStore } from 'vuex';
import numeral from 'numeral';

import api from '@/services/api';

import NoDataAspect from '@/components/Error/NoDataAspect.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import Heatmap from '@/components/Chart/Heatmap.vue';
import SourceSelection from '@/components/Selections/SourceSelection.vue';
import helper from '@/services/helper';

const ChannelActiveTime = {
  name: 'ChannelActiveTime',
  components: {
    NoDataAspect,
    NoDataFound,
    Heatmap,
    SourceSelection,
  },
  props: {
    mode: {
      type: String,
      default: 'engagement',
    },
    filter: Object,
  },
  setup(props) {
    const store = useStore();
    let { mode, filter } = toRefs(props);
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const sList = api.getSocialSource();
    const socialList = reactive(sList);
    const sourceLists = ref([...socialList, 'website']);
    let selectSource = ref('facebook');
    let loading = ref(true);
    let error = ref(false);
    let engagementData = ref([]);
    let countData = ref([]);
    let viewData = ref([]);
    let maxColor = ref('#1877F2');
    const selectedData = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return engagementData.value;
        } else if (mode.value.toLowerCase() === 'mention') {
          return countData.value;
        } else {
          return viewData.value;
        }
      } else {
        // console.log('heatmap', engagementData.value);
        return engagementData.value;
      }
    });
    const dataLabel = computed(() => {
      if (mode.value) {
        if (mode.value.toLowerCase() === 'engagement') {
          return 'Total Engagement';
        } else if (mode.value.toLowerCase() === 'mention') {
          return 'Total Mention';
        } else {
          return 'Total View';
        }
      } else {
        return 'Total Engagemen';
      }
    });
    const xAxisCategories = [
      '12am',
      '1am',
      '2am',
      '3am',
      '4am',
      '5am',
      '6am',
      '7am',
      '8am',
      '9am',
      '10am',
      '11am',
      '12pm',
      '1pm',
      '2pm',
      '3pm',
      '4pm',
      '5pm',
      '6pm',
      '7pm',
      '8pm',
      '9pm',
      '10pm',
      '11pm',
    ];
    const yAxisCategories = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const getPointCategoryName = function (point, dimension) {
      var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
      return axis.categories[point[isY ? 'y' : 'x']];
    };
    const tooltipFormatterFunction = function () {
      let value = 'Unknown';
      if (this.point != null && this.point.value != null) {
        value = numeral(this.point.value).format('0,0');
      }
      return (
        '<b>' +
        dataLabel.value +
        '</b> ' +
        value +
        '<br>on ' +
        getPointCategoryName(this.point, 'y') +
        '<br>at ' +
        getPointCategoryName(this.point, 'x')
      );
    };
    const isAllZero = function (arr) {
      let allZero = true;
      for (let engageObj of arr) {
        if (engageObj[2] > 0) {
          allZero = false;
          break;
        }
      }
      return allZero;
    };
    const descriptionFormatterFunction = function () {
      let value = 'Unknown';
      if (this.point != null && this.point.value != null) {
        value = numeral(this.point.value).format('0,0');
      }
      return (
        value +
        ' ' +
        dataLabel.value +
        ' on ' +
        getPointCategoryName(this.point, 'y') +
        ' at ' +
        getPointCategoryName(this.point, 'x')
      );
    };
    const handleChangeSource = (source) => {
      selectSource.value = source;
      maxColor.value = helper.getColorChannel(source)
    };
    const loadData = async (apiFilter) => {
      const qs = reactive({});
      const result = await api.getActiveTimeData(apiFilter, qs).catch(() => {
        console.error('Heatmap api call error', result);
        error.value = true;
      });
      // console.log('result', result.message);
      if (
        result.message != null &&
        result.message.countData != null &&
        result.message.engagementData != null
      ) {
        if (isAllZero(result.message.engagementData)) {
          engagementData.value = [];
        } else {
          engagementData.value = result.message.engagementData;
        }
        if (isAllZero(result.message.countData)) {
          countData.value = [];
        } else {
          countData.value = result.message.countData;
        }
      }
      loading.value = false;
    };
    watchEffect(() => {
      loading.value = true;
      error.value = false;
      const fv = {
        ...filter.value,
        source: [selectSource.value],
      };
      loadData(fv);
    });

    return {
      loading,
      selectedData,
      dataLabel,
      xAxisCategories,
      yAxisCategories,
      tooltipFormatterFunction,
      descriptionFormatterFunction,
      sourceLists,
      selectSource,
      handleChangeSource,
      maxColor,
      isMobileScreen,
    };
  },
};

export default ChannelActiveTime;
</script>

<style lang="scss" scoped>
.load-wrapper {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heatmap-activetime {
  height: 420px;
  margin-top: 20px;
}
.tree-icon {
  display: flex;
  .circle-icon {
    width: 15px;
    height: 15px;
    margin: auto 0.5rem auto 0;
    border-radius: 50%;
    vertical-align: middle;
    .icon {
      width: 15px;
      height: 15px;
      line-height: 15px;
      vertical-align: top;
    }
    &.facebook {
      background-color: #1877f2;
    }
    &.instagram {
      background-color: #dd2a7b;
      .icon {
        width: 10px;
        height: 15px;
        line-height: 15px;
        margin-left: 3px;
      }
    }
    &.twitter {
      background-color: #1da1f2;
      .icon {
        width: 10px;
        height: 15px;
        line-height: 15px;
        margin-left: 3px;
      }
    }
    &.youtube {
      background-color: #e32212;
      .icon {
        width: 10px;
        height: 15px;
        line-height: 15px;
        margin-left: 3px;
      }
    }
    &.pantip {
      background-color: #7459c8;
      .icon {
        width: 10px;
        height: 15px;
        line-height: 10px;
        margin-left: 2px;
      }
    }
    &.website {
      background-color: #252525;
      .icon {
        line-height: 16px !important;
        margin-left: 2px;
      }
    }
  }
  .icon-blockdit,
  .icon-website,
  .icon-tiktok {
    width: 15px;
    height: 15px;
    margin: auto 0.5rem auto 0;
  }
}
</style>
<style scoped>
:deep(
    .ant-select-dropdown
      .ant-select-item.ant-select-item-option-selected
      .ant-select-item-option-content
  ),
:deep(.ant-select-dropdown .ant-select-item .ant-select-item-option-content),
:deep(#level-select .ant-select-selector .ant-select-selection-item) {
  text-transform: capitalize;
}
</style>
