<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import NavLayout from './NavLayout.vue';
import BlankLayout from './BlankLayout.vue';

export default {
  name: 'AppLayout',
  components: {
    NavLayout,
    BlankLayout,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      const name = route.meta.layout || 'NavLayout';
      return name;
    });
    return {
      layout,
    };
  },
};
</script>
