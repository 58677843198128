<template>
  <div
    class="engagement-icon"
    :class="{
      'reaction-icon': type == 'Reaction' || type == 'reaction_count',
      'small-reaction-icon': type === 'reaction_small',
    }"
  >
    <img
      v-if="type === 'Reaction' || type === 'reaction_count' || type === 'reaction_small'"
      :src="require('../../assets/images/sentiment/reaction.png')"
      alt=""
    />
    <img
      v-if="type === 'Angry' || type === 'angry_count'"
      :src="require('../../assets/images/sentiment/angry.png')"
      alt=""
    />
    <img
      v-if="type === 'Comment' || type === 'comment_count'"
      :src="require('../../assets/images/sentiment/comment.png')"
      alt=""
    />
    <img
      v-if="type === 'Dislike' || type === 'dislike_count'"
      :src="require('../../assets/images/sentiment/dislike.png')"
      alt=""
    />
    <img
      v-if="type === 'Favourite' || type === 'favourite_count'"
      :src="require('../../assets/images/sentiment/favorite.png')"
      alt=""
    />
    <img
      v-if="type === 'Haha' || type === 'haha_count'"
      :src="require('../../assets/images/sentiment/haha.png')"
      alt=""
    />
    <img
      v-if="type === 'Like' || type === 'like_count'"
      :src="require('../../assets/images/sentiment/like.png')"
      alt=""
    />
    <img
      v-if="type === 'Love' || type === 'love_count'"
      :src="require('../../assets/images/sentiment/love.png')"
      alt=""
    />
    <img
      v-if="type === 'Retweet' || type === 'retweet_count'"
      :src="require('../../assets/images/sentiment/retweet.png')"
      alt=""
    />
    <img
      v-if="type === 'Tweet' || type === 'tweet_count'"
      :src="require('../../assets/images/sentiment/tweet.png')"
      alt=""
    />
    <img v-if="type === 'Sad' || type === 'sad_count'" :src="require('../../assets/images/sentiment/sad.png')" alt="" />
    <img
      v-if="type === 'Share' || type === 'share_count'"
      :src="require('../../assets/images/sentiment/share.png')"
      alt=""
    />
    <img
      v-if="type === 'View' || type === 'view_count'"
      :src="require('../../assets/images/sentiment/view.png')"
      alt=""
    />
    <img v-if="type === 'Wow' || type === 'wow_count'" :src="require('../../assets/images/sentiment/wow.png')" alt="" />
    <img
      v-if="type === 'Post' || type === 'post_count'"
      :src="require('../../assets/images/sentiment/post.png')"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: 'EngagementIcon',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.engagement-icon {
  position: relative;
  display: inline;
  margin-right: 20px;
  img {
    position: absolute;
    width: 18px;
    height: 18px;
    margin-top: 2px;
  }
  &.small-reaction-icon {
    margin-right: 55px;
    img {
      width: auto;
      height: 32px;
      margin-top: -6px;
      margin-left: -4px;
    }
  }
  &.reaction-icon {
    margin-right: 60px;
    img {
      width: auto;
      height: 32px;
      margin-top: -5px;
    }
  }
}
</style>
