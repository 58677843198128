<template>
  <a-row :gutter="25">
    <a-col :xxl="24" :xl="24" :lg="24" :xs="24">
      <TrendingHistory :filter="filterCriteria" />
    </a-col>
  </a-row>
  <a-row :gutter="25" class="social-movement">
    <a-col v-if="isMobileScreen">
      <div class="social-movement-title">Social Movement</div>
    </a-col>
    <a-col v-else :xs="24" :md="12">
      <div class="social-movement-title">Social Movement</div>
    </a-col>
    <a-col v-if="isMobileScreen" class="mobile-social-movement-filter-mode">
      <a-dropdown
        placement="bottomRight"
        :getPopupContainer="(trigger) => trigger.parentNode"
      >
        <img :src="require('@/assets/images/icon/settings.svg')" />
        <template #overlay>
          <a-menu id="mode-menu-mobile" @click="handleModeClick">
            <a-menu-item-group title="Measurement:">
              <a-menu-item
                v-for="mode in modeOptions"
                :key="mode.value"
                :style="
                  selectedDataAspect === mode.value
                    ? selectedModeStyle
                    : unSelectedModeStyle
                "
              >
                {{ mode.label }}
              </a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
      </a-dropdown>
    </a-col>
    <a-col v-else :xs="24" :md="12">
      <div class="social-movement-filter-mode">
        <span class="social-movement-data-aspect">Measurement: </span>
        <a-radio-group
          v-model:value="selectedDataAspect"
          button-style="solid"
          size="default"
        >
          <a-radio-button value="engagement" style="height: 34px"
            >Engagement</a-radio-button
          >
          <a-radio-button value="count" style="height: 34px"
            >Mentioned</a-radio-button
          >
        </a-radio-group>
      </div>
    </a-col>
  </a-row>
  <a-row :gutter="25">
    <a-col :xxl="8" :xl="10" :lg="12" :xs="24">
      <ChannelAndCategory
        :mode="mode"
        type="channel"
        :filter="filterCriteria"
      />
    </a-col>
    <a-col :xxl="16" :xl="14" :lg="12" :xs="24">
      <!-- {{ categoryByLevel }} -->
      <ChannelAndCategory
        :mode="mode"
        type="category"
        :category-by-level="categoryByLevel"
        :filter="filterCriteria"
        :category-data="categoryData"
        chart-type="category"
        @handleMenuClick="handleChangeCategoryLevel"
      >
        <template v-slot:category="{ noData }">
          <!-- <div class="category-select-list">
                <a-dropdown :trigger="['click']" placement="bottomRight">
                  <a class="ant-dropdown-link" @click.stop="">
                    Category
                    <FeatherIcon type="chevron-down" size="12" />
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item key="3">3rd menu item</a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div> -->
          <div
            v-if="!isMobileScreen"
            id="level-select"
            class="category-select-list"
          >
            <a-select
              v-model:value="categoryLevel"
              :get-popup-container="(trigger) => trigger.parentNode"
              style="width: 150px"
              :disabled="noData"
              :placeholder="`No Category`"
              @change="handleChangeCategoryLevel"
            >
              <a-select-option
                v-for="cat in categoryData"
                :key="cat.level"
                :value="cat.level"
              >
                {{ cat.name }}
              </a-select-option>
            </a-select>
          </div>
        </template>
      </ChannelAndCategory>
    </a-col>
  </a-row>

  <a-row :gutter="25">
    <a-col :xxl="8" :xl="10" :lg="12" :xs="24">
      <Sentiment :mode="mode" :filter="filterCriteria" />
    </a-col>
    <a-col :xxl="16" :xl="14" :lg="12" :xs="24">
      <SentimentBySourceVue :mode="mode" :filterResult="filterCriteria" />
    </a-col>
  </a-row>
  <a-row :gutter="25">
    <a-col :xxl="24" :xl="24" :lg="24" :xs="24">
      <ActiveTime :mode="mode" :filter="filterCriteria" />
    </a-col>
  </a-row>
  <a-row :gutter="25">
    <a-col :xxl="24" :xl="24" :lg="24" :xs="24">
      <TopInfluencer :mode="mode" :filter="filterCriteria" />
    </a-col>
  </a-row>
  <a-row :gutter="25">
    <a-col :xxl="24" :xl="24" :lg="24" :xs="24">
      <TopPost :mode="mode" :filter="filterCriteria" />
    </a-col>
  </a-row>
  <!-- <SeeMoreDialog/> -->
</template>

<script>
import { useStore } from 'vuex';
import TrendingHistory from './TrendingHistory.vue';
import TopInfluencer from './TopInfluencer.vue';
import TopPost from './TopPost.vue';
import ChannelAndCategory from './ChannelAndCategory.vue';
import Sentiment from './Sentiment.vue';
import SentimentBySourceVue from './SentimentBySource.vue';
import ActiveTime from './ActiveTime';
import { ref, onMounted, computed, watch } from 'vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
export default {
  name: 'OverviewSection',
  components: {
    // SeeMoreDialog,
    TopInfluencer,
    TopPost,
    ChannelAndCategory,
    Sentiment,
    ActiveTime,
    TrendingHistory,
    SentimentBySourceVue,
  },
  setup() {
    const store = useStore();
    const { state, dispatch } = store;
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const categoryData = computed(() => state.config.categoryData);
    const isMobileScreen = computed(() => state.isMobileScreen);
    let visible = ref('false');
    let loading = ref(true);
    let categoryLevel = ref(null);
    let mode = ref('engagement'); //engagement,count(mention),view
    let selectedDataAspect = ref('engagement');
    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'count' },
    ];

    const init = async () => {
      loading.value = true;
      await dispatch('config/getAccountKeyword');
      loading.value = false;
    };

    const handleChangeCategoryLevel = (level) => {
      categoryLevel.value = level;
    };

    const categoryByLevel = computed(() => {
      return categoryData.value[categoryLevel.value];
    });

    const showDrawer = () => {
      visible.value = true;
    };
    const onClose = () => {
      visible.value = false;
    };

    const handleModeClick = async (e) => {
      selectedDataAspect.value = e.key;
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    watch(selectedDataAspect, (previous) => {
      mode.value = previous;
    });

    watch(
      () => filterCriteria.value,
      () => {
        init();
      }
    );

    onMounted(() => {
      init();
      if (categoryData.value.length > 0) {
        categoryLevel.value = 0;
      }
    });

    return {
      loading,
      mode,
      categoryData,
      handleChangeCategoryLevel,
      showDrawer,
      onClose,
      visible,
      selectedDataAspect,
      categoryByLevel,
      filterCriteria,
      isMobileScreen,
      modeOptions,
      handleModeClick,
      selectedModeStyle,
      unSelectedModeStyle,
      categoryLevel,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-section-title {
  margin-bottom: 24px;
}
.page-section-title,
.social-movement-title {
  color: #272b41;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
.w-100 {
  width: 100%;
}
.social-movement {
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;
  .social-movement-filter-mode {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .social-movement-data-aspect {
      margin-right: 14px;
      font-size: 14px;
      color: #5a5f7d;
    }
  }

  @media screen and (max-width: 767px) {
    .social-movement-title {
      margin-bottom: 12px;
    }
    .mobile-social-movement-filter-mode {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.category-select-list {
  margin-right: 5px;
}
</style>
