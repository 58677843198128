<template>
  <div class="source-selector-mobile">
    <div class="source-select-title">Channels</div>
    <a-select
      v-model:value="selectedSource"
      :getPopupContainer="(trigger) => trigger.parentNode"
      class="source-select"
      style="width: 100%"
      :options="sourceOptions"
      @change="onSourceSelect"
    >
      <template #option="{ value, label }">
        <div v-if="value === 'facebook'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/facebook.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'twitter'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/twitter.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'instagram'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/instagram.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'pantip'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/pantip.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'youtube'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/youtube.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'blockdit'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/Blockdit-Icon.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'tiktok'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/tiktok.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
        <div v-if="value === 'website'" class="source-item" :aria-label="value">
          <img class="source-img" :src="require('@/assets/images/source-icon/svg/website.svg')" alt="logo" />
          &nbsp;&nbsp;{{ label }}
        </div>
      </template>
    </a-select>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import _ from 'lodash';
import api from '@/services/api';

export default {
  name: 'SourceSelection',
  props: {
    sourceSelected: String
  },
  setup(props, { emit }) {
    const { sourceSelected } = toRefs(props);
    let selectedSource = ref(sourceSelected.value);
    const allSrcList = api.getAllSource();
    const srcOpt = allSrcList.map((src) => {
      return {
        label: _.capitalize(src),
        value: src,
      };
    });
    const sourceOptions = ref(srcOpt);
    const onSourceSelect = (e) => {
      emit('onSourceSelect', e);
    };

    return {
      sourceOptions,
      selectedSource,
      onSourceSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.source-selector-mobile {
  .source-select-title {
    font-weight: 500;
    line-height: 24px;
    color: #272b41;
    margin-bottom: 5px;
  }

  .source-select {
    .ant-select-selector {
      border: 1px solid #e3e6ef;
      border-radius: 4px;
      min-height: 42px !important;

      .ant-select-selection-search input {
        height: 42px;
      }

      .ant-select-selection-placeholder {
        line-height: 40px !important;
      }
    }

    .source-item {
      display: flex;
      align-items: center;

      .source-img {
        height: 16px;
        width: 16px;
      }
    }
  }
}
</style>
