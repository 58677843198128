<template>
  <div>
    <Collapsible
      title="Social Media Account Group"
      :sub-title="ownMediaTag.length"
      :is-expand="isExpanding"
      :tags="ownMediaTag"
      @toggle="isExpanding = !isExpanding"
    >
      <template #expand>
        <div v-if="choices.length" class="mt-20">
          <div>
            <a-checkbox
              v-model:checked="checkAll"
              :indeterminate="indeterminate"
              @change="onCheckAllChange"
              >Check all
            </a-checkbox>
          </div>

          <a-checkbox-group
            v-model:value="localState"
            class="vertical"
            name="checkboxgroup-own-media"
            :options="choices"
            @change="onChange"
          />
        </div>
        <div v-else>No social media account group</div>
      </template>
      ></Collapsible
    >
  </div>
</template>

<script>
import { ref, computed, watch, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import Collapsible from '../Collapsible.vue';

export default {
  components: {
    Collapsible,
  },
  setup() {
    const store = useStore();
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const ownMediaData = computed(() => {
      let ownMedias = store.getters['config/ownMediaData'];

      ownMedias.sort((a, b) => a.name.localeCompare(b.name));
      ownMedias.push({ name: 'Others', id: 'others' });
      return ownMedias;
    });
    const isExpanding = ref(false);

    const defaultChoices = computed(() => {
      let choices = ownMediaData.value.map((v) => {
        return { label: v.name, value: v.id };
      });
      return choices;
    });

    const choices = computed(() => defaultChoices.value);

    const state = reactive({
      indeterminate: false,
      checkAll: false,
      localState: [],
    });

    const onCheckAllChange = (e) => {
      Object.assign(state, {
        localState: e.target.checked ? ownMediaData.value.map((v) => v.id) : [],
        indeterminate: false,
      });
      store.dispatch('filter/updateTempCriteria', {
        ownMedia: state.localState,
      });
    };

    watch(
      () => state.localState,
      (val) => {
        state.indeterminate = !!val.length && val.length < choices.value.length;
        state.checkAll = val.length === choices.value.length;
      }
    );

    watch(
      () => tempCriteria.value.ownMedia,
      () => {
        state.localState = [...tempCriteria.value.ownMedia];
      }
    );

    const ownMediaTag = computed(() => {
      const arr = [];
      for (let k of defaultChoices.value) {
        if (state.localState.includes(k.value)) {
          let label = k.label;
          if (label.length > 20) {
            label = label.slice(0, 20) + '...';
          }
          arr.push({
            name: k.value,
            text: label,
          });
        }
      }
      return arr;
    });

    const onChange = () => {
      store.dispatch('filter/updateTempCriteria', {
        ownMedia: state.localState,
      });
    };

    return {
      ...toRefs(state),
      onCheckAllChange,
      isExpanding,
      choices,
      ownMediaTag,
      onChange,
    };
  },
};
</script>

<style lang="scss">
.ant-checkbox-group.vertical .ant-checkbox-wrapper {
  width: 300px;
}
</style>
