<template>
  <component
    :is="editable ? 'a-popover' : 'div'"
    v-model:visible="visible"
    placement="bottom"
    trigger="click"
    :overlay-style="{ width: `${widthPopover}` + `px` }"
    overlay-class-name="fix-height-popover-inner"
    @click.stop="togglePopover($event, categoryData)"
  >
    <template #content>
      <perfect-scrollbar
        class="edit-cat-scrollbar"
        :options="{
          wheelSpeed: 1,
          swipeEasing: true,
          suppressScrollX: true,
          wheelPropagation: false,
          maxScrollbarLength: 200,
        }"
      >
        <div class="checkbox-wrapper" @click.stop>
          <a-checkbox-group :value="selectedCategory" :options="optionsCategory" @change="onChangeCategoryValue" />
        </div>
      </perfect-scrollbar>
      <div v-if="!isFromModal" class="category-action">
        <a-button :size="buttonSize" class="custom-button-link" @click.stop="clear">Clear selection</a-button>
        <a-button
          :class="{
            'ant-btn-primary': isDirty,
          }"
          :disabled="!isDirty"
          :size="buttonSize"
          @click.stop="apply('single', categoryData)"
          >Apply</a-button
        >
      </div>
    </template>
    <slot></slot>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import { notification } from 'ant-design-vue';

import api from '@/services/api';
import helper from '@/services/helper';

const getLevelByKey = (key) => {
  if (key === 'categories') return 0;
  if (key.indexOf('sub_categories_level_' === 0)) {
    const lastChar = key.split('_').pop();
    return parseInt(lastChar, 10);
  }
  return 0;
};

export default {
  name: 'EditCatogory',
  inject: ['onEditMessage'],
  props: {
    msgId: {
      type: String,
      default: '',
    },
    categoryData: { type: Object, default: () => {} },
    noSave: Boolean,
    isFromModal: Boolean,
    widthPopover: {
      type: Number,
      default: 460,
    },
    editable: Boolean,
  },
  watch: {
    categoryData: 'onCatChange',
  },
  emits: ['onChange', 'togglePopover'],
  data() {
    return {
      visible: false,
      isDirty: false,
      buttonSize: 'small',
      dataCategoryByLevel: [],
      selectedCategory: [],
    };
  },
  computed: {
    ...mapGetters({
      categoryDataFromState: 'config/categoryData',
    }),
    optionsCategory() {
      let result = [];
      if (this.dataCategoryByLevel && this.dataCategoryByLevel.length) {
        for (let item in this.dataCategoryByLevel) {
          if (this.dataCategoryByLevel[item].visible) {
            result.push(this.dataCategoryByLevel[item].category);
          }
        }
      }
      //  find 'others'and move it to the first position
      const indexOthers = result.findIndex((ele) => ele === 'others');
      const sortedResult = helper.sortOtherItemAtFirst(result, indexOthers);
      return sortedResult;
    },
  },
  mounted() {},
  methods: {
    async apply(mode, categoryData) {
      this.visible = false;
      const messageId = this.msgId;
      const level = getLevelByKey(categoryData.key);
      const newCategories = [];
      newCategories.push({
        level: level,
        category: [...this.selectedCategory],
      });
      // console.log('apply', messageId, mode, level, newCategories, api);
      if (this.noSave) {
        // this.selectedCategory = newCategories;
        this.$emit('onChange', {
          messageId,
          category: newCategories,
          level,
        });
      } else {
        this.onEditMessage({
          messageId,
          editing: true,
        });
        const result = await api.updateCategory(messageId, mode, newCategories).catch(() => {});
        if (result && result.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Message category edited.',
          });
          this.onEditMessage({
            messageId,
            category: newCategories,
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Message category edit failed.',
          });
        }
        this.onEditMessage({
          messageId,
          editing: false,
        });
      }
    },
    clear() {
      // this.visible = false;
      this.selectedCategory = ['others'];
      this.isDirty = true;
    },
    togglePopover(e, category) {
      e.stopPropagation();
      // console.log('category', category);
      if (!this.editable) {
        return false;
      }
      this.visible = true;
      let level = getLevelByKey(category.key);
      // console.log(category.key, level);

      this.selectedCategory = category.data.map((c) => c);
      for (let item in this.categoryDataFromState) {
        // console.log(this.categoryDataFromState[item].level, level);

        if (this.categoryDataFromState[item].level === level) {
          this.dataCategoryByLevel = [...this.categoryDataFromState[item].categories];
        }
      }
      this.$emit('togglePopover');
    },
    onChangeCategoryValue(checkedValues) {
      this.isDirty = true;
      // this.selectedCategory = [...checkedValues];

      if (checkedValues && checkedValues.length > 0) {
        const oldVal = this.selectedCategory.find((value) => value.toLowerCase() === 'others');
        const newVal = checkedValues.find((value) => value.toLowerCase() === 'others');

        if (oldVal && newVal) {
          const filtered = checkedValues.filter((value) => {
            return value.toLowerCase() !== 'others';
          });
          this.selectedCategory = [...filtered];
        } else if (newVal) {
          this.selectedCategory = ['others'];
        } else {
          this.selectedCategory = [...checkedValues];
        }
      } else {
        this.selectedCategory = ['others'];
      }

      if (this.isFromModal) {
        const messageId = this.msgId;
        const level = getLevelByKey(this.categoryData.key);
        const newCategories = [];
        newCategories.push({
          level: level,
          category: [...this.selectedCategory],
        });
        this.$emit('onChange', {
          messageId,
          category: newCategories,
          level,
        });
      }
    },
    onCatChange(newCatList) {
      // console.log('OHH', newCatList);
      this.onOnChangeselectedCategory = newCatList.data;
    },
  },
};
</script>

<style lang="scss">
@import '../../config/theme/colors.json';

.ps.edit-cat-scrollbar {
  min-height: 0;
  max-height: 300px !important;
  // max-height: 150px !important;
  // .ant-popover-inner {
  //   width: 248px;
  //   height: 275px;
  // }
}

.ant-popover-inner {
  // width: 456px !important;
  // height: 300px;
  // overflow: auto;
  // .ant-popover-inner-content {
  //   padding: 0px !important;
  // }
}
.fix-height-popover-inner {
  .ant-popover-inner {
    // overflow: auto;
    width: 456px !important;
    max-height: 400px;
  }
}

// .ant-popover-inner {
//   // width: 248px;
//   // height: 275px;
//   width: 456px;
//   .ant-popover-inner-content {
//     padding: 0px;
//   }
// }

.checkbox-wrapper {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-group-item {
      display: flex;
      align-items: center;
      color: $gray-color;
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &.ant-checkbox-wrapper {
        margin-right: 0;
      }
      > span + span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 0;
        text-transform: capitalize;
      }
    }
  }
  .ant-checkbox-group {
    width: 100%;
  }
}

.category-action {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  color: #9299b8;
  border-top: 1px solid #f1f2f6;
  padding-top: 15px;
  margin-top: 10px;
  .custom-button-link {
    align-self: center;
    font-weight: 500;
    font-size: 12px;
    color: #9299b8;
  }
}
</style>
