export default {
  namespaced: true,
  state: {
    editingUser: {},
    deletingUser: {},
    deletingUsers: [],
    deletingModalOpen: false,
    deletingMultipleModalOpen: false,
  },
  getters: {},
  actions: {
    setEditingUser({ commit }, data) {
      commit('setEditingUser', data);
    },
    setDeletingUser({ commit }, data) {
      commit('setDeletingUser', data);
    },
    cancelDeletingUser({ commit }) {
      commit('cancelDeletingUser');
    },
    setDeletingUsers({ commit }, data) {
      commit('setDeletingUsers', data);
    },
    cancelDeletingUsers({ commit }) {
      commit('cancelDeletingUsers');
    },
  },
  mutations: {
    setEditingUser(state, data) {
      state.editingUser = { ...data };
    },
    setDeletingUser(state, data) {
      state.deletingUser = { ...data };
      state.deletingModalOpen = true;
    },
    cancelDeletingUser(state) {
      state.deletingUser = {};
      state.deletingModalOpen = false;
    },
    setDeletingUsers(state, data) {
      state.deletingUsers = [...data];
      state.deletingMultipleModalOpen = true;
    },
    cancelDeletingUsers(state) {
      state.deletingUsers = [];
      state.deletingMultipleModalOpen = false;
    },
  },
};
