<template>
  <a-modal
    v-model:visible="localVisible"
    title="Message List"
    width="838px"
    :footer="null"
    :z-index="1000"
    :afterClose="handleCancel"
    :destroyOnClose="true"
  >
    <template #closeIcon>
      <a-button type="ghost" class="ant-btn-sm circular" style="width: 36px">
        <FeatherIcon type="x" size="18" />
      </a-button>
    </template>
    <div v-if="loading">
      <a-skeleton active />
    </div>
    <div v-else class="message-list-modal">
      <div class="modal-header">
        <div class="modal-header-bar">
          <div class="title-header">
            <span>{{ titleModal }}</span>
            <span class="title-header-result">({{ totalRecordText }} results)</span>
          </div>
          <div v-if="totalRecord > 0" class="view-all">
            <a class="view-all-text" @click="viewAll">View All</a>
          </div>
        </div>
      </div>
      <div class="modal-content">
        <a-card v-if="totalRecord === 0">
          <NoDataFound />
        </a-card>
        <a-card v-for="item in messageList" :key="item.info.id" class="message-card">
          <div class="list-wrapper">
            <MessageItem
              class="list-item"
              :item="item"
              :show-category="true"
              :has-thumb="true"
              :use-lazy="true"
              :parentName="'messagemodal'"
              @click="drilldown(item)"
            />
          </div>
        </a-card>
        <div v-if="totalRecord > 0" class="modal-content-pagination">
          <a-pagination
            v-model:pageSize="pageSize"
            v-model:current="pageNumber"
            show-size-changer
            :default-current="1"
            :total="totalRecord"
            :pageSizeOptions="['10', '25', '50', '100']"
            @showSizeChange="showSizeChange"
            @change="onPageChange"
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { computed, provide, ref, watch } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
import MessageItem from '@/components/Message/MessageItem.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';

export default {
  name: 'MessageModal',
  components: {
    MessageItem,
    NoDataFound,
  },
  setup() {
    const store = useStore();
    const visible = computed(() => store.state.message.visibleModal);
    const titleModal = computed(() => store.state.message.title);
    const messageList = computed(() => store.getters['message/messageList']);
    const loading = computed(() => store.state.message.loading);
    const currentPage = computed(() => store.getters['message/currentPage']);
    const totalPage = computed(() => store.getters['message/totalPage']);
    const totalRecord = computed(() => store.getters['message/totalRecord']);
    const totalRecordText = computed(() => {
      const total = store.getters['message/totalRecord'];
      if (total) return helper.numeral(total);
      return 0;
    });

    const localVisible = ref(false);
    watch(
      () => visible.value,
      () => {
        localVisible.value = visible.value;
      }
    );

    const pageSize = ref(25);
    const pageNumber = ref(1);
    const onPageChange = (pn, ps) => {
      pageNumber.value = pn;
      pageSize.value = ps;
      store.dispatch('message/changePage', { pageNumber: pageNumber.value, pageSize: pageSize.value });
    };
    const showSizeChange = (c, ps) => {
      pageSize.value = ps;
      store.dispatch('message/changePage', { pageNumber: pageNumber.value, pageSize: pageSize.value });
    };

    const handleCancel = () => {
      store.dispatch('message/closeModal');
    };

    provide('onEditMessage', (e) => {
      store.dispatch('message/editMessage', e);
    });

    provide(
      'subCategoryName',
      computed(() => store.getters['message/subCategoryName'])
    );

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    const viewAll = async () => {
      await store.dispatch('message/storeCurrentFilter');
      // TODO should we change date to filter store?
      // await store.dispatch('message/setStoreDateFilter');
      window.open('/explore', '_blank');
    };

    return {
      visible,
      localVisible,
      handleCancel,
      titleModal,
      messageList,
      loading,
      totalRecord,
      totalRecordText,
      onPageChange,
      showSizeChange,
      pageSize,
      pageNumber,
      totalPage,
      currentPage,
      drilldown,
      viewAll,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-list-modal {
  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title-header {
      font-size: 18px;
      font-weight: 500;
      color: #272b41;
      line-height: 22px;
      // margin-bottom: 8px;
      .title-header-result {
        font-size: 14px;
        color: #5a5f7d;
        line-height: 18px;
        margin-left: 10px;
      }
    }

    .modal-header-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .view-all {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 13px;
    font-weight: 500;
  }

  .modal-content {
    // max-width: 800px;
    // margin: auto;
    // padding: 36px;
    .message-card {
      padding: 3px !important;
      .list-wrapper {
        margin: 0;
        padding: 0;
        border: 1px solid #f1f2f6;
        padding: 15px 0px;
        .message-item {
          .content-wrapper {
            word-break: break-word;
          }
        }
      }
    }
    .modal-content-pagination {
      text-align: center;
    }
  }
}
</style>
